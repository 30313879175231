import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import PDFViewer from './PDFViewer';
import VideoPlayer from './VideoPlayer';
const HeaderOptions = ({
  selectedTemplate,
  setSelectedTemplate,
  setFileName,
  setImagePreview,
  setVideoName,
  setVideoPreview,
  setDocumentName,
  setDocumentPreview,
  headerText,
  setHeaderText,
  fileName,
  imagePreview,
  videoName,
  videoPreview,
  documentName,
  documentPreview,
  headerHasVariable,
  setHeaderHasVariable,
  headerVariableValue,
  setHeaderVariableValue,
  handleAddHeaderVariable,
  setPostFileName,
  postFileName,
  setPostVideoName,
  postVideoName,
  setPostDocumentName,
  postDocumentName,
  handleVideoChange,
  handleVideoDelete,
  handleDocumentChange,
  handleDocumentDelete,
  handleFileChange,
  handleImageDelete,
  activeTab,
  handleRadioChange,
  setSelectnone,
  selectnone,
  error,
  success,
  fileInputRef,
  documentInputRef,
  videoInputRef,
  view,
}) => {

    // const [postVideoName, setPostVideoName] = React.useState('');
    // const [postDocumentName, setPostDocumentName] = React.useState('');
    // const [postFileName, setPostFileName] = React.useState('');

  // const handleRadioChange = (event) => {
  //   setSelectedTemplate(event.target.value);
  // };



//   const handleVideoChange = (event) => {
//     const { files } = event.target;
//     if (files.length > 0) {
//       const file = files[0];
//       setVideoName(file.name);

//       // Create a VideoReader to read the file
//       const reader = new FileReader();
//       reader.onloadend = () => {
//         setVideoPreview(reader.result);
//       };
//       reader.readAsDataURL(file);
//     } else {
//         handleVideoDelete();
//     }
//   };


// const handleVideoChange = (event) => {
//     const file = event.target.files[0];
//     if (file) {
//       try {
//         console.log('Video file selected:', file.name, 'Size:', file.size, 'Type:', file.type);
        
//         if (!file.type.startsWith('video/')) {
//           alert('Please select a valid video file.');
//           return;
//         }
  
//         setVideoName(file.name);
//         setPostVideoName(file);
//         setPostDocumentName('');
//         setPostFileName('');
  
//         // Create a local URL for the file
//         const fileURL = URL.createObjectURL(file);
//         setVideoPreview(fileURL);
  
//         console.log('Video preview URL created:', fileURL);
//       } catch (error) {
//         console.error('Error handling video file:', error);
//         alert('An error occurred while processing the video file. Please try again.');
//       }
//     } else {
//       handleVideoDelete();
//     }
//   };

  

  console.log("documentPreview", documentPreview);
  console.log("videoPreview", videoPreview);

  // const handleImageDelete = () => {
  //   setFileName("Choose JPG or PNG file");
  //   setImagePreview(null);
  // };

  // const handleVideoDelete = () => {
  //   setVideoName("Choose MP4 file");
  //   setVideoPreview(null);
  // };

  // const handleDocumentDelete = () => {
  //   setDocumentName("Choose PDF file");
  //   setDocumentPreview(null);
  // };

  return (
    <>
      <div className="col-12 mt-2">
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            name="templateRadio"
            id="templateRadio1"
            type="radio"
            value="None"
            checked={selectedTemplate === "None"}
            onChange={handleRadioChange}
            disabled={view}
          />
          <label htmlFor="templateRadio1" className="form-check-label pt-1">
            None
          </label>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            name="templateRadio"
            id="templateRadio2"
            type="radio"
            value="Text"
            checked={selectedTemplate === "Text"}
            onChange={handleRadioChange}
            disabled={view}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top" placement="top">
                Enter text for the header. Keep it short and relevant to the
                content of the message.
              </Tooltip>
            }
          >
            <label htmlFor="templateRadio2" className="form-check-label pt-1">
              Text
            </label>
          </OverlayTrigger>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            name="templateRadio"
            id="templateRadio3"
            type="radio"
            value="Image"
            checked={selectedTemplate === "Image"}
            onChange={handleRadioChange}
            disabled={view}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top" placement="top">
                Upload an image for the header. Make sure it’s relevant and
                follows WhatsApp’s guidelines for images.{" "}
              </Tooltip>
            }
          >
            <label htmlFor="templateRadio3" className="form-check-label pt-1">
              Image
            </label>
          </OverlayTrigger>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            name="templateRadio"
            id="templateRadio4"
            type="radio"
            value="Video"
            checked={selectedTemplate === "Video"}
            onChange={handleRadioChange}
            disabled={view}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top" placement="top">
                Upload a video for the header upto 10MB's. Videos can provide a more engaging way to communicate key information.
              </Tooltip>
            }
          >

          <label htmlFor="templateRadio4" className="form-check-label pt-1">
            Video
          </label>
          </OverlayTrigger>
        </div>
        <div className="form-check form-check-inline">
          <input
            className="form-check-input"
            name="templateRadio"
            id="templateRadio5"
            type="radio"
            value="Document"
            checked={selectedTemplate === "Document"}
            onChange={handleRadioChange}
            disabled={view}
          />
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip id="tooltip-top" placement="top">
                Upload a document for the header upto 10MB's. Useful for sharing detailed information like PDFs or brochures.
              </Tooltip>
            }
          >

          <label htmlFor="templateRadio5" className="form-check-label pt-1">
            Document
          </label>
          </OverlayTrigger>
        </div>
      </div>

      <div className="col-12 mt-2">
        {selectedTemplate === "None" && <div id="noneHeader"></div>}

        {selectedTemplate === "Text" && (
          <div id="textHeader" className="row">
            <div className="col-12 mb-2">
              <div className="position-relative d-flex align-items-center">
                <input
                  type="text"
                  className={`form-control ${ activeTab === "arabic" ? "tw-text-end tw-pr-16 tw-py-2 tw-rounded-xl tw-w-full tw-border tw-m-1" : "input-field-ui tw-text-start"}`}
                  placeholder="Enter text"
                  value={headerText}
                  onChange={(e) => setHeaderText(e.target.value.slice(0, 60))}
                  disabled={view}
                />
                <div className="limited-inputs">{headerText.length}/60</div>
              </div>
            </div>
            <div className="col-12">
              {!headerHasVariable && (
                <>
                {!view &&  
                <a
                  onClick={handleAddHeaderVariable}
                  className="link-primary link-offset-2 link-underline-opacity-25 link-underline-opacity-100-hover"
                  style={{ cursor: "pointer" }}
                >
                  <i className="bx bxs-plus-circle"></i> Add variable
                </a>
                }
                </>
              )}
              {headerHasVariable && (
                <span className="text-muted">
                  Variable added. Only one variable is allowed in the header.
                </span>
              )}
            </div>
          </div>
        )}

        {selectedTemplate === "Image" && (
          <div id="imageHeader" className="row">
            <div className="col-12 mb-2">
              <div className="d-flex align-items-center">
                <div className="file-input-container">
                  <label htmlFor="fileInput" className="custom-file-label">
                    {fileName}
                  </label>
                  <input
                    ref={fileInputRef}
                    type="file"
                    id="fileInput"
                    className="custom-file-input"
                    accept="image/*"
                    onChange={handleFileChange}
                    disabled={view}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <label className="form-check-label pt-1">
                This media file will be sent as a sample to WhatsApp, for
                approval. At the time of sending the template, you can change
                the media file, if required. You can also send separate media
                files for each customer in a campaign.
              </label>
            </div>

            {imagePreview && (
              <div className="col-12 mb-2 mt-3">
                <div className="row">
                  <div className="col-auto">
                    <div className="image-preview">
                      <img src={imagePreview} alt="Preview" />
                    </div>
                  </div>
                  <div className="col px-0">
                    <button onClick={handleImageDelete} className="btn delete-preview-btn" disabled={view}>
                      <i class="bi bi-trash"></i>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
        
        {selectedTemplate === "Video" && (
          <div id="videoHeader">
            <div className="col-12 mb-2">
              <div className="d-flex align-items-center">
                <div className="file-input-container">
                  <label htmlFor="videoInput" className="custom-file-label">
                    {videoName}
                  </label>
                  <input
                    ref={videoInputRef}
                    type="file"
                    id="videoInput"
                    className="custom-file-input"
                    accept="video/*"
                    onChange={handleVideoChange}
                    disabled={view}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <label className="form-check-label pt-1">
                This media file will be sent as a sample to WhatsApp, for
                approval. At the time of sending the template, you can change
                the media file, if required. You can also send separate media
                files for each customer in a campaign.
              </label>
            </div>

            {videoPreview && (
              <div className="col-12 mb-2 mt-3">
              <div className="row">
                <div className="col-auto">
                  <div className="video-preview">
                    <VideoPlayer videoPreview={videoPreview} height={200} />
                  </div>
                </div>
                <div className="col px-0">
                  <button onClick={handleVideoDelete} className="btn delete-preview-btn" disabled={view}>
                    <i className="bi bi-trash"></i>
                  </button>
                </div>
              </div>
            </div>
            )}
          </div>
        )}

        {selectedTemplate === "Document" && (
          <div id="documentHeader">
            <div className="col-12 mb-2">
              <div className="d-flex align-items-center">
                <div className="file-input-container">
                  <label htmlFor="documentInput" className="custom-file-label">
                    {documentName}
                  </label>
                  <input
                    ref={documentInputRef}
                    type="file"
                    id="documentInput"
                    className="custom-file-input"
                    accept=".pdf"
                    onChange={handleDocumentChange}
                    disabled={view}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <label className="form-check-label pt-1">
                This media file will be sent as a sample to WhatsApp, for
                approval. At the time of sending the template, you can change
                the media file, if required. You can also send separate media
                files for each customer in a campaign.
              </label>
            </div>

            {documentPreview && (
               <div className="col-12 mb-2 mt-3">
               <div className="row tw-flex tw-flex-row tw-items-center">
                 <div className="col-auto tw-w-1/3">
                   <a
                     href={documentPreview}
                     download={documentName}
                     className="tw-flex tw-items-center tw-gap-3 tw-p-2.5 tw-rounded-md hover:tw-bg-blue-50 tw-transition-colors tw-w-full tw-max-w-md"
                     style={{
                       border: '1px solid #0d6efd'
                     }}
                   >
                     <i className="bi bi-file-earmark-pdf" style={{ marginRight: '10px', fontSize: '24px' }}></i>
                     <span className="tw-text-blue-600 tw-flex-1 tw-min-w-0" style={{ 
  wordBreak: 'break-word', 
  overflowWrap: 'break-word',
  whiteSpace: 'normal',
  hyphens: 'auto'
}}>
  {documentName}
</span>
                   </a>
                 </div>
                 <div className="col px-0">
                   <button onClick={handleDocumentDelete} className="btn delete-preview-btn" disabled={view}>
                     <i className="bi bi-trash"></i>
                   </button>
                 </div>
               </div>
             </div>
            )}
          </div>
        )}
      </div>
    </>
  );
};

export default HeaderOptions;
