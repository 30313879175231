import instance from "../api/axios"
import { CommunicateFormPayloadTS } from "../utils/CommunicationFormData";

export const createCommunication = (payload: CommunicateFormPayloadTS) => {
    return instance.post('/create-communication', payload);
}

// export const fetchCommunications = (payload: CommunicateFormPayloadTS) => {
export const fetchCommunications = () => {
    // return instance.post('/communications', payload);
    return instance.post('/communications');
}