import React, {
  ChangeEvent,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import {
  ChoicesBuilderTS,
  ChoicesTS,
  MatrixColumnOptionBuilderTS,
  MatrixColumnOptionTS,
  MatrixRowOptionBuilderTS,
  MatrixRowOptionTS,
  PageOption,
  QuestionBuilderPropsTS,
  QuestionTypeTS,
  TranslationTS,
} from "../../../utils/SurveyFormData";
import { fetchQuestionTypes } from "../../../services/SurveyFormService";
import {
  Box,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  IconButton,
  OutlinedInput,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import {
  CancelOutlined,
  CircleOutlined,
  InfoOutlined,
  SquareOutlined,
  AddCircleOutline
} from "@mui/icons-material";
import { AppContext } from "../../../context/AppContextProvider";
import Select from "react-dropdown-select";
import { SelectOptionType } from "../../../utils/CommunicationFormData";
import Smileys from "../CustomComponents/Smileys";
import { translateTextToAllLanguages } from "../../../helpers/TranslationHelper";

const QuestionBuilder = ({
  question,
  updateQuestion,
  togglePreview,
  removeQuestion,
}: QuestionBuilderPropsTS) => {
  const [questionTypesList, setQuestionTypesList] =
    useState<QuestionTypeTS[]>();
  const [customMessages, setCustomMessages] = useState<{
    [key: string]: string;
  }>({
    required: "This question requires an answer.",
  });
  const [open, setOpen] = React.useState(false);
  // const [isPreview, setIsPreview] = useState(false);

  const surveyContext = useContext(AppContext);
  if (!surveyContext) {
    throw new Error("ComponentC must be used within an AppContextProvider");
  }

  const { survey, isDisabledLogicsButton, selectedLanguageOptions, selectedTab } = surveyContext;
  const [selectedPageOptions, setSelectedPageOptions] = useState<
    Record<number, PageOption[]>
  >({});
  const [currentLanguageDirection, setCurrentLanguageDirection] = useState("ltr")

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    fetchQuestionTypesData();

    setCurrentLanguageDirection(selectedLanguageOptions[selectedTab].value === 'ar' ? 'rtl' : 'ltr');
  }, [selectedLanguageOptions[selectedTab]]);

  const fetchQuestionTypesData = async () => {
    try {
      const data = await fetchQuestionTypes();

      if (data.data.status === "success") {
        setQuestionTypesList(data.data.response);
      } else {
        console.log(data.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // const updateChoice = (
  //   choiceIndex: number,
  //   updatedChoice: ChoicesBuilderTS
  // ) => {
  //   // const updatedChoices = question.choices.map((choice, index) =>
  //   //   index === choiceIndex ? updatedChoice : choice
  //   // );
  //   const updatedChoices = question.choices.map((choice, index) => {
  //     if (index === choiceIndex) {
  //       const updatedTranslations = choice.translations?.map(translation =>
  //         translation.language_code === selectedLanguageOptions[selectedTab]?.value
  //           ? { ...translation, choice_text: updatedChoice.choice_text }
  //           : translation
  //       ) || [];

  //       if (!updatedTranslations?.some(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)) {
  //         updatedTranslations?.push({ language_code: selectedLanguageOptions[selectedTab].value, choice_text: updatedChoice.choice_text });
  //         // updatedTranslations = selectedLanguageOptions.map(option => ({
  //         //   language_code: option.value,
  //         //   choice_text: updatedChoice.choice_text
  //         // }));
  //       }

  //       return {
  //         ...choice,
  //         choice_text: updatedChoice.choice_text || "",
  //         translations: updatedTranslations,
  //       };
  //     }
  //     return choice;
  //   });

  //   updateQuestion({ ...question, choices: updatedChoices });
  // };
  const updateChoice = (
    choiceIndex: number,
    updatedChoice: ChoicesBuilderTS,
    effectType: string,
  ) => {
    if (effectType === 'change') {
      const updatedChoices = question.choices.map((choice, index) => {
        if (index === choiceIndex) {
          const selectedLocale = selectedLanguageOptions[selectedTab].value;

          let updatedTranslations = choice.translations ? [...choice.translations] : [];

          const existingEntryIndex = updatedTranslations.findIndex(
            translation => translation.language_code === selectedLocale && translation.column_name === 'choice_text'
          );

          if (existingEntryIndex !== -1) {
            updatedTranslations[existingEntryIndex] = {
              ...updatedTranslations[existingEntryIndex],
              translated_text: updatedChoice.choice_text,
            };
          } else {
            updatedTranslations.push({
              language_code: selectedLocale,
              column_name: 'choice_text',
              translated_text: updatedChoice.choice_text,
            });
          }

          return {
            ...choice,
            // choice_text: updatedChoice.choice_text || "",
            choice_text: (updatedTranslations.length > 1 ? updatedTranslations.filter(t => t.language_code === "en")[0].translated_text : updatedTranslations[0].translated_text)?.toString().trim() ?? "",
            is_other: updatedChoice.is_other || 0,
            translations: updatedTranslations,
          };
        }
        return choice;
      });

      updateQuestion({ ...question, choices: updatedChoices });
    } else if (effectType === 'blur' && selectedLanguageOptions.length > 1) {
      if (selectedLanguageOptions[selectedTab]?.value === 'en') {
        const translationLanguages = selectedLanguageOptions.reduce((acc: any, l: any) => {
          return l.value !== "en" ? [...acc, l.value] : acc;
        }, []).join(",");

        translateTextToAllLanguages(updatedChoice.choice_text, translationLanguages).then((translatedTexts) => {
          const updatedChoices = question.choices.map((choice, index) => {
            if (index === choiceIndex) {
              const updatedTranslations = selectedLanguageOptions.map((option) => ({
                language_code: option.value,
                column_name: 'choice_text',
                translated_text: option.value === 'en' ? updatedChoice.choice_text : translatedTexts[option.value],
              }));

              return {
                ...choice,
                translations: updatedTranslations,
              };
            }
            return choice;
          });

          updateQuestion({ ...question, choices: updatedChoices });
        });
      }
    }
  };

  // const addChoice = (index: number) => {
  //   const newChoice: ChoicesBuilderTS = {
  //     // id: question.choices.length + 1,
  //     choice_text: "",
  //     is_other: 0,
  //     question_choice_logic: {},
  //     translations: [],
  //   };
  //   updateQuestion({
  //     ...question,
  //     choices: [...question.choices, newChoice],
  //   });
  // };

  const addChoice = (index: number) => {
    const newChoice: ChoicesBuilderTS = {
      choice_text: "",
      is_other: 0,
      question_choice_logic: {},
      translations: [],
    };
    
    const updatedChoices = [
      ...question.choices.slice(0, index + 1),
      newChoice,
      ...question.choices.slice(index + 1)
    ];
    
    updateQuestion({
      ...question,
      choices: updatedChoices,
    });
  };

  const updateQuestionType = (e: ChangeEvent<HTMLSelectElement>) => {
    const selectedOption = e.target.options[e.target.selectedIndex];
    const id = parseInt(selectedOption.getAttribute("data-id") || "0");
    const typeName = e.target.value;

    if (typeName === 'rating' && Object.keys(question.rating_option).length === 0) {
      const ratingOption = question.rating_option || {};
      let updatedTranslations: TranslationTS[] = question.rating_option.translations || [];
      ["shape", "color"].forEach((val, i) => {
        selectedLanguageOptions.forEach((val1, i1) => {
          updatedTranslations.push({
            language_code: val1.value,
            column_name: val,
            // [val]: val === "shape" ? "star" : "#000000",
            translated_text: val === "shape" ? "star" : "#FDCC0D",
          });
        })
      });

      updateQuestion({
        ...question,
        question_type: {
          ...question.question_type,
          id: id,
          type_name: typeName,
        },
        rating_option: {
          ...ratingOption,
          shape: "star",
          color: "#FDCC0D",
          translations: updatedTranslations,
        },
      });
    } else if (typeName === 'radio' || typeName === 'checkbox') {
      if (question.choices.length === 0) {
        for (let i = 0; i < 2; i++) {
          question.choices.push({
            choice_text: "",
            is_other: 0,
            question_choice_logic: {},
            translations: []
          });
        }
      }

      updateQuestion({
        ...question,
        // question_text: '',
        question_type: {
          ...question.question_type,
          id: id,
          type_name: typeName,
        },
      });
    } else if (typeName === 'matrix_rating') {
      if (question.matrix_row_options.length === 0 || question.matrix_column_options.length === 0) {
        for (let i = 0; i < 2; i++) {
          question.matrix_row_options.push({
            row_option_text: "",
            translations: []
          });
          question.matrix_column_options.push({
            column_option_text: "",
            translations: []
          });
        }
      }

      updateQuestion({
        ...question,
        // question_text: '',
        question_type: {
          ...question.question_type,
          id: id,
          type_name: typeName,
        },
      });
    } else {
      updateQuestion({
        ...question,
        // question_text: '',
        question_type: {
          ...question.question_type,
          id: id,
          type_name: typeName,
        },
      });
    }
  };

  const removeChoice = (choiceIndex: number) => {
    const updatedChoices = question.choices.filter(
      (_, index) => index !== choiceIndex
    );
    updateQuestion({ ...question, choices: updatedChoices });
  };

  // const addRowOption = (type: string) => {
  //   if (type === "row") {
  //     const newRowOption: MatrixRowOptionBuilderTS = {
  //       // id: question.matrix_row_options.length + 1,
  //       row_option_text: "",
  //       translations: [],
  //     };
  //     updateQuestion({
  //       ...question,
  //       matrix_row_options: [...question.matrix_row_options, newRowOption],
  //     });
  //   } else if (type === "column") {
  //     const newColumnOption: MatrixColumnOptionBuilderTS = {
  //       column_option_text: "",
  //       translations: [],
  //     };
  //     updateQuestion({
  //       ...question,
  //       matrix_column_options: [
  //         ...question.matrix_column_options,
  //         newColumnOption,
  //       ],
  //     });
  //   }
  // };

  const addRowOption = (type: string, index: number) => {
    if (type === "row") {
      const newRowOption: MatrixRowOptionBuilderTS = {
        row_option_text: "",
        translations: [],
      };
      const updatedRowOptions = [
        ...question.matrix_row_options.slice(0, index + 1),
        newRowOption,
        ...question.matrix_row_options.slice(index + 1)
      ];
      updateQuestion({
        ...question,
        matrix_row_options: updatedRowOptions,
      });
    } else if (type === "column") {
      const newColumnOption: MatrixColumnOptionBuilderTS = {
        column_option_text: "",
        translations: [],
      };
      const updatedColumnOptions = [
        ...question.matrix_column_options.slice(0, index + 1),
        newColumnOption,
        ...question.matrix_column_options.slice(index + 1)
      ];
      updateQuestion({
        ...question,
        matrix_column_options: updatedColumnOptions,
      });
    }
  };

  // const updateRowOption = (
  //   rowIndex: number,
  //   updatedRowOption: MatrixRowOptionBuilderTS
  // ) => {
  //   // const updatedRowOptions = question.matrix_row_options.map((choice, index) =>
  //   //   index === rowIndex ? updatedRowOption : choice
  //   // );
  //   const updatedRowOptions = question.matrix_row_options.map((option, index) => {
  //     if (index === rowIndex) {
  //       const updatedTranslations = option.translations?.map(translation =>
  //         translation.language_code === selectedLanguageOptions[selectedTab]?.value
  //           ? { ...translation, row_option_text: updatedRowOption.row_option_text }
  //           : translation
  //       ) || [];

  //       if (!updatedTranslations?.some(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)) {
  //         updatedTranslations?.push({ language_code: selectedLanguageOptions[selectedTab].value, row_option_text: updatedRowOption.row_option_text });
  //       }

  //       return {
  //         ...option,
  //         row_option_text: updatedRowOption.row_option_text || "",
  //         translations: updatedTranslations,
  //       };
  //     }
  //     return option;
  //   });

  //   updateQuestion({ ...question, matrix_row_options: updatedRowOptions });
  // };
  const updateRowOption = (
    rowIndex: number,
    updatedRowOption: MatrixRowOptionBuilderTS,
    effectType: string,
  ) => {
    if (effectType === 'change') {
      const updatedRowOptions = question.matrix_row_options.map((option, index) => {
        if (index === rowIndex) {
          const selectedLocale = selectedLanguageOptions[selectedTab].value;

          let updatedTranslations = option.translations ? [...option.translations] : [];

          // Find existing entry for the current locale
          const existingEntryIndex = updatedTranslations.findIndex(
            translation => translation.language_code === selectedLocale && translation.column_name === 'row_option_text'
          );

          if (existingEntryIndex !== -1) {
            // Update existing entry
            updatedTranslations[existingEntryIndex] = {
              ...updatedTranslations[existingEntryIndex],
              // row_option_text: updatedRowOption.row_option_text,
              translated_text: updatedRowOption.row_option_text,
            };
          } else {
            // Add a new entry
            updatedTranslations.push({
              language_code: selectedLocale,
              column_name: 'row_option_text',
              // row_option_text: updatedRowOption.row_option_text,
              translated_text: updatedRowOption.row_option_text,
            });
          }

          return {
            ...option,
            row_option_text: updatedRowOption.row_option_text || "",
            translations: updatedTranslations,
          };
        }
        return option;
      });

      updateQuestion({ ...question, matrix_row_options: updatedRowOptions });
    } else if (effectType === 'blur' && selectedLanguageOptions.length > 1) {
      if (selectedLanguageOptions[selectedTab]?.value === 'en') {
        const translationLanguages = selectedLanguageOptions.reduce((acc: any, l: any) => {
          return l.value !== "en" ? [...acc, l.value] : acc;
        }, []).join(",");

        translateTextToAllLanguages(updatedRowOption.row_option_text, translationLanguages).then((translatedTexts) => {
          const updatedRowOptions = question.matrix_row_options.map((rowOption, index) => {
            if (index === rowIndex) {
              const updatedTranslations = selectedLanguageOptions.map((option) => ({
                language_code: option.value,
                column_name: 'row_option_text',
                translated_text: option.value === 'en' ? updatedRowOption.row_option_text : translatedTexts[option.value],
              }));

              return {
                ...rowOption,
                translations: updatedTranslations,
              };
            }
            return rowOption;
          });

          updateQuestion({ ...question, matrix_row_options: updatedRowOptions });
        });
      }
    }
  };

  // const updateColumnOption = (
  //   columnIndex: number,
  //   updatedColumnOption: MatrixColumnOptionBuilderTS
  // ) => {
  //   // const updatedColumnOptions = question.matrix_column_options.map(
  //   //   (choice, index) => (index === columnIndex ? updatedColumnOption : choice)
  //   // );
  //   const updatedColumnOptions = question.matrix_column_options.map((option, index) => {
  //     if (index === columnIndex) {
  //       const updatedTranslations = option.translations?.map(translation =>
  //         translation.language_code === selectedLanguageOptions[selectedTab]?.value
  //           ? { ...translation, column_option_text: updatedColumnOption.column_option_text }
  //           : translation
  //       ) || [];

  //       if (!updatedTranslations?.some(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)) {
  //         updatedTranslations?.push({ language_code: selectedLanguageOptions[selectedTab].value, column_option_text: updatedColumnOption.column_option_text });
  //       }

  //       return {
  //         ...option,
  //         column_option_text: updatedColumnOption.column_option_text || "",
  //         translations: updatedTranslations,
  //       };
  //     }
  //     return option;
  //   });

  //   updateQuestion({
  //     ...question,
  //     matrix_column_options: updatedColumnOptions,
  //   });
  // };

  const updateColumnOption = (
    columnIndex: number,
    updatedColumnOption: MatrixColumnOptionBuilderTS,
    effectType: string
  ) => {
    if (effectType === 'change') {
      const updatedColumnOptions = question.matrix_column_options.map((option, index) => {
        if (index === columnIndex) {
          const selectedLocale = selectedLanguageOptions[selectedTab].value;

          let updatedTranslations = option.translations ? [...option.translations] : [];

          // Find existing entry for the current locale
          const existingEntryIndex = updatedTranslations.findIndex(
            translation => translation.language_code === selectedLocale && translation.column_name === 'column_option_text'
          );

          if (existingEntryIndex !== -1) {
            // Update existing entry
            updatedTranslations[existingEntryIndex] = {
              ...updatedTranslations[existingEntryIndex],
              // column_option_text: updatedColumnOption.column_option_text,
              translated_text: updatedColumnOption.column_option_text,
            };
          } else {
            // Add a new entry
            updatedTranslations.push({
              language_code: selectedLocale,
              column_name: 'column_option_text',
              // column_option_text: updatedColumnOption.column_option_text,
              translated_text: updatedColumnOption.column_option_text,
            });
          }

          return {
            ...option,
            column_option_text: updatedColumnOption.column_option_text || "",
            translations: updatedTranslations,
          };
        }
        return option;
      });

      updateQuestion({ ...question, matrix_column_options: updatedColumnOptions });
    } else if (effectType === 'blur' && selectedLanguageOptions.length > 1) {
      if (selectedLanguageOptions[selectedTab]?.value === 'en') {
        const translationLanguages = selectedLanguageOptions.reduce((acc: any, l: any) => {
          return l.value !== "en" ? [...acc, l.value] : acc;
        }, []).join(",");

        translateTextToAllLanguages(updatedColumnOption.column_option_text, translationLanguages).then((translatedTexts) => {
          const updatedColumnOptions = question.matrix_column_options.map((columnOption, index) => {
            if (index === columnIndex) {
              const updatedTranslations = selectedLanguageOptions.map((option) => ({
                language_code: option.value,
                column_name: 'column_option_text',
                translated_text: option.value === 'en' ? updatedColumnOption.column_option_text : translatedTexts[option.value],
              }));

              return {
                ...columnOption,
                translations: updatedTranslations,
              };
            }
            return columnOption;
          });

          updateQuestion({ ...question, matrix_column_options: updatedColumnOptions });
        });
      }
    }
  };

  const removeRowOption = (type: string, i: number) => {
    if (type === "row") {
      const updatedRowOptions = question.matrix_row_options.filter(
        (_, index) => index !== i
      );
      updateQuestion({ ...question, matrix_row_options: updatedRowOptions });
    } else if (type === "column") {
      const updatedColumnOptions = question.matrix_column_options.filter(
        (_, index) => index !== i
      );
      updateQuestion({
        ...question,
        matrix_column_options: updatedColumnOptions,
      });
    }
  };

  const updateSliderOption = (key: string, value: any, effectType: string) => {
    const sliderOption = question.slider_option || {};
    let updatedTranslations: TranslationTS[] = sliderOption.translations || [];

    if (key !== "left_side_label" && key !== "right_side_label") {
      if (value === "" || value === null || value === undefined) {
        value = "";
      } else if (!/^\d+$/.test(value.toString())) {
        return;
      }
    }    

    if (key === "left_side_label" || key === "right_side_label") {
      if (effectType === 'change') {
        const existingTranslationIndex = updatedTranslations.findIndex(
          translation => translation.language_code === selectedLanguageOptions[selectedTab]?.value && translation.column_name === key
        );

        if (existingTranslationIndex !== -1) {
          updatedTranslations[existingTranslationIndex] = {
            ...updatedTranslations[existingTranslationIndex],
            translated_text: value,
          };
        } else {
          updatedTranslations.push({
            language_code: selectedLanguageOptions[selectedTab]?.value,
            column_name: key,
            translated_text: value,
          });
        }

        updateQuestion({
          ...question,
          slider_option: {
            ...sliderOption,
            [key]: (key === "left_side_label" || key === "right_side_label") ? value : parseInt(value),
            translations: updatedTranslations,
          },
        });
      } else if (effectType === 'blur' && selectedLanguageOptions.length > 1) {
        const currentTranslationIndex = updatedTranslations.findIndex(
          translation => translation.language_code === selectedLanguageOptions[selectedTab]?.value && translation.column_name === key
        );

        if (currentTranslationIndex !== -1) {
          updatedTranslations[currentTranslationIndex] = {
            ...updatedTranslations[currentTranslationIndex],
            translated_text: value
          };
        } else {
          updatedTranslations.push({
            language_code: selectedLanguageOptions[selectedTab]?.value,
            column_name: key,
            translated_text: value
          });
        }

        if (selectedLanguageOptions[selectedTab]?.value === 'en') {
          const translationLanguages = selectedLanguageOptions.reduce((acc: any, l: any) => {
            return l.value !== "en" ? [...acc, l.value] : acc;
          }, []).join(",");

          translateTextToAllLanguages(value, translationLanguages).then((translatedTexts) => {
            selectedLanguageOptions.forEach((option) => {
              if (option.value !== 'en') {
                const translationIndex = updatedTranslations.findIndex(
                  translation => translation.language_code === option.value && translation.column_name === key
                );

                if (translationIndex !== -1) {
                  updatedTranslations[translationIndex] = {
                    ...updatedTranslations[translationIndex],
                    translated_text: translatedTexts[option.value]
                  };
                } else {
                  updatedTranslations.push({
                    language_code: option.value,
                    column_name: key,
                    translated_text: translatedTexts[option.value]
                  });
                }
              }
            });
          });
        }

        updateQuestion({
          ...question,
          slider_option: {
            ...sliderOption,
            [key]: value,
            translations: updatedTranslations,
          },
        });
      }
    } else {
      if (effectType === 'change') {
        selectedLanguageOptions.forEach((val, i) => {
          const existingTranslationIndex = updatedTranslations.findIndex(
            translation => translation.language_code === val.value && translation.column_name === key
          );

          if (existingTranslationIndex !== -1) {
            updatedTranslations = updatedTranslations.map(translation => {
              if (translation.column_name === key) {
                return {
                  ...translation,
                  translated_text: value === "" ? "" : parseInt(value)
                };
              }
              return translation;
            });
          } else {
            updatedTranslations.push({
              language_code: val.value,
              column_name: key,
              translated_text: value === "" ? "" : parseInt(value)
            })
          }
        });

        updateQuestion({
          ...question,
          slider_option: {
            ...sliderOption,
            [key]: (key === "left_side_label" || key === "right_side_label") ? value : parseInt(value),
            translations: updatedTranslations,
          },
        });
      }
    }
  };

  const updateRatingOption = (key: string, value: any) => {
    const ratingOption = question.rating_option || {};
    // updateQuestion({
    //   ...question,
    //   rating_option: {
    //     ...ratingOption,
    //     [key]: value ? value : "",
    //   },
    // });
    let updatedTranslations: TranslationTS[] = ratingOption.translations || [];

    if (key === "scale") {
      if (value === "" || value === null || value === undefined) {
        value = "";
      } else if (!/^\d+$/.test(value.toString())) {
        return;
      }
    }

    // const existingTranslationIndex = updatedTranslations.findIndex(
    //   translation => translation.language_code === selectedLanguageOptions[selectedTab]?.value && translation.column_name === key
    // );

    // if (existingTranslationIndex !== -1) {
    //   updatedTranslations[existingTranslationIndex] = {
    //     ...updatedTranslations[existingTranslationIndex],
    //     // [key]: value,
    //     translated_text: value,
    //   };
    // } else {
    //   updatedTranslations.push({
    //     language_code: selectedLanguageOptions[selectedTab]?.value,
    //     column_name: key,
    //     // [key]: value,
    //     translated_text: value,
    //   });
    // }

    selectedLanguageOptions.forEach((val, i) => {
      const existingTranslationIndex = updatedTranslations.findIndex(
        translation => translation.language_code === val.value && translation.column_name === key
      );

      if (existingTranslationIndex !== -1) {
        updatedTranslations = updatedTranslations.map(translation => {
          if (translation.column_name === key) {
            return {
              ...translation,
              translated_text: key === "scale" ? parseInt(value) : value
            };
          }
          return translation;
        });
      } else {
        updatedTranslations.push({
          language_code: val.value,
          column_name: key,
          translated_text: key === "scale" ? parseInt(value) : value
        })
      }
    });

    updateQuestion({
      ...question,
      rating_option: {
        ...ratingOption,
        [key]: key === "scale" ? parseInt(value) : value,
        translations: updatedTranslations,
      },
    });
  };

  const updateQuestionOptions = (
    e: React.ChangeEvent<HTMLInputElement>,
    validationType: string,
    message: string
  ) => {
    const { checked } = e.target;
    const { question_options } = question;

    if (checked) {
      // if (!question_options.some((option) => option.validation === validationType)) {
      //   updateQuestion({
      //     ...question,
      //     question_options: [
      //       ...question_options,
      //       {
      //         // id: question_options.length + 1,
      //         validation: validationType,
      //         message: "This question requires an answer.",
      //       },
      //     ],
      //   });
      // }
      const updatedOptions = question_options.map(option =>
        option.validation === validationType
          ? {
            ...option,
            translations: selectedLanguageOptions.map(option => ({
              language_code: option.value,
              column_name: "message",
              // message: message,
              translated_text: option.value === "ar" ? "هذا السؤال يحتاج إلى إجابة." : message,
            })),
          }
          : option
      );

      if (!question_options.some(option => option.validation === validationType)) {
        const newOption = {
          validation: validationType,
          message: message,
          translations: selectedLanguageOptions.map(option => ({
            language_code: option.value,
            column_name: "message",
            // message: message,
            translated_text: option.value === "ar" ? "هذا السؤال يحتاج إلى إجابة." : message,
          })),
        };

        updateQuestion({
          ...question,
          question_options: [...question_options, newOption],
        });
      } else {
        updateQuestion({
          ...question,
          question_options: updatedOptions,
        });
      }
    } else {
      setCustomMessages({});
      updateQuestion({
        ...question,
        question_options: question_options.filter(
          (option) => option.validation !== validationType
        ),
      });
    }
  };

  const handleCustomMessageChange = (
    validationType: string,
    message: string,
    effectType: string
  ) => {
    setCustomMessages((prevMessages) => ({
      ...prevMessages,
      [validationType]: message,
    }));
    updateQuestion({
      ...question,
      question_options: question.question_options.map(option => {
        if (option.validation === validationType) {
          let updatedTranslations = option.translations || [];

          // Update the current language translation
          const currentTranslationIndex = updatedTranslations.findIndex(
            translation => translation.language_code === selectedLanguageOptions[selectedTab]?.value && translation.column_name === "message"
          );

          if (currentTranslationIndex !== -1) {
            updatedTranslations[currentTranslationIndex] = {
              ...updatedTranslations[currentTranslationIndex],
              translated_text: message
            };
          } else {
            updatedTranslations.push({
              language_code: selectedLanguageOptions[selectedTab]?.value,
              column_name: "message",
              translated_text: message
            });
          }

          if (effectType === 'blur' && selectedLanguageOptions[selectedTab]?.value === 'en' && selectedLanguageOptions.length > 1) {
            const translationLanguages = selectedLanguageOptions.reduce((acc: any, l: any) => {
              return l.value !== "en" ? [...acc, l.value] : acc;
            }, []).join(",");

            translateTextToAllLanguages(message, translationLanguages).then((translatedTexts) => {
              selectedLanguageOptions.forEach((langOption) => {
                if (langOption.value !== 'en') {
                  const translationIndex = updatedTranslations.findIndex(
                    translation => translation.language_code === langOption.value && translation.column_name === "message"
                  );

                  if (translationIndex !== -1) {
                    updatedTranslations[translationIndex] = {
                      ...updatedTranslations[translationIndex],
                      translated_text: translatedTexts[langOption.value]
                    };
                  } else {
                    updatedTranslations.push({
                      language_code: langOption.value,
                      column_name: "message",
                      translated_text: translatedTexts[langOption.value]
                    });
                  }
                }
              });

              return {
                ...option,
                message: message || "",
                translations: updatedTranslations,
              };
            });
          } else if (effectType === 'change') {
            return {
              ...option,
              message: message || "",
              translations: updatedTranslations,
            };
          }

          // const updatedTranslations = option.translations?.map(translation =>
          //   translation.language_code === selectedLanguageOptions[selectedTab]?.value
          //     // ? { ...translation, message: message }
          //     ? { ...translation, translated_text: message }
          //     : translation
          // ) || [];

          // // if (!updatedTranslations.some(
          // //   translation => translation.language_code === selectedLanguageOptions[selectedTab]?.value
          // // )) {
          // //   updatedTranslations.push({
          // //     language_code: selectedLanguageOptions[selectedTab]?.value,
          // //     message: message,
          // //   });
          // // }

          // return {
          //   ...option,
          //   message: message || "",
          //   translations: updatedTranslations,
          // };
        }
        return option;
      }),
    });
  };

  const handleChange = (selected: PageOption[], index: number) => {
    setSelectedPageOptions((prev) => {
      const newState: Record<number, PageOption[]> = { ...prev };
      newState[index] = selected;

      // Remove the selected option from other dropdowns
      Object.keys(newState).forEach((key) => {
        if (Number(key) !== index) {
          newState[Number(key)] = newState[Number(key)].filter(
            (option) => !selected.some((sel) => sel.value === option.value)
          );
        }
      });

      return newState;
    });
  };

  const handleClearPageOptions = (
    clear_type: "single" | "all",
    index?: number
  ) => {
    if (clear_type === "single" && index !== undefined) {
      setSelectedPageOptions((prev) => ({
        ...prev,
        [index]: [],
      }));

      updateQuestion({
        ...question,
        choices: question.choices.map((choice, choiceIndex) => {
          if (choiceIndex === index) {
            return {
              ...choice,
              question_choice_logic: {},
            };
          }
          return choice;
        }),
      });
    } else if (clear_type === "all") {
      setSelectedPageOptions({});

      updateQuestion({
        ...question,
        choices: question.choices.map((choice) => {
          return {
            ...choice,
            question_choice_logic: {},
          };
        }),
      });
    }
  };

  const currentPageNumber =
    survey.pages.findIndex((page) =>
      page.questions.some((q) => q.order === question.order)
    ) + 1;

  const handleAddLogic = () => {
    if (Object.keys(selectedPageOptions).length !== question.choices.length) {
      alert("Please add skip logics for all the choices.");
      return;
    }
    const updatedChoices = question.choices.map((choice, index) => {
      const logicForChoice = selectedPageOptions[index];
      if (logicForChoice && logicForChoice.length > 0) {
        return {
          ...choice,
          question_choice_logic: {
            skip_to_page: logicForChoice[0].page_number,
          },
        };
      }
      return choice;
    });

    updateQuestion({
      ...question,
      choices: updatedChoices,
    });

    setOpen(false);
  };

  const handleQuestionTextChange = (e: ChangeEvent<HTMLInputElement>, effectType: string) => {
    const updatedText = e.target.value;

    let updatedTranslations = question.translations || [];

    const currentTranslationIndex = updatedTranslations.findIndex(
      translation => translation.language_code === selectedLanguageOptions[selectedTab].value && translation.column_name === "question_text"
    );

    if (currentTranslationIndex !== -1) {
      updatedTranslations[currentTranslationIndex] = {
        ...updatedTranslations[currentTranslationIndex],
        translated_text: updatedText
      };
    } else {
      updatedTranslations.push({
        language_code: selectedLanguageOptions[selectedTab].value,
        column_name: "question_text",
        translated_text: updatedText
      });
    }

    if (effectType === 'blur' && selectedLanguageOptions[selectedTab].value === 'en' && selectedLanguageOptions.length > 1) {
      const translationLanguages = selectedLanguageOptions.reduce((acc: any, l: any) => {
        return l.value !== "en" ? [...acc, l.value] : acc;
      }, []).join(",");

      translateTextToAllLanguages(updatedText, translationLanguages).then((translatedTexts) => {
        selectedLanguageOptions.forEach((option) => {
          if (option.value !== 'en') {
            const translationIndex = updatedTranslations.findIndex(
              translation => translation.language_code === option.value && translation.column_name === "question_text"
            );

            if (translationIndex !== -1) {
              updatedTranslations[translationIndex] = {
                ...updatedTranslations[translationIndex],
                translated_text: translatedTexts[option.value]
              };
            } else {
              updatedTranslations.push({
                language_code: option.value,
                column_name: "question_text",
                translated_text: translatedTexts[option.value]
              });
            }
          }
        });

        let questionText = "";
        if (updatedTranslations) {
          questionText = (updatedTranslations.length > 1 ? updatedTranslations.filter(t => t.language_code === "en")[0].translated_text : updatedTranslations[0].translated_text)?.toString().trim() ?? "";
        }

        updateQuestion({
          ...question,
          // question_text: e.target.value,
          question_text: questionText,
          translations: updatedTranslations
        });
      });
    } else if (effectType === 'change') {

      let questionText = "";
      if (updatedTranslations) {
        questionText = (updatedTranslations.length > 1 ? updatedTranslations.filter(t => t.language_code === "en")[0].translated_text : updatedTranslations[0].translated_text)?.toString().trim() ?? "";
      }

      updateQuestion({
        ...question,
        // question_text: e.target.value,
        question_text: questionText,
        translations: updatedTranslations
      });
    }
  };


  const currentNpsQuestion1 = currentLanguageDirection === "ltr" ? "How likely is it that you would recommend" : "ما مدى احتمالية أن توصي به";
  const currentNpsQuestion2 = currentLanguageDirection === "ltr" ? "to a friend or colleague?" : "لصديق أو زميل؟";

  // console.log(survey);

  return (
    <div
      className="tw-rounded-lg tw-pt-4 tw-px-2 tw-bg-[#f0f4ff] tw-shadow-lg"
      dir={currentLanguageDirection}
    >
      {/* <span className="tw-float-end tw-cursor-pointer tw-px-1  tw-bg-red-500 hover:tw-bg-red-600 tw-text-white tw-rounded-full tw-mb-2 hover:tw-scale-105 tw-transition-all tw-duration-300" onClick={removeQuestion}><i className="bi bi-x"></i></span> */}
      <Tooltip title="Remove Question">
            <IconButton
              aria-label="delete"
              size="large"
              sx={{ color: "red", float: "inline-end" }}
              onClick={removeQuestion}
            >
              <CancelOutlined />
            </IconButton>
          </Tooltip>
      <div className="tw-flex tw-justify-center tw-items-center tw-w-full tw-flex-col md:tw-flex-row tw-gap-2">
        <Typography>
          {"Q" + question.order + ")"}
        </Typography>
        {question.question_type.type_name === "nps" ? (
          <div
            className="tw-flex tw-flex-col lg:tw-flex-row tw-justify-between tw-items-center tw-w-full tw-text-sm"
          >
            {/* <Box display="flex" alignItems="center"> */}
            <Typography>
              {currentNpsQuestion1}
            </Typography>
            <input
              type="text"
              placeholder="Custom Text"
              // value={question.question_text}
              // onChange={(e) =>
              //   updateQuestion({ ...question, question_text: e.target.value })
              // }
              value={question.translations?.find(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)?.translated_text || ""}
              onChange={(e) => handleQuestionTextChange(e, 'change')}
              onBlur={(e) => handleQuestionTextChange(e, 'blur')}
              className="tw-border tw-rounded-md tw-w-full md:tw-w-[50%] tw-mr-1 tw-p-2 focus:tw-border-[#396d93] focus:tw-border-2 "
            />
            <Typography variant="body1">{currentNpsQuestion2}</Typography>
            {/* </Box> */}
          </div>
        ) : (
          <Box width="100%">
            <input
              // fullWidth
              type="text"
              placeholder="Question Text"
              // value={question.question_text}
              // onChange={(e) =>
              //   updateQuestion({ ...question, question_text: e.target.value })
              // }
              value={question.translations?.find(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)?.translated_text || ""}
              onChange={(e) => handleQuestionTextChange(e, 'change')}
              onBlur={(e) => handleQuestionTextChange(e, 'blur')}
              className="tw-border tw-text-base tw-rounded-md tw-w-full md:tw-w-[90%] tw-p-2 focus:tw-border-[#396d93] focus:tw-border-2"
            />
          </Box>
        )}
        <select
          value={question.question_type.type_name}
          onChange={updateQuestionType}
          className="tw-border tw-text-base tw-rounded-lg tw-p-3 focus:tw-border-[#396d93] focus:tw-border-2 tw-bg-white"
        >
          {questionTypesList?.map((val, key) => {
            return (
              <option
                key={key}
                data-id={val.id.toString()}
                value={val.type_name}
                className="tw-text-base"
              >
                {val.type_label}
              </option>
            );
          })}
        </select>
      </div>
      <div
        className="tw-flex tw-justify-between tw-items-center tw-w-full tw-flex-col md:tw-flex-row tw-gap-2 tw-mt-2"
      >
        <label
          style={{
            // margin: "0px 5px",
            minWidth: "130px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
          className="tw-text-base"
        >
          <input type="checkbox" 
          checked={question.question_options.some(
            (option) => option.validation && option.validation === "required"
          )}
          // onChange={(e) => updateQuestionOptions(e, "required")}
          onChange={(e) => updateQuestionOptions(e, 'required', 'This question requires an answer.')}
          className="tw-w-4 tw-h-4 tw-text-blue-600 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-blue-500 dark:focus:tw-ring-blue-600 dark:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
          />
          Required?
        </label>
        {question.question_options.length > 0 && (
          <>
            <OutlinedInput
              sx={{
                margin: "0px 10px",
                height: "40px",
                width: "100%",
                backgroundColor: "white",
              }}
              type="text"
              placeholder="Custom message"
              // value={customMessages.required || ""}
              value={question.question_options.filter(qo => qo.validation === 'required')[0].translations?.filter(t => t.language_code === selectedLanguageOptions[selectedTab].value)[0]?.translated_text || ""}
              onChange={(e) =>
                handleCustomMessageChange("required", e.target.value, "change")
              }
              onBlur={(e) =>
                handleCustomMessageChange("required", e.target.value, "blur")
              }
            />
            <Tooltip title="Display this error message when this question is not answered.">
              <InfoOutlined color="action" />
            </Tooltip>
          </>
        )}
      </div>

      {(question.question_type.type_name === "radio" ||
  question.question_type.type_name === "checkbox") && (
  <>
    {question.choices.map((choice, index) => (
      <Box
        key={choice.id || index}
        display="flex"
        alignItems="center"
        sx={{
          mt: 1,
          mb: 1,
        }}
      >
        {question.question_type.type_name === "radio" ? (
          <IconButton aria-label="radio" size="large" disabled>
            <CircleOutlined />
          </IconButton>
        ) : (
          <IconButton aria-label="checkbox" size="large" disabled>
            <SquareOutlined />
          </IconButton>
        )}

        <OutlinedInput
          fullWidth
          type="text"
          placeholder="Choice Text"
          value={choice.translations?.find(t => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "choice_text")?.translated_text || ""}
          onChange={(e) =>
            updateChoice(index, {
              ...choice,
              choice_text: e.target.value,
            }, 'change')
          }
          onBlur={(e) =>
            updateChoice(index, {
              ...choice,
              choice_text: e.target.value,
            }, 'blur')
          }
          sx={{
            margin: "0px 5px",
            height: "40px",
            backgroundColor: 'white',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: '#396d93',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: '#396d93',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#396d93',
            },
          }}
        />
        <label
          style={{
            margin: "0px 5px",
            minWidth: "120px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "5px",
          }}
          className="text-base"
        >
          <input
            type="checkbox"
            checked={!!choice.is_other}
            onChange={(e) =>
              updateChoice(index, {
                ...choice,
                is_other: e.target.checked ? 1 : 0,
              }, 'change')
            }
            className="tw-w-4 tw-h-4 tw-bg-gray-100 tw-border-gray-300 tw-rounded focus:tw-ring-[#396d93] dark:focus:tw-ring-[#396d93] dark:tw-ring-offset-gray-800 focus:tw-ring-2 dark:tw-bg-gray-700 dark:tw-border-gray-600"
          />
          Is Other
        </label>
        <Tooltip title="Add Choice">
          <IconButton
            aria-label="add"
            size="large"
            sx={{ color: "green" }}
            onClick={() => addChoice(index)}
          >
            <AddCircleOutline />
          </IconButton>
        </Tooltip>
        {question.choices.length > 1 && (
          <Tooltip title="Remove Choice">
            <IconButton
              aria-label="delete"
              size="large"
              sx={{ color: "red" }}
              onClick={() => removeChoice(index)}
            >
              <CancelOutlined />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ))}
  </>
)}
      {question.question_type.type_name === "matrix_rating" && (
  <Box display="flex" flexDirection="column" justifyContent="flex-start" sx={{ mt: 2, mb: 2 }}>
    <Typography variant="h6" sx={{ textAlign: "start" }}>Row Options</Typography>
    {question.matrix_row_options.map((matrix_row_option, index) => (
      <Box key={"matrix_row_option" + index} display="flex" alignItems="center" sx={{ mt: 1, mb: 1 }}>
        <OutlinedInput
          fullWidth
          type="text"
          placeholder="Row Text"
          value={matrix_row_option.translations?.find(t => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "row_option_text")?.translated_text || ""}
          onChange={(e) =>
            updateRowOption(index, {
              ...matrix_row_option,
              row_option_text: e.target.value,
            }, 'change')
          }
          onBlur={(e) =>
            updateRowOption(index, {
              ...matrix_row_option,
              row_option_text: e.target.value,
            }, 'blur')
          }
          sx={{
            margin: "0px 5px",
            height: "40px",
            backgroundColor: 'white',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.87)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#1976d2',
            },
          }}
        />
        <Tooltip title="Add Row">
          <IconButton
            aria-label="add"
            size="large"
            sx={{ color: "green" }}
            onClick={() => addRowOption("row", index)}
          >
            <AddCircleOutline />
          </IconButton>
        </Tooltip>
        {question.matrix_row_options.length > 1 && (
          <Tooltip title="Remove Row">
            <IconButton
              aria-label="delete"
              size="large"
              sx={{ color: "red" }}
              onClick={() => removeRowOption("row", index)}
            >
              <CancelOutlined />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ))}

    <Typography variant="h6" sx={{ textAlign: "start", mt: 4 }}>Column Options</Typography>
    {question.matrix_column_options.map((matrix_column_option, index) => (
      <Box key={"matrix_column_option" + index} display="flex" alignItems="center" sx={{ mt: 1, mb: 1 }}>
        <OutlinedInput
          fullWidth
          type="text"
          placeholder="Column Text"
          value={matrix_column_option.translations?.find(t => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "column_option_text")?.translated_text || ""}
          onChange={(e) =>
            updateColumnOption(index, {
              ...matrix_column_option,
              column_option_text: e.target.value,
            }, 'change')
          }
          onBlur={(e) =>
            updateColumnOption(index, {
              ...matrix_column_option,
              column_option_text: e.target.value,
            }, 'blur')
          }
          sx={{
            margin: "0px 5px",
            height: "40px",
            backgroundColor: 'white',
            '& .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.23)',
            },
            '&:hover .MuiOutlinedInput-notchedOutline': {
              borderColor: 'rgba(0, 0, 0, 0.87)',
            },
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#1976d2',
            },
          }}
        />
        <Tooltip title="Add Column">
          <IconButton
            aria-label="add"
            size="large"
            sx={{ color: "green" }}
            onClick={() => addRowOption("column", index)}
          >
            <AddCircleOutline />
          </IconButton>
        </Tooltip>
        {question.matrix_column_options.length > 1 && (
          <Tooltip title="Remove Column">
            <IconButton
              aria-label="delete"
              size="large"
              sx={{ color: "red" }}
              onClick={() => removeRowOption("column", index)}
            >
              <CancelOutlined />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    ))}
  </Box>
)}
      {question.question_type.type_name === "range" && (
  <Box display="flex" flexDirection="column">
    <Box
      display="flex"
      alignItems="center"
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginRight: "30px",
          minWidth: "110px",
          textAlign: "start",
        }}
      >
        Left side label
      </Typography>
      <OutlinedInput
        type="text"
        placeholder="Enter a label"
        value={question.slider_option?.translations?.find((t: any) => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "left_side_label")?.translated_text || ""}
        onChange={(e) =>
          updateSliderOption("left_side_label", e.target.value, 'change')
        }
        onBlur={(e) =>
          updateSliderOption("left_side_label", e.target.value, 'blur')
        }
        sx={{
          margin: "0px 5px",
          height: "40px",
          backgroundColor: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2',
          },
        }}
      />
    </Box>
    <Box
      display="flex"
      alignItems="center"
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginRight: "30px",
          minWidth: "110px",
          textAlign: "start",
        }}
      >
        Right side label
      </Typography>
      <OutlinedInput
        type="text"
        placeholder="Enter a label"
        value={question.slider_option?.translations?.find((t: any) => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "right_side_label")?.translated_text || ""}
        onChange={(e) =>
          updateSliderOption("right_side_label", e.target.value, 'change')
        }
        onBlur={(e) =>
          updateSliderOption("right_side_label", e.target.value, 'blur')
        }
        sx={{
          margin: "0px 5px",
          height: "40px",
          backgroundColor: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2',
          },
        }}
      />
    </Box>
    <Box
      display="flex"
      alignItems="center"
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginRight: "30px",
          minWidth: "110px",
          textAlign: "start",
        }}
      >
        Min. Range
      </Typography>
      <OutlinedInput
        type="text"
        placeholder="Enter a value"
        value={question.slider_option?.translations?.find((t: any) => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "min_value")?.translated_text.toString() || ""}
        onChange={(e) =>
          // updateSliderOption("min_value", parseInt(e.target.value))
          updateSliderOption("min_value", e.target.value, 'change')
        }
        sx={{
          margin: "0px 5px",
          height: "40px",
          backgroundColor: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2',
          },
        }}
      />
    </Box>
    <Box
      display="flex"
      alignItems="center"
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginRight: "30px",
          minWidth: "110px",
          textAlign: "start",
        }}
      >
        Max. Range
      </Typography>
      <OutlinedInput
        type="text"
        placeholder="Enter a value"
        value={question.slider_option?.translations?.find((t: any) => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "max_value")?.translated_text || ""}
        onChange={(e) =>
          // updateSliderOption("max_value", parseInt(e.target.value))
          updateSliderOption("max_value", e.target.value, 'change')
        }
        sx={{
          margin: "0px 5px",
          height: "40px",
          backgroundColor: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2',
          },
        }}
      />
    </Box>
    <Box
      display="flex"
      alignItems="center"
      sx={{
        mt: 2,
        mb: 2,
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          marginRight: "30px",
          minWidth: "110px",
          textAlign: "start",
        }}
      >
        Step Size
      </Typography>
      <OutlinedInput
        type="text"
        placeholder="Enter a value"
        value={question.slider_option?.translations?.find((t: any) => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "step_size_value")?.translated_text || ""}
        onChange={(e) =>
          // updateSliderOption("step_size_value", parseInt(e.target.value))
          updateSliderOption("step_size_value", e.target.value, 'change')
        }
        sx={{
          margin: "0px 5px",
          height: "40px",
          backgroundColor: 'white',
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.23)',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)',
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#1976d2',
          },
        }}
      />
    </Box>
  </Box>
)}
      {question.question_type.type_name === "rating" && (
        <Box display="flex" flexDirection="column">
          <Box
  display="flex"
  alignItems="center"
  sx={{
    mt: 2,
    mb: 2,
  }}
>
  <Typography
    variant="subtitle1"
    sx={{
      marginRight: "30px",
      minWidth: "110px",
      textAlign: "start",
    }}
  >
    Scale
  </Typography>
  <OutlinedInput
    type="text"
    placeholder="Enter a value"
    value={question.rating_option?.translations?.find((t: any) => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "scale")?.translated_text || ""}
    onChange={(e) =>
      updateRatingOption("scale", e.target.value)
    }
    sx={{
      margin: "0px 5px",
      height: "40px",
      backgroundColor: 'white',
      '& .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.23)',
      },
      '&:hover .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(0, 0, 0, 0.87)',
      },
      '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: '#1976d2',
      },
    }}
  />
</Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                marginRight: "30px",
                minWidth: "110px",
                textAlign: "start",
              }}
            >
              Shape
            </Typography>
            <select
              // value={question.rating_option?.shape ?? ""}
              // value={question.rating_option?.translations?.find((translation: any) => translation.language_code === selectedLanguageOptions[selectedTab].value)?.shape || ""}
              value={question.rating_option?.translations?.find((t: any) => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "shape")?.translated_text || ""}
              onChange={(e) => updateRatingOption("shape", e.target.value)}
              style={{
                width: "250px",
                height: "40px",
                minWidth: "100px",
                border: "1px solid #B0B8C4",
                borderRadius: "3px",
                boxShadow: "0px 2px 2px #F3F6F9",
              }}
              className="text-base"
            >
              <option value="star">Star</option>
              <option value="heart">Heart</option>
              <option value="smiley">Smiley</option>
              <option value="thumb">Thumb</option>
            </select>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            sx={{
              mt: 2,
              mb: 2,
            }}
          >
            <Typography
              variant="subtitle1"
              sx={{
                marginRight: "30px",
                minWidth: "110px",
                textAlign: "start",
              }}
            >
              Color
            </Typography>
            <input
              type="color"
              // value={question.rating_option?.color ?? ""}
              // value={question.rating_option?.translations?.find((translation: any) => translation.language_code === selectedLanguageOptions[selectedTab].value)?.color || ""}
              value={question.rating_option?.translations?.find((t: any) => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "color")?.translated_text || ""}
              onChange={(e) => updateRatingOption("color", e.target.value)}
            />
          </Box>
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        justifyContent="end"
        sx={{
          // mt: 2,
          mb: 2,
        }}
      >
        {/* <Button sx={{backgroundColor: "#03335b", color: "white"}} variant="contained" onClick={removeQuestion}>
          Remove Question
        </Button> */}
        <Button
          variant="contained"
          sx={{
            ml: 2,
            mr: 2,
            backgroundColor: "#03335b", color: "white"
          }}
          onClick={togglePreview}
        >
          Preview
        </Button>
      </Box>
      <Divider />
    </div>
  );
};

export default QuestionBuilder;