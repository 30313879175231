import React, { useState } from 'react';
import './CorporateDashboard.css';
import { Link } from 'react-router-dom';
import {useNavigate} from "react-router-dom";
import CorporatesEmployeesOnboarded from './component/CorpWellDashboard/CorporatesEmployeesOnboarded';
import CorporatePrograms from './component/CorpWellDashboard/CorporatePrograms';
import AgeBreakdownColoumn from './component/CorpWellDashboard/AgeBreakdownColoumn';
import GenderPieChart from './component/CorpWellDashboard/GenderPieChart';
import CorporateInsights from './component/CorpWellDashboard/CorporateInsights';
import SafetyLifestyleBar from './component/CorpWellDashboard/SafetyLifestyleBar';
import HRAPieChart from './component/CorpWellDashboard/HRAPieChart';
import BMIPieChart from './component/CorpWellDashboard/BMIPieChart';
import SAFETeamChart from './component/CorpWellDashboard/SAFETeamChart';
import HealthGoalsChart from './component/CorpWellDashboard/HealthGoalsChart';
import CorporateActiveEmployees from './component/CorpWellDashboard/CorporateActiveEmployees';
import ActiveCorporates from './component/CorpWellDashboard/ActiveCorporates';
import ManageCorporates from './ManageCorporates';




function CorporateDashboard () {

  const handleBackButton = () => {
    navigate('/blocker-pop-ups');
  }

  const navigate = useNavigate();


  
  return (
    <>
      <section className='CorporateDashboard '>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
                <div className="col-md" style={{
                display: 'flex',
                alignItems: 'center'
                }}>
                {/* <button className="btn btn-primary back-btn mb-0 me-3" onClick={handleBackButton}><i className="bi bi-chevron-left me-1"></i> Back</button> */}
                  <h1 className="main-title" style={{ margin: '0' }}>Employee Wellness Dashboard</h1>
              </div>
              {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" data-bs-toggle="modal" data-bs-target="#testCorporateDashboard">Test Blocker PopUps</Link></div> */}

          </div>

          <div className='corporate-dashboard p-4 mb-3'>

            <div className="row">
                <div className="col-md">
                  <h1 className="dashboard-title">Hello</h1>
                  {/* <h2 className="dashboard-subtitle mb-3">Lorem ipsum dolor sit amet, consectetur</h2> */}
                  <Link to="/add-new-corporate" className="btn program-save-btn">Add New Corporate</Link>
                </div>

                <div className="col-md">
                    <CorporatesEmployeesOnboarded/>
                </div>

            </div>
          </div>

          <div className='corporate-dashboard p-3 mb-3'>
              <div className="row">
                <ManageCorporates />
                 
              </div>
          </div>

          {/* <div className='corporate-dashboard p-3 mb-3'>
              <div className="row">
                  <div className="col">
                    <h1 className="dashboard-corpwell-title">Active Corporates</h1>
                  </div>
                  <div className="col-auto text-end">
                  <Link to="/manage-corporates" className="dashboard-corpwell-subtitle mb-0">See All</Link>
                  </div>
              </div>
              <div className="row">
                <ActiveCorporates/>
                 
              </div>
          </div>

          <div className='corporate-dashboard p-3 mb-3'>
              <div className="row">
                  <div className="col">
                    <h1 className="dashboard-corpwell-title">Programs</h1>
                  </div>
              </div>
              <div className="row">
                  <CorporatePrograms/>
              </div>
          </div>
          
          <div className='row'>

            <div className='col-md-4'>
                <div className='corporate-dashboard p-3 mb-3'>
                    <div className="row">
                      <div className="col-12">
                        <h1 className="dashboard-corpwell-title mb-0">Health Index</h1>
                      </div>
                      <div className='col-12'>
                        <h2 className='boxed-number'>
                          60
                        </h2>
                      </div>
                      <div className='col-12'>
                      <h2 className="dashboard-corpwell-subtitle text-center">Empowering Wellness, Measuring Success</h2> 
                      </div>
                  </div>
                </div>
            </div>

            <div className='col-md-4'>
                <div className='corporate-dashboard p-3 mb-3'>
                    <div className="row">
                      <div className="col-12">
                        <h1 className="dashboard-corpwell-title mb-0">Age Breakdown</h1>
                        <h2 className="dashboard-corpwell-subtitle">Average Age: <span>33</span></h2>
                      </div>
                      <div className='col-12'>
                          <AgeBreakdownColoumn/>
                      </div>
                  </div>
                </div>
            </div>

            <div className='col-md-4'>
                <div className='corporate-dashboard p-3 mb-3'>
                    <div className="row">
                      <div className="col-12">
                        <h1 className="dashboard-corpwell-title mb-0">Gender</h1>
                        <h2 className="dashboard-corpwell-subtitle">Players: <span>273</span></h2>
                      </div>
                      <div className='col-12'>
                          <GenderPieChart/>
                      </div>
                  </div>
                </div>
            </div>

          </div>


          <div className='corporate-dashboard p-3 mb-3'>
              <div className="row">
                  <div className="col">
                    <h1 className="dashboard-corpwell-title">Insights</h1>
                  </div>
              </div>
              <div className="row">
                  <CorporateInsights/>
              </div>
          </div>

          <div className='row'>

          <div className='col-md-4'>
                <div className='corporate-dashboard p-3 mb-3'>
                    <div className="row">
                      <div className="col-12">
                        <h1 className="dashboard-corpwell-title mb-0">Safety Lifestyle</h1>
                        <h2 className="dashboard-corpwell-subtitle">Elite: <span>33</span></h2>
                      </div>
                      <div className='col-12'>
                          <SafetyLifestyleBar/>
                      </div>
                  </div>
                </div>
            </div>

            <div className='col-md-4'>
                <div className='corporate-dashboard p-3 mb-3'>
                    <div className="row">
                      <div className="col-12">
                        <h1 className="dashboard-corpwell-title mb-0">Health Risk Assessment</h1>
                        <h2 className="dashboard-corpwell-subtitle">HRA: <span>69 (Healthy)</span></h2>
                      </div>
                      <div className='col-12'>
                          <HRAPieChart/>
                      </div>
                  </div>
                </div>
            </div>

            <div className='col-md-4'>
                <div className='corporate-dashboard p-3 mb-3'>
                    <div className="row">
                      <div className="col-12">
                        <h1 className="dashboard-corpwell-title mb-0">Body Mass Index</h1>
                        <h2 className="dashboard-corpwell-subtitle">BMI: <span>25.0 (Normal)</span></h2>
                      </div>
                      <div className='col-12'>
                          <BMIPieChart/>
                      </div>
                  </div>
                </div>
            </div>

            <div className='col-md-6'>
                <div className='corporate-dashboard p-3 mb-3'>
                    <div className="row">
                      <div className="col">
                        <h1 className="dashboard-corpwell-title">SAFE Team Metrics</h1>
                      </div>
                      <div className="col-auto">
                        <a href="#" className="dashboard-corpwell-subtitle">See All</a>
                      </div>
                      <div className='col-12'>
                          <SAFETeamChart/>
                      </div>
                  </div>
                </div>
            </div>

            <div className='col-md-6'>
                <div className='corporate-dashboard p-3 mb-3'>
                    <div className="row">
                      <div className="col">
                        <h1 className="dashboard-corpwell-title">Health Goals</h1>
                      </div>
                      <div className="col-auto">
                        <a href="#" className="dashboard-corpwell-subtitle">See All</a>
                      </div>
                      <div className='col-12'>
                          <HealthGoalsChart/>
                      </div>
                  </div>
                </div>
            </div>

          </div>

          <div className='corporate-dashboard p-3 mb-3'>
              <div className="row">
                  <div className="col">
                    <h1 className="dashboard-corpwell-title">Active Employees</h1>
                  </div>
              </div>
              <div className="row">
                  <CorporateActiveEmployees />
              </div>
          </div> */}

        </div>
      </section>

    </>
  );
}

export default CorporateDashboard ;
