import React from 'react'

const HeaderDesc = ({image, desc}) => {
  return (
    <div className='tw-flex tw-flex-row tw-justify-start tw-gap-4 tw-items-center tw-w-full tw-bg-white tw-rounded-2xl tw-shadow-md tw-p-4 tw-mt-4'>
        <img src={image} alt="image" className=' tw-h-[140px] tw-rounded-full' />
        <div className='tw-ml-4'>
            <p className=' tw-leading-loose tw-text-left'>{desc}</p>
        </div>
    </div>
  )
}

export default HeaderDesc