import React, {
  useState,
  useEffect,
  useContext,
  ChangeEvent,
  useRef,
} from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AppContext } from "../../../context/AppContextProvider";
import {
  fetchSurvey,
  submitSurveyBuilder,
  updateSurveyBuilder,
  uploadLogo,
} from "../../../services/SurveyFormService";
import PageBuilder from "./PageBuilder";
import {
  ExtendedSurveyFormDataBuilderTS,
  languageOptions,
  PagesDataBuilderTS,
  SurveyFormDataBuilderTS,
  TabData,
  TabPanelProps,
  TranslationTS,
} from "../../../utils/SurveyFormData";
import { motion } from "framer-motion";
import Srv from "../../../survey.png";
import { Pencil } from "lucide-react";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CheckCircle2Icon } from "lucide-react";
import SurveyLogo from "../../../survey_logo1.png"
import SurveyPreview from "./SurveyPreview";
import CustomAlertBox from "../CustomComponents/CustomAlertBox";
import { InputLabel, Tab, Tabs } from "@mui/material";
import Select from "react-dropdown-select";
import { SelectOptionType } from "../../../utils/CommunicationFormData";
import { translateTextToAllLanguages } from "../../../helpers/TranslationHelper";
import { Cancel } from "@mui/icons-material";

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

// Helper function to filter translations
function filterTranslations(translations: TranslationTS[] | undefined, selected: SelectOptionType[]) {
  return translations?.filter(
    (translation: any) => selected.some(option => option.value === translation.language_code)
  );
}

const SurveyBuilder = () => {
  const userId = 1;
  const navigate = useNavigate();
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isUpdating, setIsUpdating] = useState(false);
  const [activeTab, setActiveTab] = useState("build");
  const [showPreview, setShowPreview] = useState(false);

  const [open, setOpen] = React.useState(false);
  const [update, setUpdate] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleUpdate = () => setUpdate(true);
  const handleClose = () => setOpen(false);
  const handleUpdateClose = () => setUpdate(false);
  const [uniqueString, setUniqueString] = useState((Math.random() + 1).toString(36).substring(4));

  const surveyContext = useContext(AppContext);
  if (!surveyContext) {
    throw new Error("SurveyBuilder must be used within an AppContextProvider");
  }

  const {
    survey,
    setSurvey,
    isDisabledLogicsButton,
    setIsDisabledLogicsButton,
    selectedLanguageOptions,
    setSelectedLanguageOptions,
    selectedTab,
    setSelectedTab
  } = surveyContext;

  const [isEditingLogo, setIsEditingLogo] = useState(false);
  const [isEditingTitle, setIsEditingTitle] = useState(false);
  const [surveyNumber, setSurveyNumber] = useState(1);

  const [language, setLanguage] = useState("");
  // const [selectedLanguageOptions, setSelectedLanguageOptions] = useState<SelectOptionType[]>([]);
  // const [selectedTab, setSelectedTab] = useState(0);

  const [surveyLogoFile, setSurveyLogoFile] = useState<File | null>(null)

  const handleChange2 = (selected: SelectOptionType[]) => {
    setSelectedLanguageOptions(selected);

    if (selectedTab >= selected.length && selected.length !== 0) {
      setSelectedTab(selected.length - 1);
    }

    survey.translations = filterTranslations(survey.translations, selected);

    survey.pages.forEach((page) => {
      page.translations = filterTranslations(page.translations, selected);

      page.questions.forEach((question) => {
        if (question.question_options.length > 0) {
          question.question_options.forEach((option) => {
            option.translations = filterTranslations(option.translations, selected);
          });
        }

        if (question.question_type.type_name === "radio" || question.question_type.type_name === "checkbox") {
          question.choices.forEach((choice) => {
            choice.translations = filterTranslations(choice.translations, selected);
          });
        } else if (question.question_type.type_name === "matrix_rating") {
          question.matrix_row_options.forEach((rowOption) => {
            rowOption.translations = filterTranslations(rowOption.translations, selected);
          });
          question.matrix_column_options.forEach((columnOption) => {
            columnOption.translations = filterTranslations(columnOption.translations, selected);
          });
        } else if (question.question_type.type_name === "range") {
          question.slider_option.translations = filterTranslations(question.slider_option.translations, selected);
        } else if (question.question_type.type_name === "rating") {
          question.rating_option.translations = filterTranslations(question.rating_option.translations, selected);
        }
        // else {
        question.translations = filterTranslations(question.translations, selected);
        // }
      });
    });
    // const updatedTranslations = survey.translations?.filter(translation =>
    //   selected.some(option => option.value === translation.language_code)
    // );

    // setSurvey({
    //   ...survey,
    //   translations: updatedTranslations
    // });
    setSurvey(survey);
    // setSurvey({
    //   ...survey,
    //   translations: updatedTranslations,
    //   pages: survey.pages.map(page => {
    //     return (
    //       page.translations?.filter(translation =>
    //         selected.some(option => option.value === translation.language_code)
    //       )
    //     )
    //   })
    // });
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setSelectedTab(newValue);
  };

  useEffect(() => {
    if (id) {
      fetchSurveyFormData(id);
    }
    setSurvey({
      ...survey,
      user_id: userId,
      platform: "goqii",
      survey_type: "custom",
    });
    // }, [id, userId]);
  }, []);

  useEffect(() => {
    logicButtonDisabled(survey);
  }, [survey]);

  const logicButtonDisabled = (survey: ExtendedSurveyFormDataBuilderTS) => {
    if (survey.pages.length > 1) {
      survey.pages.forEach((page: PagesDataBuilderTS) => {
        page.questions?.forEach((question: any) => {
          if (
            question.question_type.type_name === "radio" ||
            question.question_type.type_name === "checkbox"
          ) {
            if (
              question.choices.length === 0 ||
              question.choices.some((choice: any) => choice.choice_text === "")
            ) {
              setIsDisabledLogicsButton(true);
            } else {
              setIsDisabledLogicsButton(false);
            }
          } else if (question.question_type.type_name === "rating") {
            if (
              question.rating_option &&
              (Object.keys(question.rating_option).length < 1 ||
                question.rating_option["scale"] === "")
            ) {
              setIsDisabledLogicsButton(true);
            } else {
              setIsDisabledLogicsButton(false);
            }
          } else if (question.question_type.type_name === "nps") {
            if (question.question_text === "") {
              setIsDisabledLogicsButton(true);
            } else {
              setIsDisabledLogicsButton(false);
            }
          } else {
            setIsDisabledLogicsButton(false);
          }
        });
      });
    } else {
      setIsDisabledLogicsButton(true);
    }
  };

  const addPage = () => {
    const newPage: PagesDataBuilderTS = {
      title: "",
      description: "",
      page_number: survey.pages.length + 1,
      questions: [],
    };
    setSurvey({
      ...survey,
      pages: [...survey.pages, newPage],
    });
  };

  const updatePage = (pageIndex: number, updatedPage: PagesDataBuilderTS) => {
    const updatedPages = survey.pages.map((page, index) =>
      index === pageIndex ? { ...updatedPage, page_number: index + 1 } : page
    );
    setSurvey({ ...survey, pages: recalculateOrder(updatedPages) });
  };

  const removePage = (pageIndex: number) => {
    const updatedPages = survey.pages
      .filter((_, index) => index !== pageIndex)
      .map((page, index) => ({ ...page, page_number: index + 1 }));
    const recalculatedPages = recalculateOrder(updatedPages);
    setSurvey({ ...survey, pages: recalculatedPages });
  };

  const getTotalQuestions = () => {
    return survey.pages.reduce(
      (total, page) => total + page.questions.length,
      0
    );
  };

  const recalculateOrder = (pages: PagesDataBuilderTS[]) => {
    let questionOrder = 1;
    return pages.map((page) => ({
      ...page,
      questions: page.questions.map((question) => ({
        ...question,
        order: questionOrder++,
      })),
    }));
  };

  const handleTemplateNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    let tempName = e.target.value;
    tempName = tempName.replace(/\s+/g, "-");
    tempName = tempName.replace(/-+/g, "-");
    tempName = tempName.toLowerCase();

    setSurvey({ ...survey, template_name: tempName });
  };

  const handleSurveySubmit = async (
    surveyBuilder: ExtendedSurveyFormDataBuilderTS
  ) => {

    if (!validatingSurveyBuilder(surveyBuilder, selectedLanguageOptions)) {
      return;
    }

    setIsSubmitting(true);
    try {
      if (surveyLogoFile && (surveyLogoFile instanceof File)) {
        const formData = new FormData();
        formData.append("file", surveyLogoFile);
        const fileData = await uploadLogo(formData);

        if (fileData.data.status === "success") {
          surveyBuilder.logo = fileData.data.response.url;
        } else {
          console.log("Logo upload failed:", fileData.data.message);
          return;
        }
      }
      const data = await submitSurveyBuilder(surveyBuilder);

      if (data.data.status === "success") {
        // alert(data.data.message);
        handleOpen();
        setTimeout(() => {
          navigate("/feedback360/home");
        }, 1000);
      } else {
        console.log(data.data.message);
        alert(data.data.message);
      }
    } catch (error: any) {
      console.log(error.response.data.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const fetchSurveyFormData = async (surveyId: any) => {
    setIsLoading(true);
    try {
      const data = await fetchSurvey(surveyId);

      if (data.data.status === "success") {
        setSurvey(data.data.response);

        const fetchedLanguageOptions = data.data.response.translations.map((translation: any) => {
          const matchedOption = languageOptions.find(option => option.value === translation.language_code);
          return matchedOption ? matchedOption : null;
        }).filter(Boolean);

        setSelectedLanguageOptions(fetchedLanguageOptions);
        setSelectedTab(0);
      } else {
        console.log(data.data.message);
        alert(data.data.message);
        navigate("/feedback360/home");
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  console.log(survey);

  const handleSurveyUpdate = async (surveyBuilder: ExtendedSurveyFormDataBuilderTS) => {

    if (!validatingSurveyBuilder(surveyBuilder, selectedLanguageOptions)) {
      return;
    }

    setIsUpdating(true);
    try {
      if (surveyLogoFile && (surveyLogoFile instanceof File)) {
        const formData = new FormData();
        formData.append("file", surveyLogoFile);
        const fileData = await uploadLogo(formData);

        if (fileData.data.status === "success") {
          surveyBuilder.logo = fileData.data.response.url;
        } else {
          console.log("Logo upload failed:", fileData.data.message);
          return;
        }
      }
      const data = await updateSurveyBuilder(surveyBuilder);

      if (data.data.status === "success") {
        // alert(data.data.message);
        handleUpdate();
        setTimeout(() => {
          navigate("/feedback360/home");
        }, 2000);
      } else {
        console.log(data.data.message);
        alert(data.data.message);
        window.scrollTo({
          top: 0,
          behavior: "smooth",
        });
      }
    } catch (error: any) {
      console.log(error.response.data.message);
    } finally {
      setIsUpdating(false);
    }
  };

  // const validatingSurveyBuilder = (surveyBuilder: SurveyFormDataBuilderTS) => {
  //   let errors = [];
  //   // const surveyError = surveyBuilder.title === "";
  //   // const surveyError = surveyBuilder.translations?.some(translation => translation.title === "");
  //   const surveyError = surveyBuilder.translations?.length === 0 ||
  //     surveyBuilder.translations?.length !== selectedLanguageOptions.length || 
  //     surveyBuilder.translations?.some(translation => translation.translated_text === "");
  //   // || surveyBuilder.template_name === "";
  //   if (surveyError) {
  //     // errors.push("Survey title/template cannot be empty.");
  //     errors.push("Survey title cannot be empty.");
  //   }

  //   const pageError = surveyBuilder.pages.some((page) => page.title === "");
  //   if (pageError) {
  //     errors.push("Page title cannot be empty.");
  //   }
  //   const questionTextError = surveyBuilder.pages.some((page) =>
  //     page.questions.some((question) => question.question_text === "")
  //   );
  //   if (questionTextError) {
  //     errors.push("Question text cannot be empty.");
  //   }

  //   const questionOptionsError = surveyBuilder.pages.some((page) =>
  //     page.questions.some((question) =>
  //       question.question_options.some(
  //         (qo) => qo.validation === "required" && qo.message === ""
  //       )
  //     )
  //   );
  //   if (questionOptionsError) {
  //     errors.push("Required question message cannot be empty.");
  //   }

  //   const questionChoicesError = surveyBuilder.pages.some((page) =>
  //     page.questions.some(
  //       (question) =>
  //         (question.question_type.type_name === "radio" ||
  //           question.question_type.type_name === "checkbox") &&
  //         (question.choices.length === 0 ||
  //           question.choices.some((choice) => choice.choice_text === ""))
  //     )
  //   );
  //   if (questionChoicesError) {
  //     errors.push("Question choices cannot be empty.");
  //   }

  //   const questionSliderError = surveyBuilder.pages.some((page) =>
  //     page.questions.some(
  //       (question) =>
  //         question.question_type.type_name === "range" &&
  //         (Object.keys(question.slider_option).length === 0 ||
  //           !question.slider_option.left_side_label ||
  //           question.slider_option.left_side_label === "" ||
  //           !question.slider_option.right_side_label ||
  //           question.slider_option.right_side_label === "" ||
  //           !question.slider_option.min_value ||
  //           question.slider_option.min_value === "" ||
  //           !question.slider_option.max_value ||
  //           question.slider_option.max_value === "" ||
  //           !question.slider_option.step_size_value ||
  //           question.slider_option.step_size_value === "")
  //     )
  //   );
  //   if (questionSliderError) {
  //     errors.push("Question slider options cannot be empty.");
  //   }

  //   const questionRatingError = surveyBuilder.pages.some((page) =>
  //     page.questions.some(
  //       (question) =>
  //         question.question_type.type_name === "rating" &&
  //         (Object.keys(question.rating_option).length === 0 ||
  //           !question.rating_option.color ||
  //           question.rating_option.color === "" ||
  //           !question.rating_option.shape ||
  //           question.rating_option.shape === "" ||
  //           !question.rating_option.scale ||
  //           question.rating_option.scale === "")
  //     )
  //   );
  //   if (questionRatingError) {
  //     errors.push("Question rating options cannot be empty.");
  //   }
  //   const questionMatrixError = surveyBuilder.pages.some((page) =>
  //     page.questions.some(
  //       (question) =>
  //         question.question_type.type_name === "matrix_rating" &&
  //         (question.matrix_row_options.length === 0 ||
  //           question.matrix_column_options.length === 0 ||
  //           question.matrix_row_options.some(
  //             (mro) => mro.row_option_text === ""
  //           ) ||
  //           question.matrix_column_options.some(
  //             (mco) => mco.column_option_text === ""
  //           ))
  //     )
  //   );
  //   if (questionMatrixError) {
  //     errors.push("Matrix question row/column cannot be empty.");
  //   }
  //   const questionNpsError = surveyBuilder.pages.some((page) =>
  //     page.questions.some(
  //       (question) =>
  //         question.question_type.type_name === "nps" &&
  //         question.question_text === ""
  //     )
  //   );
  //   if (questionNpsError) {
  //     errors.push("NPS question cannot be empty.");
  //   }
  //   if (errors.length > 0) {
  //     alert(errors.join("\n"));
  //     return false;
  //   }
  //   return true;
  // };
  const validatingSurveyBuilder = (surveyBuilder: SurveyFormDataBuilderTS, selectedLanguageOptions: SelectOptionType[]) => {
    let errors: string[] = [];

    // Validate survey translations
    selectedLanguageOptions.forEach(lang => {
      const missingSurveyTranslation = !surveyBuilder.translations?.some(translation =>
        translation.language_code === lang.value && translation.translated_text?.toString().trim() !== ""
      );
      if (missingSurveyTranslation) {
        errors.push(`Survey title is missing for language: ${lang.label} (${lang.value}).`);
      }
    });

    // Validate each page translations
    surveyBuilder.pages.forEach((page, pageIndex) => {
      selectedLanguageOptions.forEach(lang => {
        const missingPageTranslation = !page.translations?.some(translation =>
          translation.language_code === lang.value && translation.translated_text?.toString().trim() !== ""
        );
        if (missingPageTranslation) {
          // errors.push(`Page title is missing for language: ${lang.label} (${lang.value}) on page ${pageIndex + 1}.`);
          const existingTranslation = page.translations?.some(translation => translation.language_code === lang.value);

          if (!page.translations || page.translations.length === 0 || !existingTranslation) {
            page.translations?.push({
              language_code: lang.value,
              column_name: "title",
              translated_text: "",
            });
          }
        }
      });
    });

    // Validate each question's translations
    surveyBuilder.pages.forEach((page, pageIndex) => {
      page.questions.forEach((question, questionIndex) => {
        selectedLanguageOptions.forEach(lang => {
          const missingQuestionTranslation = !question.translations?.some(translation =>
            translation.language_code === lang.value && translation.translated_text?.toString().trim() !== ""
          );
          if (missingQuestionTranslation) {
            errors.push(`Question text is missing for language: ${lang.label} (${lang.value}) on page ${pageIndex + 1}, question ${questionIndex + 1}.`);
          }
        });
      });
    });

    // Validate choices for radio/checkbox questions
    surveyBuilder.pages.forEach((page, pageIndex) => {
      page.questions.forEach((question, questionIndex) => {
        if (["radio", "checkbox"].includes(question.question_type.type_name)) {
          question.choices.forEach((choice, choiceIndex) => {
            selectedLanguageOptions.forEach(lang => {
              const missingChoiceTranslation = !choice.translations?.some(translation =>
                translation.language_code === lang.value && translation.translated_text?.toString().trim() !== ""
              );
              if (missingChoiceTranslation) {
                errors.push(`Choice is missing for language: ${lang.label} (${lang.value}) on page ${pageIndex + 1}, question ${questionIndex + 1}, choice ${choiceIndex + 1}.`);
              }
            });
          });
        }
      });
    });

    // Validate matrix row/column options
    surveyBuilder.pages.forEach((page, pageIndex) => {
      page.questions.forEach((question, questionIndex) => {
        if (question.question_type.type_name === "matrix_rating") {
          question.matrix_row_options.forEach((rowOption, rowIndex) => {
            selectedLanguageOptions.forEach(lang => {
              const missingRowTranslation = !rowOption.translations?.some(translation =>
                translation.language_code === lang.value && translation.translated_text?.toString().trim() !== ""
              );
              if (missingRowTranslation) {
                errors.push(`Matrix row is missing for language: ${lang.label} (${lang.value}) on page ${pageIndex + 1}, question ${questionIndex + 1}, row ${rowIndex + 1}.`);
              }
            });
          });

          question.matrix_column_options.forEach((columnOption, columnIndex) => {
            selectedLanguageOptions.forEach(lang => {
              const missingColumnTranslation = !columnOption.translations?.some(translation =>
                translation.language_code === lang.value && translation.translated_text?.toString().trim() !== ""
              );
              if (missingColumnTranslation) {
                errors.push(`Matrix column is missing for language: ${lang.label} (${lang.value}) on page ${pageIndex + 1}, question ${questionIndex + 1}, column ${columnIndex + 1}.`);
              }
            });
          });
        }
      });
    });

    // Validate other question-specific fields
    surveyBuilder.pages.forEach((page, pageIndex) => {
      page.questions.forEach((question, questionIndex) => {
        if (question.question_options.length > 0) {
          question.question_options.forEach((option, optionIndex) => {
            if (option.validation === "required") {
              selectedLanguageOptions.forEach(lang => {
                const missingChoiceTranslation = !option.translations?.some(translation =>
                  translation.language_code === lang.value && translation.translated_text?.toString().trim() !== ""
                );
                if (missingChoiceTranslation) {
                  errors.push(`Required question message translation is missing for language: ${lang.label} (${lang.value}) on page ${pageIndex + 1}, question ${questionIndex + 1}.`);
                }
              });
            }
          });
        }
      });
    });

    surveyBuilder.pages.forEach((page, pageIndex) => {
      const sliderQuestions = page.questions.filter(question => question.question_type.type_name === "range");
      if (sliderQuestions.length > 0) {
        sliderQuestions.forEach((sliderQuestion, index) => {
          selectedLanguageOptions.forEach(lang => {
            const requiredFields = [
              "left_side_label",
              "right_side_label",
              "min_value",
              "max_value",
              "step_size_value"
            ];

            requiredFields.forEach(field => {
              const translation = sliderQuestion.slider_option.translations?.find(
                (t: any) => t.language_code === lang.value && t.column_name === field
              );

              const isMissingTranslation = !translation || !translation.translated_text?.toString().trim();

              if (isMissingTranslation) {
                errors.push(`Slider option "${field}" is missing for language: ${lang.label} (${lang.value}) on page ${pageIndex + 1}, question ${index + 1}.`);
              }
            });
          });
        });
      }
    });

    surveyBuilder.pages.forEach((page, pageIndex) => {
      const ratingQuestions = page.questions.filter(question => question.question_type.type_name === "rating");
      if (ratingQuestions.length > 0) {
        ratingQuestions.forEach((ratingQuestion, index) => {
          selectedLanguageOptions.forEach(lang => {
            const requiredFields = [
              "scale",
              "shape",
              "color",
            ];

            requiredFields.forEach(field => {
              const translation = ratingQuestion.rating_option.translations?.find(
                (t: any) => t.language_code === lang.value && t.column_name === field
              );

              const isMissingTranslation = !translation || !translation.translated_text?.toString().trim();

              if (isMissingTranslation) {
                errors.push(`Rating option "${field}" is missing for language: ${lang.label} (${lang.value}) on page ${pageIndex + 1}, question ${index + 1}.`);
              }
            });
          });
        });
      }
    });

    // Return validation result
    if (errors.length > 0) {
      alert(errors.join("\n"));
      return false;
    }
    return true;
  };


  const [isHovering, setIsHovering] = useState(false);
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    if (file) {
      setSurveyLogoFile(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setSurvey((prevSurvey) => ({
          ...prevSurvey,
          logo: reader.result as string,
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  const handleImageClick = () => {
    fileInputRef.current?.click();
  };

  // const handleSurveyTitleChange = (e: ChangeEvent<HTMLInputElement>) => {
  //   const updatedTitle = e.target.value;
  //   const templateName = uniqueString + "-" + e.target.value.replace(/\s+/g, "-").replace(/-+/g, "-").toLocaleLowerCase();

  //   const updatedTranslations = survey.translations?.map(translation =>
  //     translation.language_code === selectedLanguageOptions[selectedTab].value
  //       ? { ...translation, translated_text: updatedTitle }
  //       : translation
  //   );

  //   if (!updatedTranslations?.some(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)) {
  //     updatedTranslations?.push({ locale: selectedLanguageOptions[selectedTab].value, translated_text: updatedTitle });
  //   }

  //   setSurvey({
  //     ...survey,
  //     translated_text: e.target.value,
  //     template_name: templateName,
  //     translations: updatedTranslations
  //   });
  // }
  const handleSurveyTitleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputType: string, effectType: string) => {
    const updatedText = e.target.value;
    const templateName = uniqueString + "-" + e.target.value.replace(/\s+/g, "-").replace(/-+/g, "-").toLocaleLowerCase();
    const selectedLocale = selectedLanguageOptions[selectedTab].value;

    // Clone the translations or create a new array if it doesn't exist
    let updatedTranslations = survey.translations ? [...survey.translations] : [];

    // Find existing entries for the current locale and input type
    const existingEntryIndex = updatedTranslations.findIndex(
      translation => translation.language_code === selectedLocale && translation.column_name === inputType
    );

    if (existingEntryIndex !== -1) {
      // Update existing entry
      updatedTranslations[existingEntryIndex] = {
        ...updatedTranslations[existingEntryIndex],
        // [inputType]: updatedText,
        translated_text: updatedText,
      };
    } else {
      // Add a new entry
      updatedTranslations.push({
        language_code: selectedLocale,
        column_name: inputType,
        // [inputType]: updatedText,
        translated_text: updatedText,
      });
    }

    if (effectType === 'blur' && selectedLocale === 'en' && selectedLanguageOptions.length > 1) {
      const translationLanguages = selectedLanguageOptions.reduce((acc: any, l: any) => {
        return l.value !== "en" ? [...acc, l.value] : acc;
      }, []).join(",");

      translateTextToAllLanguages(updatedText, translationLanguages).then((translatedTexts) => {
        selectedLanguageOptions.forEach((option) => {
          if (option.value !== 'en') {
            const translationIndex = updatedTranslations.findIndex(
              translation => translation.language_code === option.value && translation.column_name === inputType
            );

            if (translationIndex !== -1) {
              updatedTranslations[translationIndex] = {
                ...updatedTranslations[translationIndex],
                translated_text: translatedTexts[option.value]
              };
            } else {
              updatedTranslations.push({
                language_code: option.value,
                column_name: inputType,
                translated_text: translatedTexts[option.value]
              });
            }
          }
        });

        // Update the page with the new translations
        setSurvey({
          ...survey,
          // title: e.target.value,
          // Below dependency fix.
          title: (updatedTranslations.length > 1 ? updatedTranslations.filter(t => t.language_code === "en")[0].translated_text : updatedTranslations[0].translated_text)?.toString().trim() ?? "",
          template_name: templateName,
          translations: updatedTranslations
        });
      });
    } else if (effectType === 'change') {
      // Update the page with the new translations
      setSurvey({
        ...survey,
        // title: e.target.value,
        // Below dependency fix.
        title: (updatedTranslations.length > 1 ? updatedTranslations.filter(t => t.language_code === "en")[0].translated_text : updatedTranslations[0].translated_text)?.toString().trim() ?? "",
        template_name: templateName,
        translations: updatedTranslations
      });
    }
  };

  return (
    <div className="tw-min-h-screen tw-bg-transparent tw-mb-16">
        <h2 className="tw-text-2xl tw-font-bold tw-mb-4">
          Creating Your Survey from Scratch
        </h2>
        <div className="tw-text-left tw-mx-auto tw-rounded-xl tw-shadow-lg tw-p-4 tw-bg-white tw-w-full tw-mb-6 tw-flex tw-justify-between">
          <img src={Srv} alt="survey" className="tw-w-48" />
          <p className="tw-text-gray-600 tw-text-lg tw-pl-5 tw-flex tw-justify-center tw-items-center">
            This interface allows users to create a custom survey as per your
            needs. Users can add or modify questions and customize the survey
            appearance.
          </p>
        </div>
        <motion.div
          initial={{ opacity: 0, scale: 0.9 }}
          animate={{ opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="tw-bg-white tw-rounded-xl tw-shadow-md tw-p-8"
        >
          {activeTab === "build" && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="tw-space-y-8"
            >
              <Box
                display='flex'
                flexDirection='column'
                alignItems="flex-start"
                sx={{
                  '& > :not(style)': { m: 0.5 }, pr: 2,
                }}>
                <InputLabel
                  htmlFor="sms_name"
                  sx={{ whiteSpace: "wrap", textAlign: "left" }}
                >
                  Languages
                </InputLabel>
                <Select
                  multi
                  options={languageOptions}
                  values={selectedLanguageOptions}
                  onChange={handleChange2}
                  searchable={false}
                  style={{
                    minWidth: "250px",
                    textAlign: "left",
                    fontSize: "18px",
                  }}
                />
              </Box>
              {/* <Tabs
                value={language}
                onChange={(e: React.SyntheticEvent, newValue: string) => setLanguage(newValue)}
              >
                {selectedLanguageOptions.map((langOpt, i) => {
                  return (
                    <Tab key={i} value={langOpt.value} label={langOpt.label} />
                  )
                })}
              </Tabs> */}
              {selectedLanguageOptions.length > 0 && (
                <Box sx={{ borderBottom: 1, borderColor: 'divider', marginTop: '20px' }}>
                  <Tabs value={selectedTab} onChange={handleTabChange} aria-label="language tabs">
                    {selectedLanguageOptions.map((option, index) => (
                      <Tab key={option.value} label={option.label} />
                    ))}
                  </Tabs>
                </Box>
              )}

              {selectedLanguageOptions.map((option, index) => (
                <CustomTabPanel key={option.value} value={selectedTab} index={index}>
                  <div className="tw-space-y-6">
                    <div className="tw-flex tw-flex-col tw-justify-center tw-items-center tw-w-full tw-rounded-2xl tw-bg-[#f0f4ff] tw-p-6 tw-gap-4">
                      {/* Survey Logo */}
                      <div
                        className="tw-relative tw-w-36 tw-h-36 tw-cursor-pointer tw-rounded-md tw-mx-auto border tw-border-indigo-200"
                        onMouseEnter={() => setIsHovering(true)}
                        onMouseLeave={() => setIsHovering(false)}
                        onClick={handleImageClick}
                      >
                        {survey.logo && <div
                          className="tw-absolute -tw-top-4 -tw-right-4 tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-rounded-md"
                          onClick={(e) => {e.stopPropagation(); setSurvey({...survey, logo: ""});}}
                        >
                          <Cancel fontSize="medium" color="info" />
                        </div>}
                        {survey.logo ? (
                          <img
                            src={survey.logo}
                            alt="Survey Logo"
                            className="tw-w-full tw-h-full tw-object-contain tw-rounded-md"
                          />
                        ) : (
                          <h2 className="tw-text-2xl tw-font-bold tw-text-gray-500 tw-mt-8 tw-text-center">Company Logo</h2>
                        )}

                        <input
                          ref={fileInputRef}
                          type="file"
                          onChange={handleLogoUpload}
                          className="tw-hidden"
                          accept="image/*"
                        />
                        {isHovering && (
                          <div className="tw-absolute tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-rounded-md">
                            <Pencil size={24} color="white" />
                          </div>
                        )}
                      </div>

                      {/* Survey Title */}
                      <div className="tw-relative tw-w-full">
                        {isEditingTitle ? (
                          <>
                            <input
                              type="text"
                              // value={survey.title}
                              // value={survey.translations?.find(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)?.title || ""}
                              value={survey.translations?.find(t => t.language_code === selectedLanguageOptions[selectedTab].value  && t.column_name === "title")?.translated_text || ""}
                              // value={(() => {
                              //   const selectedLanguage = selectedLanguageOptions[selectedTab].value;
                              //   const translation = survey.translations?.find(
                              //     t => t.language_code === selectedLanguage && t.column_name === "title"
                              //   )?.translated_text;

                              //   return selectedLanguage === "en" ? translation || "" : translation || "Translating...";
                              // })()}
                              onChange={(e) => handleSurveyTitleChange(e, 'title', 'change')}
                              onBlur={(e) => handleSurveyTitleChange(e, 'title', 'blur')}
                              // onBlur={() => setIsEditingTitle(false)}
                              className="tw-w-full tw-px-5 tw-py-3 tw-border-2 tw-border-[#03335b] tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#03335b] focus:tw-border-transparent tw-transition-all tw-duration-300 tw-text-3xl tw-font-bold"
                              autoFocus
                            />
                            <span
                              onClick={() => setIsEditingTitle(false)}
                              className="tw-absolute tw-cursor-pointer tw-top-0 tw-bottom-0 tw-my-auto tw-h-[80%] tw-right-2 tw-bg-[#03335b] tw-text-white tw-rounded-lg tw-px-2 tw-pt-3 hover:tw-bg-[#1279BE] tw-transition-colors"
                            >
                              Save
                            </span>
                          </>
                        ) : (
                          <h2 className="tw-text-3xl tw-font-bold tw-text-gray-500 tw-flex tw-items-center tw-justify-center tw-rounded-lg tw-py-4">
                            {/* {survey.title || `Survey Title ${surveyNumber} ${language}`} */}
                            {survey.translations?.find(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)?.translated_text || `Survey Title ${surveyNumber} ${language}`}
                            <span
                              onClick={() => setIsEditingTitle(true)}
                              className="tw-ml-2 tw-cursor-pointer"
                            >
                              <Pencil
                                size={20}
                                className="tw-text-gray-500 hover:tw-text-gray-700"
                              />
                            </span>
                          </h2>
                        )}
                      </div>
                    </div>


                    {/* Template Name */}
                    {/* <div className="flex items-center space-x-4">
                          <input
                            type="text"
                            placeholder="Template Name"
                            value={survey.template_name ?? ""}
                            onChange={handleTemplateNameChange}
                            className="flex-grow px-5 py-3 border-2 border-indigo-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300 text-lg"
                          />
                        </div> */}

                    {/* Survey Description */}
                    {/* <textarea
                          placeholder="Survey Description"
                          value={survey.description}
                          onChange={(e) => setSurvey({ ...survey, description: e.target.value })}
                          className="w-full px-5 py-3 border-2 border-indigo-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300 resize-none text-lg"
                          rows={4}
                        /> */}

                    {/* Pages */}
                    {survey.pages.map((page, index) => (
                      <motion.div
                        key={`page${index}`}
                        initial={{ opacity: 0, y: 20 }}
                        animate={{ opacity: 1, y: 0 }}
                        transition={{ duration: 0.5, delay: index * 0.1 }}
                        className="tw-mb-8"
                      >
                        {/* <div className="relative mb-4">
                      <input
                        type="text"
                        value={page.title}
                        onChange={(e) => updatePage(index, { ...page, title: e.target.value })}
                        placeholder="Page Title"
                        className="w-full px-5 py-3 border-2 border-indigo-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:border-transparent transition-all duration-300 text-xl font-semibold"
                      />
                      <Pencil size={20} className="absolute right-4 top-1/2 transform -translate-y-1/2 text-gray-500" />
                    </div> */}
                        <PageBuilder
                          page={page}
                          updatePage={(updatedPage) =>
                            updatePage(index, updatedPage)
                          }
                          removePage={() => removePage(index)}
                          getTotalQuestions={getTotalQuestions}
                        />
                      </motion.div>
                    ))}

                    <motion.span
                      onClick={addPage}
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="tw-w-full tw-cursor-pointer tw-bg-[#03335b] tw-shadow-lg tw-text-white tw-px-6 tw-py-3 tw-rounded-lg hover:tw-bg-[#03335b]/90 tw-transition-colors tw-duration-300 tw-flex tw-items-center tw-justify-center tw-text-lg tw-font-semibold"
                    >
                      <span className="tw-mr-2">New Page</span>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="tw-h-6 tw-w-6"
                        viewBox="0 0 20 20"
                        fill="currentColor"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </motion.span>
                  </div>
                </CustomTabPanel>
              ))}
              {showPreview && (
                <SurveyPreview
                  survey={survey}
                  onClose={() => setShowPreview(false)}
                  title={survey.title || `Survey Title ${surveyNumber}`}
                  logo={survey.logo || SurveyLogo}
                  selectedLanguages={selectedLanguageOptions}
                />
              )}

              <div className="tw-flex tw-justify-end tw-space-x-6 tw-mt-8">
                {selectedLanguageOptions.length > 0 &&
                  <motion.span
                    onClick={() => setShowPreview(true)}
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="tw-bg-white tw-cursor-pointer tw-border-[#03335b] tw-border-2 tw-text-[#03335b] tw-px-8 tw-py-3 tw-rounded-lg tw-transition-all tw-duration-300 tw-text-lg tw-font-semibold tw-shadow-md"
                  >
                    Preview
                  </motion.span>
                }
                {id && selectedLanguageOptions.length > 0 ? (
                  <motion.fieldset
                    onClick={() => handleSurveyUpdate(survey)}
                    disabled={
                      survey.pages.length < 1 ||
                      survey.pages[0]?.questions?.length < 1 ||
                      isUpdating
                    }
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="tw-bg-[#03335b] tw-cursor-pointer tw-text-white tw-px-8 tw-py-3 tw-rounded-lg hover:tw-bg-[#03335b]/70 tw-transition-colors tw-duration-300 disabled:tw-opacity-50 tw-text-lg tw-font-semibold tw-shadow-md"
                  >
                    Update
                  </motion.fieldset>
                ) : selectedLanguageOptions.length > 0 && (
                  <motion.button
                    onClick={() => handleSurveySubmit(survey)}
                    disabled={
                      survey.pages.length < 1 ||
                      survey.pages[0]?.questions?.length < 1 ||
                      isSubmitting
                    }
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="tw-bg-[#03335b] tw-text-white tw-px-8 tw-py-3 tw-rounded-lg hover:tw-bg-[#03335b]/80 tw-transition-colors tw-duration-300 disabled:tw-opacity-50 tw-text-lg tw-font-semibold tw-shadow-md"
                  >
                    Finish
                  </motion.button>
                )}
                {/* <motion.button
                            onClick={() => navigate("/home")}
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="bg-gray-200 text-gray-700 px-8 py-3 rounded-lg hover:bg-gray-300 transition-colors duration-300 text-lg font-semibold shadow-md"
                          >
                            Cancel
                          </motion.button> */}
              </div>
            </motion.div>
          )}

          {activeTab === "send" && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="tw-text-center"
            >
              <h2 className="tw-text-3xl tw-font-bold tw-mb-4 tw-text-indigo-800">
                Send Your Survey
              </h2>
              <p className="tw-text-gray-600 tw-text-lg">
                This section is under development.
              </p>
            </motion.div>
          )}

          {activeTab === "analyze" && (
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
              className="tw-text-center"
            >
              <h2 className="tw-text-3xl tw-font-bold tw-mb-4 tw-text-indigo-800">
                Analyze Survey Results
              </h2>
              <p className="text-gray-600 text-lg">
                This section is under development.
              </p>
            </motion.div>
          )}
        </motion.div>
      
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div>
            <CustomAlertBox
              type="success"
              title="Thank you"
              message="Your feedback has been submitted!!"
            />
          </div>
        </Fade>
      </Modal>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={update}
        onClose={handleUpdateClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={update}>
          <div>
            <CustomAlertBox
              type="success"
              title="Thank you"
              message="Your feedback has been updated!!"
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
};

export default SurveyBuilder;
