import {
  Container,
  FormControlLabel,
  InputLabel,
  OutlinedInput,
  Radio,
  RadioGroup,
} from "@mui/material";
import React, { ChangeEvent, useEffect, useState } from "react";
import {
  CommunicateFormTS,
  options,
  SelectOptionType,
} from "../../utils/CommunicationFormData";
import Select from "react-dropdown-select";
import { fetchSurveys } from "../../services/SurveyFormService";
import { createCommunication } from "../../services/CommunicationFormService";
import { useNavigate } from "react-router-dom";
import FormErrorBox from "../SurveyForm/FormElements/FormErrorBox";
import Backdrop from "@mui/material/Backdrop";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import { CheckCircle2Icon } from "lucide-react";
import CustomAlertBox from "../SurveyForm/CustomComponents/CustomAlertBox";

const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

const CommunicateForm = () => {
  const platform = "goqii";
  const [communicateForm, setCommunicateForm] = useState<CommunicateFormTS>({
    channel: "sms",
    sms_name: "",
    trigger_point: "",
    trigger_time: "",
    trigger_interval_time: "",
    feedback_name: "",
    sms_message:
      "Please take a moment to shared your feedback about the call with {name}. Click here to give feedback - {link}",
    language: "",
  });

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [communicateFormErrors, setCommunicateFormErrors] = useState<
    Partial<CommunicateFormTS>
  >({});

  const [feedbackOptions, setFeedbackOptions] = useState<SelectOptionType[]>(
    []
  );

  const [selectedOptions, setSelectedOptions] = useState<SelectOptionType[]>(
    []
  );
  const [selectedFeedbackOptions, setSelectedFeedbackOptions] = useState<
    SelectOptionType[]
  >([]);
  const [selectedOptions2, setSelectedOptions2] = useState<SelectOptionType[]>(
    []
  );
  const [isSubmitting, setIsSubmitting] = useState(false);
  const navigate = useNavigate();
  const [openInterval, setOpenInterval] = useState(false);

  const handleChange = (selected: SelectOptionType[]) => {
    if (selected.length > 0) {
      setSelectedOptions(selected);
      setCommunicateForm({
        ...communicateForm,
        trigger_point: selected[0].value,
      });

      setCommunicateFormErrors({
        ...communicateFormErrors,
        trigger_point: "",
      });
    }
  };

  const handleFeedbackOptionsChange = (selected: SelectOptionType[]) => {
    if (selected.length > 0) {
      setSelectedFeedbackOptions(selected);
      // const link = "https://survey.goqii.com/survey-form/" + selected[0].value + "/{userid}";

      setCommunicateForm((prev) => ({
        ...prev,
        feedback_name: selected[0].label,
        // sms_message: `Please take a moment to shared your feedback about the call with {name}. Click here to give feedback - ${link}`
      }));

      setCommunicateFormErrors({
        ...communicateFormErrors,
        feedback_name: "",
      });
    }
  };

  const handleChange2 = (selected: SelectOptionType[]) => {
    if (selected.length > 0) {
      setSelectedOptions2(selected);
      setCommunicateForm({
        ...communicateForm,
        language: selected[0].value,
      });
    }
  };

  const handleRadioChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const radio = event.target as HTMLInputElement;
    if (radio.value === "interval") {
      setOpenInterval(true);
    } else {
      setOpenInterval(false);
    }
    setCommunicateForm({
      ...communicateForm,
      trigger_time: radio.value,
    });
    setCommunicateFormErrors({
      ...communicateFormErrors,
      trigger_time: "",
    });
  };

  useEffect(() => {
    fetchAllSurveys(platform);
  }, []);

  // const fetchAllSurveys = async (platform: string, filter: string) => {
  const fetchAllSurveys = async (platform: string) => {
    const payload = {
      platform: platform,
      survey_type: "custom",
      // filter: filter
    };
    try {
      const data = await fetchSurveys(payload);

      if (data.data.status === "success") {
        setFeedbackOptions(
          data.data.response.map((data: any) => ({
            value: data.id,
            label: `${data.title} (${data.template_name ? data.template_name : ""
              })`,
          }))
        );
      } else {
        console.log(data.data.message);
        alert(data.data.message);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleInputChanges = (
    e: ChangeEvent<HTMLInputElement>,
    input_type: string
  ) => {
    let val = e.target.value;
    if (input_type === "time_interval" && val !== "") {
      if (/^[0-9:]*$/.test(val) && val.length <= 8) {
        setCommunicateForm({
          ...communicateForm,
          trigger_interval_time: val,
        });

        if (val.match(/^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/)) {
          setCommunicateFormErrors({
            ...communicateFormErrors,
            trigger_interval_time: "",
          });
        } else {
          setCommunicateFormErrors({
            ...communicateFormErrors,
            trigger_interval_time: "Invalid time interval format",
          });
        }
      }
    } else if (input_type === "sms_name" && val !== "") {
      setCommunicateForm({
        ...communicateForm,
        sms_name: val,
      });
      setCommunicateFormErrors({
        ...communicateFormErrors,
        sms_name: "",
      });
    } else if (val === "") {
      setCommunicateForm({
        ...communicateForm,
        [input_type]: "",
      });
    }
  };

  const handleSubmit = async () => {
    const validationErrors = validateForm(communicateForm);
    if (Object.keys(validationErrors).length === 0) {
      setIsSubmitting(true);
      try {
        const response = await createCommunication(communicateForm);

        if (response.data.status === "success") {
          // alert(response.data.message);
          handleOpen();
          setCommunicateForm({
            channel: "sms",
            sms_name: "",
            trigger_point: "",
            trigger_time: "",
            trigger_interval_time: "",
            feedback_name: "",
            sms_message:
              "Please take a moment to shared your feedback about the call with {name}. Click here to give feedback - {link}",
            language: "",
          });
          setSelectedOptions([]);
          setSelectedFeedbackOptions([]);
          setSelectedOptions2([]);
        } else {
          alert(response.data.message);
        }
      } catch (error) {
        console.log(error);
        alert(error);
      } finally {
        setIsSubmitting(false);
        setCommunicateFormErrors({});
      }
    } else {
      setCommunicateFormErrors(validationErrors);
    }
  };

  const validateForm = (form: CommunicateFormTS) => {
    const errors: Partial<CommunicateFormTS> = {};
    if (!form.sms_name) {
      errors.sms_name = "Please enter sms name.";
    }
    if (!form.trigger_point) {
      errors.trigger_point = "Please select trigger point.";
    }
    if (!form.trigger_time) {
      errors.trigger_time = "Please select trigger time.";
    }
    if (form.trigger_time === "interval") {
      if (!form.trigger_interval_time) {
        errors.trigger_interval_time = "Please enter trigger interval time.";
      } else if (
        !form.trigger_interval_time.match(
          /^([01]\d|2[0-3]):([0-5]\d):([0-5]\d)$/
        )
      ) {
        errors.trigger_interval_time =
          "Invalid time interval format. Must be HH:MM:SS.";
      }
    }
    if (!form.feedback_name) {
      errors.feedback_name = "Please select feedback name.";
    }
    // if (!form.language) {
    //     errors.language = 'Please select language.';
    // }
    return errors;
  };

  console.log(communicateForm);

  return (
    <Container>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        sx={{
          "& > :not(style)": { m: 0.5 },
          m: 2,
          pl: 2,
          pr: 2,
        }}
      >
        <Typography variant="h4">SMS Collector</Typography>
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{
          "& > :not(style)": { m: 0.5 },
          m: 2,
          pl: 2,
          pr: 2,
        }}
      >
        <InputLabel
          htmlFor="sms_name"
          sx={{ whiteSpace: "wrap", textAlign: "left" }}
        >
          SMS Name
        </InputLabel>
        <OutlinedInput
          // fullWidth
          id="sms_name"
          aria-describedby="my-helper-text"
          sx={{ boxSizing: "border-box", minWidth: "250px", height: "40px" }}
          name="sms_name"
          value={communicateForm.sms_name || ""}
          onChange={(e: ChangeEvent<HTMLInputElement>) =>
            handleInputChanges(e, "sms_name")
          }
        />
        {communicateFormErrors.sms_name && (
          <FormErrorBox error={communicateFormErrors.sms_name} />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{
          "& > :not(style)": { m: 0.5 },
          m: 2,
          pl: 2,
          pr: 2,
        }}
      >
        {/* {error && <FormErrorBox error={error} />} */}
        <InputLabel
          htmlFor="sms_name"
          sx={{ whiteSpace: "wrap", textAlign: "left" }}
        >
          Trigger Point
        </InputLabel>
        <Select
          options={options}
          values={selectedOptions}
          onChange={handleChange}
          searchable={false}
          style={{
            minWidth: "250px",
            textAlign: "left",
          }}
        />
        {communicateFormErrors.trigger_point && (
          <FormErrorBox error={communicateFormErrors.trigger_point} />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{
          "& > :not(style)": { m: 0.5 },
          m: 2,
          pl: 2,
          pr: 2,
        }}
      >
        <InputLabel
          htmlFor="demo-radio-buttons-group-label"
          sx={{ whiteSpace: "wrap", textAlign: "left" }}
        >
          When?
        </InputLabel>
        <RadioGroup
          row
          name="row-radio-buttons-group"
          onChange={handleRadioChange}
        >
          <FormControlLabel
            value="immediate"
            checked={communicateForm.trigger_time === "immediate"}
            control={<Radio />}
            label="Immediately"
          />
          <FormControlLabel
            value="interval"
            checked={communicateForm.trigger_time === "interval"}
            control={<Radio />}
            label="Interval"
          />
        </RadioGroup>
        {communicateFormErrors.trigger_time && (
          <FormErrorBox error={communicateFormErrors.trigger_time} />
        )}
      </Box>
      {openInterval && (
        <>
          <Box
            display="flex"
            flexDirection="column"
            alignItems="flex-start"
            sx={{
              "& > :not(style)": { m: 0.5 },
              m: 2,
              pl: 2,
              pr: 2,
            }}
          >
            <InputLabel
              htmlFor="sms_name"
              sx={{ whiteSpace: "wrap", textAlign: "left" }}
            >
              Time Interval
            </InputLabel>
            <OutlinedInput
              // fullWidth
              id="sms_name"
              aria-describedby="my-helper-text"
              sx={{
                boxSizing: "border-box",
                minWidth: "250px",
                height: "40px",
              }}
              name="sms_name"
              placeholder="HH:MM:SS (24 hrs format)"
              value={communicateForm.trigger_interval_time || ""}
              onChange={(e: ChangeEvent<HTMLInputElement>) =>
                handleInputChanges(e, "time_interval")
              }
            />
            {communicateFormErrors.trigger_interval_time && (
              <FormErrorBox
                error={communicateFormErrors.trigger_interval_time}
              />
            )}
          </Box>
        </>
      )}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{
          "& > :not(style)": { m: 0.5 },
          m: 2,
          pl: 2,
          pr: 2,
        }}
      >
        {/* {error && <FormErrorBox error={error} />} */}
        <InputLabel
          htmlFor="sms_name"
          sx={{ whiteSpace: "wrap", textAlign: "left" }}
        >
          Feedback Name
        </InputLabel>
        <Select
          options={feedbackOptions}
          values={selectedFeedbackOptions}
          onChange={handleFeedbackOptionsChange}
          dropdownHeight="200px"
          style={{
            minWidth: "250px",
            textAlign: "left",
          }}
        />
        {communicateFormErrors.feedback_name && (
          <FormErrorBox error={communicateFormErrors.feedback_name} />
        )}
      </Box>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{
          "& > :not(style)": { m: 0.5 },
          m: 2,
          pl: 2,
          pr: 2,
        }}
      >
        <InputLabel
          htmlFor="my-input"
          sx={{ whiteSpace: "wrap", textAlign: "left" }}
        >
          SMS Message
        </InputLabel>
        <textarea
          className="custom_textarea"
          cols={50}
          rows={3}
          name="demo-textarea"
          disabled
          value={communicateForm.sms_message || ""}
          style={{
            backgroundColor: "lightgray",
          }}
        />
      </Box>
      {/* <Box
                display='flex'
                flexDirection='column'
                alignItems="flex-start"
                sx={{
                    '& > :not(style)': { m: 0.5 }, m: 2, pl: 2, pr: 2,
                }}>
                <InputLabel
                    htmlFor="sms_name"
                    sx={{ whiteSpace: "wrap", textAlign: "left" }}
                >
                    Language
                </InputLabel>
                <Select
                    options={options2}
                    values={selectedOptions2}
                    onChange={handleChange2}
                    searchable={false}
                    style={{
                        minWidth: "250px",
                        textAlign: "left"
                    }}
                />
                {communicateFormErrors.language && <FormErrorBox error={communicateFormErrors.language} />}
            </Box> */}
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{
          "& > :not(style)": { m: 0.5 },
          m: 2,
          pl: 2,
          pr: 2,
        }}
      >
        <Button
          variant="contained"
          onClick={handleSubmit}
          disabled={isSubmitting}
        >
          Submit
        </Button>
      </Box>

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={open}
        onClose={handleClose}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={open}>
          <div>
            <CustomAlertBox
              type="success"
              title="Thank you"
              message="Your feedback has been submitted!!"
            />
          </div>
        </Fade>
      </Modal>
    </Container>
  );
};

export default CommunicateForm;
