import React, { useState } from 'react';
import { Box, SxProps, Theme } from '@mui/material';
import { FavoriteBorderOutlined, Favorite, InsertEmoticon, EmojiEmotions, ThumbUpOffAlt, ThumbUpAlt, StarOutline, Star } from '@mui/icons-material';
import { RTLRatingProps } from '../../../utils/SurveyFormData';

const RatingRTL = ({ max, value, onChange, ratingOptionShape, rating_option, currentLanguage }: RTLRatingProps) => {
  const [hover, setHover] = useState<number>(-1);

  const handleMouseMove = (event: React.MouseEvent<HTMLDivElement>) => {
    const { left, width } = event.currentTarget.getBoundingClientRect();
    const percent = (event.clientX - left) / width;
    setHover(() => {
        return currentLanguage === "ar" ? Math.ceil((1 - percent) * max) : Math.ceil(percent * max);
    });
  };

  const handleMouseLeave = () => {
    setHover(-1);
  };

  const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
    const { left, width } = event.currentTarget.getBoundingClientRect();
    const percent = (event.clientX - left) / width;
    const newValue = currentLanguage === "ar" ? Math.ceil((1 - percent) * max) : Math.ceil(percent * max);
    onChange(event, newValue);
  };

  const getIcon = (index: number) => {
    const filled = (hover !== -1 ? hover : value) >= index;
    switch (ratingOptionShape) {
      case "heart":
        return filled ? <Favorite fontSize="inherit" /> : <FavoriteBorderOutlined style={{ opacity: 0.55 }} fontSize="inherit" />;
      case "smiley":
        return filled ? <EmojiEmotions fontSize="inherit" /> : <InsertEmoticon style={{ opacity: 0.55 }} fontSize="inherit" />;
      case "thumb":
        return filled ? <ThumbUpAlt fontSize="inherit" /> : <ThumbUpOffAlt style={{ opacity: 0.55 }} fontSize="inherit" />;
      default:
        return filled ? <Star fontSize="inherit" /> : <StarOutline style={{ opacity: 0.55 }} fontSize="inherit" />;
    }
  };

  const boxSx: SxProps<Theme> = {
    direction: 'ltr',
    display: 'flex',
    flexDirection: currentLanguage === "ar" ? 'row-reverse' : 'row',
    fontSize: '2.5rem',
    width: '100%',
    justifyContent: 'space-around',
    cursor: 'pointer',
  };

  const iconSx = (index: number): SxProps<Theme> => ({
    color: (hover !== -1 ? hover : value) >= index ? rating_option.color : 'rgba(0, 0, 0, 0.26)',
    transition: 'all 0.2s linear',
    transform: (hover !== -1 ? hover : value) >= index ? 'scale(1.2)' : 'scale(1)',
  });

  return (
    <Box
      sx={boxSx}
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      onClick={handleClick}
    >
      {[...Array(max)].map((_, index) => (
        <Box
          key={index}
          sx={iconSx(index + 1)}
        >
          {getIcon(index + 1)}
        </Box>
      ))}
    </Box>
  );
};

export default RatingRTL;