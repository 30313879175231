import React, { useEffect, useState } from 'react';
import { motion } from 'framer-motion';
import { Check } from 'lucide-react';
import Confetti from 'react-confetti';
import { useNavigate } from 'react-router-dom';

const ThankYou = () => {
  const [showConfetti, setShowConfetti] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowConfetti(false);
    }, 5000); // Stop confetti after 5 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleOkayClick = () => {
    setShowConfetti(false);
    navigate('/feedback360/home');
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-justify-center tw-min-h-screen tw-bg-gray-100">
      {showConfetti && <Confetti />}
      
      <motion.div
        initial={{ scale: 0 }}
        animate={{ scale: 1 }}
        transition={{ duration: 0.5 }}
        className="tw-bg-white tw-p-8 tw-rounded-lg tw-shadow-lg tw-text-center"
      >
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ delay: 0.5, type: 'spring', stiffness: 500 }}
          className="mb-4"
        >
          <Check className="tw-w-16 tw-h-16 tw-mx-auto tw-text-green-500" />
        </motion.div>
        
        <h1 className="tw-text-3xl tw-font-bold tw-mb-4">Thank You</h1>
        <p className="tw-text-gray-600 tw-mb-6">Your form has been submitted successfully.</p>
        
        {/* <motion.button
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="bg-green-500 text-white px-6 py-2 rounded-full font-semibold"
          onClick={handleOkayClick}
        >
          Okay
        </motion.button> */}
      </motion.div>
    </div>
  );
};

export default ThankYou;