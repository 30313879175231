import React, { useState, useEffect, useRef } from 'react';
import { FaUpload, FaArrowLeft, FaEye, FaPlus, FaPlay, FaVideo, FaCloudUploadAlt, FaTrash } from 'react-icons/fa';
import HeaderDesc from './HeaderDesc';
import Modal from './Modal';
import Select from 'react-select';
import { Tooltip } from 'react-tippy';
import { useParams } from 'react-router-dom';
import { useNavigate } from "react-router-dom";
import {
    fetch_all_categories, fetch_all_tags, fetch_all_programs, fetch_all_conditions, fetch_all_pathways, save_post, fetch_single_article,
    update_post, getPresignedUrl, generateviewurl, sendApproval, log_data, addNewCategoryTag, fetchAuthors, save_video_post
} from "../../../services/cms/Service";
import axios from 'axios';

const LibraryVideoUpload = ({ videoData, onPreview, onCancel, onBack, categoriesList = [], tagsList = [], onAddCategory, onAddTag }) => {

    const { videoId } = useParams();
    const navigate = useNavigate();

    const [video, setVideo] = useState({
        title: '',
        description: '',
        videoUrl: "",
        thumbnailUrl: '',
        contentType: 'Workout Video',
        program: '',
        condition: '',
        pathway: '',
        language: 'English',
        author: [],
        categories: [],
        tags: [],
        publishedDate: new Date().toISOString().split('T')[0],
        postType: 'upload_video',
        videoType: '',
        activityType: '',
        language: '',
        duration: ''
    });

    console.log(video, "video")



    const [programs, setPrograms] = useState([]);
    const [conditions, setConditions] = useState([]);
    const [pathways, setPathways] = useState([]);
    const [activity, setActivity] = useState([
        { id: 1, activityName: "Walk" },
        { id: 2, activityName: "Run" },
        { id: 3, activityName: "Weights" },
        { id: 4, activityName: "Calisthenics" },
        { id: 5, activityName: "Swimming" },
        { id: 6, activityName: "Stairs" },
        { id: 7, activityName: "Yoga" },
        { id: 8, activityName: "Dance" },
        { id: 9, activityName: "Aerobics" },
        { id: 10, activityName: "Jumprope" }
    ]);
    const [language, setLanguage] = useState([{ id: 1, languageName: "English" }, { id: 2, languageName: "Hindi" }, { id: 3, languageName: "Hinglish" }]);
    const [videoType, setVideoType] = useState([{ id: 1, videoType: "Portrait" }, { id: 2, videoType: "Landscape" }, { id: 3, videoType: "Landscape Pose Detection" }]);
    const [categories, setCategories] = useState([]);
    const [tags, setTags] = useState(tagsList);

    const [file, setFile] = useState(null);
    const [uploadUrl, setUploadUrl] = useState('');
    const [videoUrl, setVideoUrl] = useState('');
    const [uploadProgress, setUploadProgress] = useState(0);
    const [uploading, setUploading] = useState(false);
    const [authors, setAuthors] = useState([]);
    // Word limits
    const TITLE_LIMIT = 200;
    const DESCRIPTION_LIMIT = 300;

    const getWordCount = (text) => {
        return text.trim().split(/\s+/).length;
    };

    const getCharacterCount = (text) => {
        if (typeof text !== 'string') {
            return 0;
        }
        return text.length;
    };

    useEffect(() => {
        if (videoData) {
            setVideo(prevVideo => ({ ...prevVideo, ...videoData }));
        }
    }, [videoData]);

    useEffect(() => {
        fetchAllConditions();
        fetchAllPathways();
        fetchCategories();
        fetchTags();
        fetchPrograms();
        fetch_Authors()
    }, []);



    const [showCategoryModal, setShowCategoryModal] = useState(false);
    const [newCategory, setNewCategory] = useState('');
    const [newSubCategory, setNewSubCategory] = useState('');
    const videoRef = useRef(null);

    const suggestedCategories = ['Lifestyle', 'Nutrition', 'Mental Health', 'Exercise'];

    const handleInputChange = (field, value) => {
        if (field === 'title' && getCharacterCount(value) > TITLE_LIMIT) return;
        if (field === 'description' && getCharacterCount(value) > DESCRIPTION_LIMIT) return;
        if (field === 'categories' || field === 'tags') {
            console.log(`Updating ${field} with:`, value);
            setVideo(prevVideo => ({ ...prevVideo, [field]: value }));
        } else if (field === "program") {
            setVideo(prevBlog => ({ ...prevBlog, program: value }));
            fetchAllConditions(value);
        } else if (field === 'author') {
            setVideo(prevBlog => ({ ...prevBlog, [field]: value }));
        } else if (field === 'contentType') {
            setVideo(prevBlog => ({ ...prevBlog, [field]: value }));
        } else if (field === "condition") {
            setVideo(prevBlog => ({ ...prevBlog, condition: value }));
            fetchAllPathways(video.program, value);
        }
        else {
            console.log(`Updating ${field} with:`, value);
            setVideo(prevVideo => ({ ...prevVideo, [field]: value }));
        }
    };

    // const handleVideoUpload = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         const videoUrl = URL.createObjectURL(file);
    //         handleInputChange('videoUrl', videoUrl);
    //         handleInputChange('postType', 'local');
    //         handleInputChange('thumbnailUrl', ''); // Clear thumbnail for local videos
    //     }
    // };



    const handleUpload = async (e) => {
        try {
            setFile(e.target.files[0]);
            const file = e.target.files[0]

            const videoElement = document.createElement('video');
            videoElement.src = URL.createObjectURL(file);

            videoElement.addEventListener('loadedmetadata', () => {
                const videoDuration = videoElement.duration; // Duration in seconds
                console.log('Video Duration:', videoDuration);
                setVideo((prev) => ({
                    ...prev,
                    duration: videoDuration
                }));
            })
            // Send the file URL to the backend to get the presigned URL
            const response = await getPresignedUrl();

            const url = response.data.data;

            if (url) {
                setUploadUrl(url);
                setUploading(true);

                if (url) {
                    const result = await axios.put(url, file, {
                        headers: {
                            'Content-Type': file.type || 'application/octet-stream',
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setUploadProgress(percentCompleted);
                        },
                    });
                    setUploading(false);
                    setVideoUrl(result.config.url);
                    if (result.status === 200) {
                        const res = await generateviewurl({ presignedUrl: result.config.url });
                        const videoUrl = res.data.data
                        console.log(videoUrl, "videoUrl")
                        setVideo((prev) => ({
                            ...prev,
                            videoUrl: videoUrl,
                            postType: 'upload_video', // or 'youtube' if it's a YouTube link
                        }));
                        alert('File uploaded successfully');
                    } else {
                        alert('Failed to upload file');
                    }
                }
            } else {
                alert('Failed to get upload URL');
            }
        } catch (error) {
            console.error('Error uploading file:', error);
            alert('An error occurred during file upload');
        }
    };

    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleSubmit = async () => {

        const { title, description, program, pathway, condition, thumbnailUrl, contentType, language, videoType, activityType, duration } = video;
        let categoryIds = video.categories.map((category) => category.value);
        let tagIds = video?.tags?.map((tag) => tag.value) || 0;
        let authorName = video.author.map((author) => author.label);
        // let videoURL = `<iframe
        //       width="100%"
        //       height="100%"
        //       src=${video.videoUrl}
        //       title="YouTube video player"
        //       frameBorder="0"
        //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        //       allowFullScreen
        //       className="tw-w-full tw-aspect-video"
        //     ></iframe>`;
        let videoURL = video.videoUrl
        let data = { title, description, thumbnailUrl, language, duration, activityType, videoType, contentType, postType: "upload_video", author: authorName ? JSON.stringify(authorName) : '', content: videoURL, programId: program ? parseInt(program) : 0, conditionId: condition ? parseInt(condition) : 0, pathwayId: pathway ? parseInt(pathway) : 0, categories: JSON.stringify(categoryIds), tags: JSON.stringify(tagIds) };


        try {
            const saveResp = await save_video_post(data);
            console.log(saveResp, "saveResp")
            if (saveResp?.data?.code === 200) {
                let id = saveResp?.data?.data?.pId;
                log_data({ pid: id, action: "send for Draft" })
                // Second API call: sendApproval using the post ID
                const approvalResp = await sendApproval({ pid: id });
                log_data({ pid: id, action: "send for Approval" })
                // Check response for the second API call
                if (approvalResp?.data?.data?.message === "Article Pending Approval") {
                    setIsModalOpen(true);
                    navigate('/content-management/content-status');
                }
            }
        } catch (e) {
            console.error("Error during approval process:", e);
        }

    };


    const handleBack = () => {
        navigate(`/content-management/create-content`);
    };

    const handleDraft = async (shouldNavigate = true) => {

        const { title, description, program, pathway, condition, thumbnailUrl, contentType, language, videoType, activityType, duration } = video;
        let categoryIds = video.categories.map((category) => category.value);
        let tagIds = video?.tags?.map((tag) => tag.value) || 0;
        let authorName = video.author.map((author) => author.label);
        // let videoURL = `<iframe
        //       width="100%"
        //       height="100%"
        //       src=${video.videoUrl}
        //       title="YouTube video player"
        //       frameBorder="0"
        //       allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        //       allowFullScreen
        //       className="tw-w-full tw-aspect-video"
        //     ></iframe>`;
        let videoURL = video.videoUrl
        let data = { title, description, thumbnailUrl, language, activityType, videoType, duration, contentType, postType: "upload_video", author: authorName ? JSON.stringify(authorName) : '', content: videoURL, programId: program ? parseInt(program) : 0, conditionId: condition ? parseInt(condition) : 0, pathwayId: pathway ? parseInt(pathway) : 0, categories: JSON.stringify(categoryIds), tags: JSON.stringify(tagIds) };


        try {
            const resp = await save_video_post(data);
            if (resp?.data?.code === 200) {
                if (!shouldNavigate) return resp?.data?.data?.pId;
                let id = resp?.data?.data?.pId;
                log_data({ pid: id, action: "send for Drafts" })
                navigate(`/content-management/create-content`);
            } else {
            }
            setIsModalOpen(true);
        } catch (e) {
            console.error("Error during approval process:", e);
        }

    };

    const handleCreateCategory = () => {
        if (newCategory) {
            const newCategoryObject = {
                value: newCategory.toLowerCase(),
                label: newCategory,
                subCategories: newSubCategory ? [newSubCategory] : []
            };
            setCategories([...categories, newCategoryObject]);
            setVideo(prevVideo => ({
                ...prevVideo,
                categories: [...prevVideo.categories, newCategoryObject]
            }));
            setNewCategory('');
            setNewSubCategory('');
            add_New_Category(newCategory)
            setShowCategoryModal(false);
        }
    };
    const handlePreview = async () => {
        let pid = await handleDraft(false);
        log_data({ pid: pid, action: "send for Drafts" })
        navigate(`/content-management/preview-post/${pid}`);
    };
    const handlePlayVideo = () => {
        if (videoRef.current) {
            videoRef.current.style.display = 'block'; // Make the video visible
            videoRef.current.play(); // Play the video
        }
    };

    // fetch blog content categories 
    async function fetchCategories() {
        try {
            let response = await fetch_all_categories();

            if (response.data?.code === 200) {
                setCategories(response?.data?.data?.categories || []);
            } else {
                throw new Error("Failed to fetch categories");
            }
        } catch (e) {

        }
    }

    async function fetch_Authors() {
        try {

            let response = await fetchAuthors();
            if (response) {
                console.log(response.data.data.details, "response")

                setAuthors(response.data.data.details)

            } else {
                throw new Error("Failed to fetch data");
            }
        } catch (e) {
            console.error(e);
        }
    }

    // fetch list of all tags 
    async function fetchTags() {
        try {
            let response = await fetch_all_tags();

            if (response.data?.code === 200) {
                setTags(response?.data?.data?.tags || []);
            } else {
                throw new Error("Failed to fetch tags");
            }
        } catch (e) {

        }
    }

    // fetch list of all programs 
    async function fetchPrograms() {
        try {
            let response = await fetch_all_programs();

            if (response.data?.code === 200) {
                setPrograms(response?.data?.data?.programs || []);
            } else {
                throw new Error("Failed to fetch programs");
            }
        } catch (e) {

        }
    }

    // fetch all conditions 
    async function fetchAllConditions(programId) {
        try {
            let response = await fetch_all_conditions(programId);

            if (response.data?.code === 200) {
                setConditions(response?.data?.data?.conditions || []);
            } else {
                throw new Error("Failed to fetch conditions");
            }
        } catch (e) {

        }
    }

    // fetch all pathways 
    async function fetchAllPathways(programId, conditionId) {
        try {
            let response = await fetch_all_pathways({ programId, conditionId });

            if (response.data?.code === 200) {
                setPathways(response?.data?.data?.pathways || []);
            } else {
                throw new Error("Failed to fetch conditions");
            }
        } catch (e) {

        }
    }

    async function add_New_Tag(name) {
        try {

            let response = await addNewCategoryTag({ type: "tag", name: name });
            if (response) {
                console.log(response, "response")

            } else {
                throw new Error("Failed to fetch blogs");
            }
        } catch (e) {
            console.error(e);
        }
    }

    async function add_New_Category(name) {
        try {

            let response = await addNewCategoryTag({ type: "category", name: name });
            if (response) {
                console.log(response, "response")

            } else {
                throw new Error("Failed to fetch blogs");
            }
        } catch (e) {
            console.error(e);
        }
    }

    const [inputValue, setInputValue] = useState('');

    const handleKeyDown = (e) => {
        if (e.key === 'Enter' && inputValue) {
            // Create a new tag with 'name', 'slug', and 'term_id'

            const tagExists = tags.some(tag => tag.name.toLowerCase() === inputValue.toLowerCase());
            console.log(tagExists, "tagExists")
            if (tagExists) {
                console.log('Tag already exists');
                return; // Stop the function if the tag already exists
            }
            const newTag = { value: inputValue, label: inputValue };

            // Add the new tag to the existing tags array
            const updatedTags = [...video.tags, newTag]; // Spread the existing tags and add the new one

            // Update the tags state
            handleInputChange('tags', updatedTags);

            // Update the external tags list if necessary
            setTags(prevTags => [...prevTags, newTag]); // Update with the new tag

            // Optionally call an external function to handle the new tag
            add_New_Tag(inputValue);

            // Clear the input
            setInputValue('');
        }
    };

    const [isDragging, setIsDragging] = useState(false);

    const handleDragEnter = (e) => {
        e.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = (e) => {
        e.preventDefault();
        setIsDragging(false);
    };

    const handleDrop = (e) => {
        e.preventDefault();
        setIsDragging(false);
        const file = e.dataTransfer.files[0];
        handleHeaderImageUpload({ target: { files: [file] } });
    };

    const handleHeaderImageUpload = async (e) => {
        if (e != null) {

            const file = e.target.files[0];
            if (file) {
                const response = await getPresignedUrl();
                const url = response.data.data;
                if (url) {
                    const result = await axios.put(url, file, {
                        headers: {
                            'Content-Type': file.type || 'application/octet-stream',
                        },
                        onUploadProgress: (progressEvent) => {
                            const percentCompleted = Math.round(
                                (progressEvent.loaded * 100) / progressEvent.total
                            );
                            setUploadProgress(percentCompleted);
                        },
                    });
                    setUploading(false);
                    setVideoUrl(result.config.url);
                    if (result.status === 200) {
                        const res = await generateviewurl({ presignedUrl: result.config.url });
                        const videoUrl = res.data.data
                        console.log(videoUrl, "videoUrl")
                        if (videoUrl) {
                            handleInputChange("thumbnailUrl", videoUrl);
                        }
                    }
                };
            }
        } else {
            handleInputChange("thumbnailUrl", "");
        }
    }



    return (
        <div className="tw-p-6 tw-bg-white tw-rounded-lg tw-shadow-xl tw-mb-16">
            <h2 className="tw-text-3xl tw-font-bold tw-mb-6 tw-mt-8 tw-text-gray-900">Upload Video</h2>

            {/* Form fields */}
            <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-my-4 tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                    <span className="tw-text-sm tw-mr-2">Title</span>
                </label>
                <input
                    type="text"
                    placeholder="Enter title"
                    className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-pr-16"
                    value={video.title}
                    onChange={(e) => handleInputChange('title', e.target.value)}
                />
                <span className="tw-absolute tw-bottom-2 tw-right-4 tw-text-sm tw-text-gray-500">
                    {getCharacterCount(video.title)}/{TITLE_LIMIT}
                </span>
            </div>

            <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-my-4 tw-relative">
                <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                    <span className="tw-text-sm tw-mr-2">Description</span>
                </label>
                <textarea
                    placeholder="Enter description"
                    className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-pr-16"
                    value={video.description}
                    onChange={(e) => handleInputChange('description', e.target.value)}
                />
                <span className="tw-absolute tw-bottom-2 tw-right-4 tw-text-sm tw-text-gray-500">
                    {getCharacterCount(video.description)}/{DESCRIPTION_LIMIT}
                </span>
            </div>

            <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 tw-gap-6 tw-mb-6">
                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Content Type</span>
                    </label>
                    <select
                        className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                        value={video.contentType}
                        onChange={(e) => handleInputChange('contentType', e.target.value)}
                    >
                        <option>Workout Video</option>
                        <option>Awareness Video</option>
                        <option>Medical Awareness Video</option>
                    </select>
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Author</span>
                    </label>
                    <Select
                        isMulti
                        options={authors.map(cat => ({ label: cat.user_nicename }))}
                        value={video.user_nicename}
                        onChange={(selectedOptions) => handleInputChange('author', selectedOptions)}
                        className="tw-flex-grow"
                        placeholder="Select Author"
                    />
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Categories</span>
                    </label>
                    <div className="tw-flex tw-items-center tw-space-x-2">
                        <Select
                            isMulti
                            options={categories.map(cat => ({ value: cat.term_id, label: cat.name }))}
                            value={video.categories}
                            onChange={(selectedOptions) => handleInputChange('categories', selectedOptions)}
                            className="tw-flex-grow"
                            placeholder="Select categories"
                        />
                        <Tooltip title="Add new category" position="top" animation="scale">
                            <button className="tw-p-2 tw-bg-blue-100 tw-rounded-full hover:tw-bg-blue-200 tw-cursor-pointer tw-transition-all tw-duration-300" onClick={() => setShowCategoryModal(true)} >
                                <FaPlus />
                            </button>
                        </Tooltip>
                    </div>
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Tags</span>
                    </label>
                    <Select
                        isMulti
                        options={Array.isArray(tags) ? tags.map(tag => ({ value: tag.term_id, label: tag.name })) : []}
                        value={video.tags}
                        onChange={(selectedOptions) => handleInputChange('tags', selectedOptions)}
                        className="tw-w-full"
                        placeholder="Add Tags"
                        inputValue={inputValue}  // Bind input value to state
                        onInputChange={(value) => setInputValue(value)}  // Track input value
                        onKeyDown={handleKeyDown}  // Trigger on "Enter" key press
                        onCreateOption={(inputValue) => {
                            const newTag = { title: inputValue, description: '' };

                            return { value: newTag.id, label: newTag.title };
                        }}
                        isCreatable={true}
                    />
                </div>
            </div>

            <div className='tw-grid tw-grid-cols-3 tw-gap-2 tw-mb-6'>
                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Video</span>
                    </label>
                    <select
                        className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                        value={video.videoType}
                        onChange={(e) => handleInputChange('videoType', e.target.value)}
                    >
                        <option disabled value="">Select type of Video</option>
                        {
                            videoType.map((videoType) => (
                                <option key={videoType.id} value={videoType.videoType}>{videoType.videoType}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Language</span>
                    </label>
                    <select
                        className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                        value={video.language}
                        onChange={(e) => handleInputChange('language', e.target.value)}
                    >
                        <option disabled value="">Select Language</option>
                        {
                            language.map((language) => (
                                <option key={language.id} value={language.languageName}>{language.languageName}</option>
                            ))
                        }
                    </select>
                </div>

                <div className="tw-flex tw-flex-col tw-border tw-py-1.5 tw-px-2 tw-rounded-lg tw-relative">
                    <label className="tw-flex tw-items-center tw-py-1 tw-px-2">
                        <span className="tw-text-sm tw-mr-2">Activity</span>
                    </label>
                    <select
                        className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-w-full"
                        value={video.activityType}
                        onChange={(e) => handleInputChange('activityType', e.target.value)}
                    >
                        <option disabled value="">Select Activity</option>
                        {
                            activity.map((activity) => (
                                <option key={activity.id} value={activity.activityName}>{activity.activityName}</option>
                            ))
                        }

                    </select>
                </div>
            </div>

            <div className="tw-flex tw-flex-col tw-border tw-py-4 tw-px-4 tw-rounded-lg tw-relative tw-my-6 tw-bg-gray-50">
                <label className="tw-flex tw-items-center tw-mb-2">
                    <span className="tw-text-sm tw-font-medium tw-text-gray-900">
                        Header Image (Up to 700kb, 1280x720)
                    </span>
                </label>
                <div
                    className={`tw-flex tw-flex-col tw-items-center tw-justify-center tw-border-2 tw-border-dashed tw-rounded-lg tw-p-6 tw-transition-colors tw-duration-300 ${isDragging
                        ? "tw-border-blue-500 tw-bg-blue-50"
                        : "tw-border-gray-300"
                        }`}
                    onDragEnter={handleDragEnter}
                    onDragOver={handleDragEnter}
                    onDragLeave={handleDragLeave}
                    onDrop={handleDrop}
                >
                    {video.thumbnailUrl ? (
                        <div className="tw-relative tw-w-full tw-h-40 tw-mb-4">
                            <img
                                src={video.thumbnailUrl}
                                alt="Header"
                                className="tw-w-full tw-h-full tw-object-cover tw-rounded-lg tw-shadow-md"
                            />
                            <button
                                onClick={() => handleHeaderImageUpload(null)}
                                className="tw-absolute tw-top-2 tw-right-2 tw-bg-red-500 tw-text-white tw-rounded-full tw-p-2 tw-shadow-md hover:tw-bg-red-600 tw-transition-colors tw-duration-300"
                            >
                                <FaTrash />
                            </button>
                        </div>
                    ) : (
                        <FaCloudUploadAlt className="tw-text-4xl tw-text-gray-400 tw-mb-2" />
                    )}
                    <p className="tw-text-sm tw-text-gray-600 tw-mb-2">
                        {video.thumbnailUrl
                            ? "Drag & drop to replace"
                            : "Drag & drop your image here"}
                    </p>
                    <p className="tw-text-xs tw-text-gray-500 tw-mb-4">or</p>
                    <label className="tw-cursor-pointer tw-bg-blue-500 tw-text-white tw-py-2 tw-px-4 tw-rounded-lg hover:tw-bg-blue-600 tw-transition-colors tw-duration-300">
                        Choose File
                        <input
                            type="file"
                            className="tw-hidden"
                            onChange={handleHeaderImageUpload}
                            accept="image/*"
                        />
                    </label>
                </div>
                {video.thumbnailUrl && (
                    <p className="tw-text-xs tw-text-gray-500 tw-mt-2 tw-text-center">
                        Image uploaded successfully
                    </p>
                )}
            </div>

            {/* Video upload and preview section */}
            <div className="tw-mb-6">
                {video.videoUrl ? (
                    <div className="tw-relative tw-w-full tw-aspect-video tw-mb-4">
                        {video.postType === 'youtube' ? (
                            <iframe
                                width="100%"
                                height="100%"
                                src={video.videoUrl}
                                title="YouTube video player"
                                frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen
                                className="tw-w-full tw-aspect-video"
                            ></iframe>
                        ) : video.postType === 'upload_video' ? (
                            <>
                                {/* <img
                                    src={video.thumbnailUrl}
                                    alt={video.title}
                                    className="tw-w-full tw-h-full tw-object-cover tw-rounded"
                                /> */}
                                {/* <button
                                    className="tw-absolute tw-inset-0 tw-flex tw-items-center tw-justify-center tw-bg-black tw-bg-opacity-50 tw-text-white"
                                    onClick={handlePlayVideo}
                                > */}
                                {/* <FaPlay className="tw-text-6xl" /> */}
                                {/* </button> */}
                                <video
                                    ref={videoRef}
                                    src={video.videoUrl} // Use the presigned URL here
                                    controls
                                    className="tw-w-full tw-aspect-video tw-rounded"
                                    preload="auto"
                                >
                                    Your browser does not support the video tag.
                                </video>
                            </>
                        ) : (
                            // Local video
                            <video
                                ref={videoRef}
                                src={video.videoUrl}
                                controls
                                className="tw-w-full tw-h-full tw-object-cover"
                            />
                        )}
                    </div>
                ) : (
                    <div className="tw-border-dashed tw-border-2 tw-border-gray-300 tw-p-12 tw-text-center tw-mb-4 tw-aspect-video tw-flex tw-flex-col tw-items-center tw-justify-center tw-bg-gray-50">
                        <FaVideo className="tw-text-4xl tw-mb-4 tw-text-gray-400" />
                        <p className="tw-text-lg tw-text-gray-600">No video uploaded yet</p>
                        <p className="tw-text-xs tw-text-gray-500 tw-mt-1 tw-mb-4">(Video File, Upto 500 MB or 45 minutes.)</p>
                        <label htmlFor="video-upload" className="tw-flex tw-items-center hover:tw-scale-105 tw-cursor-pointer tw-transition-all tw-duration-300 tw-py-2.5 tw-px-5 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100" disabled={uploading}>
                            <FaUpload className="tw-mr-2" /> {uploading ? 'Uploading...' : 'Upload'} Video
                        </label>
                        <input
                            id="video-upload"
                            type="file"
                            accept="video/*"
                            onChange={handleUpload}
                            className="tw-hidden"
                        />
                        {uploading && (
                            <div>
                                <progress value={uploadProgress} max="100"></progress>
                                <span>{uploadProgress} %</span>
                            </div>
                        )}
                    </div>
                )}
            </div>

            <div className="tw-flex tw-justify-between tw-items-center">
                <button className="tw-flex tw-items-center tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={handleBack}>
                    <FaArrowLeft className="tw-mr-2" /> Back
                </button>
                <div className="tw-space-x-2 tw-flex tw-flex-row">
                    <button className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-4 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100" disabled={video.videoUrl == ''} onClick={handleSubmit}>Submit for Approval</button>

                    <button className="tw-flex tw-items-center hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-4 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100" disabled={video.videoUrl == ''} onClick={handlePreview}>
                        <FaEye className="tw-mr-2" /> Preview
                    </button>
                    <button className="hover:tw-scale-105 tw-transition-all tw-duration-300 tw-py-2.5 tw-px-4 tw-me-2 tw-mb-2 tw-text-sm tw-font-medium tw-text-gray-900 focus:tw-outline-none tw-bg-white tw-rounded-lg tw-border tw-border-gray-200 hover:tw-bg-gray-100 hover:tw-text-blue-700 focus:tw-z-10 focus:tw-ring-4 focus:tw-ring-gray-100" disabled={video.videoUrl == ''} onClick={handleDraft}>Save as Drafts</button>
                </div>
                <button className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={onCancel}>Cancel</button>
            </div>

            <Modal
                title="Success"
                content="Video uploaded successfully"
                action="success"
                isOpen={isModalOpen}
                onClose={() => setIsModalOpen(false)}
            />

            {showCategoryModal && (
                <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
                    <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-w-96">
                        <h3 className="tw-text-xl tw-font-bold tw-mb-4">Create New Category</h3>
                        <input
                            type="text"
                            placeholder="New Category"
                            className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-mb-4"
                            value={newCategory}
                            onChange={(e) => setNewCategory(e.target.value)}
                        />
                        {/* <input
                            type="text"
                            placeholder="Sub-category (optional)"
                            className="tw-border tw-border-gray-300 tw-px-4 tw-py-2 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-w-full tw-mb-4"
                            value={newSubCategory}
                            onChange={(e) => setNewSubCategory(e.target.value)}
                        /> */}
                        <div className="tw-mb-4">
                            <h4 className="tw-font-semibold tw-mb-2">Suggested Categories:</h4>
                            <div className="tw-flex tw-flex-wrap tw-gap-2">
                                {suggestedCategories.map((category, index) => (
                                    <button
                                        key={index}
                                        className="tw-border-2 tw-border-black tw-px-2 tw-py-1 tw-text-xs tw-bg-gray-200 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105"
                                        onClick={() => setNewCategory(category)}

                                    >
                                        {category}
                                    </button>
                                ))}
                            </div>
                        </div>
                        <div className="tw-flex tw-justify-end tw-space-x-2">
                            <button className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-bg-gray-200 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={handleCreateCategory}>Create</button>
                            <button className="tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={() => setShowCategoryModal(false)}>Cancel</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default LibraryVideoUpload;