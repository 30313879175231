import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import {
  MoreVertical,
  BarChart2,
  Eye,
  Copy,
  Link,
  FileText,
  Edit,
  Info,
  ChevronLeft,
  ChevronRight,
  XCircle,
} from "lucide-react";
import {
  fetchSurveys,
  copySurveyBuilder,
} from "../../services/SurveyFormService";
import {
  CopySurveyBuilderPayloadTS,
  SingleSurvey,
} from "../../utils/SurveyFormData";
import Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";
import { X } from "lucide-react";
import NPS from "../../NPS-Score.png"
import CsatChart from "../Analysis/HighCharts/CsatChart";
import Rating from "../Analysis/Rating"
import { Tooltip } from "@mui/material";

const Home = () => {
  const platform = "goqii";
  const [allSurveys, setAllSurveys] = useState<SingleSurvey[]>([]);
  const [openMenuId, setOpenMenuId] = useState<number | null>(null);
  const navigate = useNavigate();
  const [currentPage, setCurrentPage] = useState(1);
  const surveysPerPage = 9;
  const [tooltipVisibleId, setTooltipVisibleId] = useState<number | null>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    fetchAllSurveys(platform);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        tooltipRef.current &&
        !tooltipRef.current.contains(event.target as Node)
      ) {
        setTooltipVisibleId(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const fetchAllSurveys = async (platform: string) => {
    const payload = {
      platform: platform,
      survey_type: "custom",
    };

    try {
      const { data } = await fetchSurveys(payload);
      if (data.status === "success") {
        setAllSurveys(data.response);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Failed to fetch surveys:", error);
    }
  };

  console.log(allSurveys);

  const handleViewForm = (surveyId: number) =>
    navigate(`/feedback360/preview-survey-form/${surveyId}`);
  const handleAnalysis = (surveyId: number) =>
    navigate(`/feedback360/analysis/${surveyId}`);
  const handleEditSurvey = (surveyId: number) =>
    navigate(`/feedback360/create/${surveyId}`);

  const handleCopySurvey = async (surveyId: number) => {
    try {
      const payload: CopySurveyBuilderPayloadTS = { id: surveyId, user_id: 1 };
      const { data } = await copySurveyBuilder(payload);
      if (data.status === "success") {
        // alert(data.message);
        fetchAllSurveys(platform);
      } else {
        console.error(data.message);
      }
    } catch (error) {
      console.error("Failed to copy survey:", error);
    }
  };

  const toggleMenu = (surveyId: number) => {
    setOpenMenuId(openMenuId === surveyId ? null : surveyId);
  };

  const getRandomNPSData = () => {
    const randomValue1 = Math.random();
    const randomValue2 = Math.random();

    const promoters = Math.floor(randomValue1 * 60) + 20;
    const passives = Math.floor(randomValue2 * (80 - promoters));
    const detractors = 100 - promoters - passives;

    return [promoters, passives, detractors];
  };

  const calculateNPS = (data: number[]) => {
    return data[0] - data[2];
  };

  const getChartOptions = (npsData: number[], npsScore: number) => ({
    chart: {
      type: "pie",
      height: '100%',
      marginTop: 0,
      marginBottom: 0,
      marginLeft: 0,
      marginRight: 0,
    },
    title: {
      text: null,
    },
    plotOptions: {
      pie: {
        dataLabels: {
          enabled: false,
        },
        innerSize: "60%",
        size: '100%',
      },
    },
    series: [
      {
        name: "NPS",
        data: [
          { name: "Promoters", y: npsData[0], color: "#48AF7C" },
          { name: "Passives", y: npsData[1], color: "#3CB5EC" },
          { name: "Detractors", y: npsData[2], color: "#E84848" },
        ],
      },
    ],
    accessibility: {
      enabled: false,
    },
    credits: {
      enabled: false,
    },
    tooltip: {
      pointFormat: "{series.name}: <b>{point.percentage:.1f}%</b>",
    },
    responsive: {
      rules: [{
        condition: {
          maxWidth: 500,
        },
        chartOptions: {
          chart: {
            height: '200px',
          },
        },
      }],
    },
  });

  const indexOfLastSurvey = currentPage * surveysPerPage;
  const indexOfFirstSurvey = indexOfLastSurvey - surveysPerPage;
  const currentSurveys = allSurveys.slice(
    indexOfFirstSurvey,
    indexOfLastSurvey
  );

  const paginate = (pageNumber: number) => setCurrentPage(pageNumber);

  console.log(allSurveys);

  const renderTooltipContent = (survey: SingleSurvey) => {
    switch (survey.survey_analysis_type) {
      case "nps":
      case "other":
        return (
          <div className="tw-flex tw-flex-col tw-space-y-4">
            <h2 className="tw-text-xl tw-font-semibold">
              How NPS is Calculated
            </h2>
            <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4 tw-space-y-4 md:tw-space-y-0">
              <div className="tw-flex-1">
                <p className="tw-text-sm tw-text-gray-600">
                  The Net Promoter Score (NPS) is a measure of customer loyalty and
                  satisfaction. It is calculated based on responses to survey.
                  <br /><br />
                  <b>NPS</b> = Promoter % - Detractors %
                  <br /><br />
                  <b>Respondents are categorized into three groups:</b>
                  <br />
                  <b className="tw-text-[#48AF7C] tw-animate-pulse">Promoters:</b> Loyal enthusiasts who appreciate your
                  product/service, fueling growth.
                  <br /><br />
                  <b className="tw-text-[#3CB5EC] tw-animate-pulse">Passives:</b> Satisfied but unenthusiastic customers who are
                  vulnerable to competitive offerings.
                  <br /><br />
                  <b className="tw-text-[#E84848] tw-animate-pulse">Detractors:</b> Unhappy customers who can damage your brand and
                  impede growth through negative word-of-mouth.
                </p>
              </div>
              <div className="tw-flex-1 tw-flex tw-justify-center tw-items-center">
                <img
                  loading="lazy"
                  src={NPS}
                  alt="NPS"
                  className="tw-max-w-full tw-h-auto"
                />
              </div>
            </div>
          </div>
        );
      case "csat_smiley":
        return (
          <div className="tw-flex tw-flex-col tw-space-y-4">
            <h2 className="text-xl font-semibold">
              Customer Satisfaction (CSAT) Score
            </h2>
            <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4 tw-space-y-4 md:tw-space-y-0">
              <div className="tw-flex-1">
                <p className="tw-text-sm tw-text-gray-600">
                  CSAT measures customer satisfaction with a product, service, or experience.
                  <br /><br />
                  <b>Average Rating:</b> {survey.avg_csat_value.toFixed(2)} / 5
                  <br /><br />
                  The CSAT gauge visually represents the average satisfaction level:
                  <br />
                  😡 Very Unsatisfied (1) to 😄 Very Satisfied (5)
                </p>
              </div>
              <div className="tw-flex-1 tw-flex tw-justify-center tw-items-center">
                <img
                  loading="lazy"
                  src="https://cdn.prod.website-files.com/60740020d222cb8d835a05fc/655de8f4e99c375ea97d4762_Frame%209204.avif"
                  alt="CSAT Classifications"
                  className="tw-max-w-full tw-h-auto"
                />
              </div>
            </div>
          </div>
        );
      case "rating":
        return (
          <div className="tw-flex tw-flex-col tw-space-y-4">
            <h2 className="tw-text-xl tw-font-semibold">
              Star Rating
            </h2>
            <div className="tw-flex tw-flex-col md:tw-flex-row md:tw-space-x-4 tw-space-y-4 md:tw-space-y-0">
              <div className="tw-flex-1">
                <p className="tw-text-sm tw-text-gray-600">
                  Star ratings provide a simple way for customers to express their satisfaction level.
                  <br /><br />
                  <b>Average Rating:</b> {survey.avg_rating.toFixed(2)} / 5
                  <br /><br />
                  The star icon below is filled proportionally to the average rating:
                </p>
              </div>
              <div className="tw-flex-1 tw-flex tw-justify-center tw-items-center">
                <img
                  loading="lazy"
                  src="https://camo.githubusercontent.com/3b7eab15ec734dc44bcc38ab5cb1840409f7748544ad6592e91b9f1e3218c756/68747470733a2f2f662e636c6f75642e6769746875622e636f6d2f6173736574732f313931383438312f3131313638392f35383864613535382d366166662d313165322d386331332d3363323661386161313136652e706e67"
                  alt="Star Rating"
                  className="tw-max-w-full tw-h-auto"
                />
              </div>
            </div>
          </div>
        );
      default:
        return (
          <p className="tw-text-sm tw-text-gray-600">
            No specific analysis type available for this survey.
          </p>
        );
    }
  };

  return (
    <div className="tw-mx-auto tw-pb-16 tw-pr-4 tw-bg-transparent">
      <h1 className="tw-text-2xl tw-text-gray-600 tw-font-bold tw-mb-6">Feedback Analysis</h1>
      <div className="tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-start tw-gap-4 tw-mb-6 tw-bg-white tw-p-6 tw-rounded-2xl tw-shadow-md">
        <img
          src="https://appcdn.goqii.com/storeimg/15362_1719248477.png"
          alt="logo"
          className="tw-w-60"
        />
        <div className="tw-mx-10 tw-text-left tw-text-xs md:tw-text-base tw-w-full">
          <p className="tw-text-gray-600">
            The Feedback Analysis showcases a snapshot of the survey responses.
            <br />
            There are 3 types of surveys.
            <br />
            <b>Net Promoter Score (NPS):</b>
            <br />
            Classifies users as promoters and detractors. The difference between the two is the NPS.
            <br />
            <b>Customer Satisfaction (C-SAT):</b>
            <br />
            Customer satisfaction feedback average.
            <br />
            <b>Star Rating:</b>
            <br />
            Star rating feedback average.
          </p>
        </div>
      </div>
      <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-6">
        {currentSurveys.map((survey) => {
          const npsData = getRandomNPSData();
          const npsScore = calculateNPS(npsData);
          var { chartData, rating, shape, scale, color, otherData, otherLabel }: any = []
          if (survey.survey_analysis_type === "csat_smiley") {
            chartData = [
              {
                name: "Avg. Score",
                y: [survey.avg_csat_value],
              },
            ];
          } else if (survey.survey_analysis_type === "rating") {
            rating = survey.avg_rating;
            shape = survey.shape;
            scale = survey.scale;
            color = survey.color;
            console.log(color);
          } else if (survey.survey_analysis_type === "other" || survey.survey_analysis_type === null) {
            otherData = [0, 0, 0];
            otherLabel = 0;
          }

          return (
            <div
              key={survey.id}
              className="tw-bg-white tw-rounded-2xl tw-shadow-md tw-overflow-visible tw-flex tw-flex-col tw-justify-between"
            >
              <div className="md:tw-px-6 tw-px-2 tw-py-2">
                <div className="tw-flex tw-items-center tw-justify-between">
                  <div className="tw-flex tw-items-center tw-justify-center tw-max-w-[calc(100%-40px)]">
                  <Tooltip placement="top" title={survey.title} componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#0260ab",
                  color: "#FFFFFF",
                  fontSize: "18px",
                },
              },
            }}>
                    <h2 className="md:tw-text-lg tw-text-sm tw-font-semibold tw-truncate tw-whitespace-nowrap tw-overflow-hidden">
                      {survey.title}
                    </h2>
                    </Tooltip>
                    {survey.survey_analysis_type !== null && (
                      <div className="tw-relative tw-ml-2">
                        <Info
                          className="tw-h-5 tw-w-5 tw-text-gray-500 tw-cursor-pointer tw-align-middle"
                          onClick={() =>
                            setTooltipVisibleId((prevId) =>
                              prevId === survey.id ? null : survey.id
                            )
                          }
                        />
                      </div>
                    )}
                  </div>
                  {tooltipVisibleId === survey.id && (
                    <div
                      ref={tooltipRef}
                      className="tw-fixed tw-inset-0 tw-z-50 tw-overflow-auto tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-p-4"
                      onClick={() => setTooltipVisibleId(null)}
                    >
                      <div
                        className="tw-bg-white tw-relative tw-rounded-lg tw-shadow-lg tw-z-20 tw-p-5 sm:tw-p-6 md:tw-m-4 tw-ml-12 tw-w-full md:tw-max-w-4xl  tw-max-w-[70%] md:tw-max-h-[90vh] tw-max-h-[80vh] tw-overflow-y-auto"
                        onClick={(e) => e.stopPropagation()}
                      >
                        <XCircle
                          className="tw-h-6 tw-w-6 tw-absolute tw-top-2 tw-right-2 tw-cursor-pointer tw-text-gray-500 hover:tw-text-gray-700"
                          onClick={() => setTooltipVisibleId(null)}
                        />
                        {renderTooltipContent(survey)}
                      </div>
                    </div>
                  )}
                  <div className="tw-relative">
                    <span
                      onClick={() => toggleMenu(survey.id)}
                      className=" tw-rounded-full hover:tw-bg-gray-100 tw-cursor-pointer tw-p-1"
                    >
                      <MoreVertical className="tw-h-5 tw-w-5 tw-text-gray-500" />
                    </span>
                    {openMenuId === survey.id && (
                      <div className="tw-absolute tw-right-0 tw-mt-2 tw-w-48 tw-bg-white tw-rounded-md tw-shadow-lg tw-z-10">
                        <div className="tw-py-1">
                          <span
                            onClick={() => handleEditSurvey(survey.id)}
                            className="tw-w-full tw-cursor-pointer tw-text-left tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 tw-flex tw-items-center"
                          >
                            <Edit className="tw-mr-2 tw-h-4 tw-w-4" /> Edit
                          </span>
                          {/* <button
                            onClick={() => handleCopySurvey(survey.id)}
                            className="w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 flex items-center"
                          >
                            <Copy className="mr-2 h-4 w-4" /> Copy
                          </button> */}
                          <span
                            onClick={() => handleViewForm(survey.id)}
                            className="tw-w-full tw-cursor-pointer tw-text-left tw-px-4 tw-py-2 tw-text-sm tw-text-gray-700 hover:tw-bg-gray-100 tw-flex tw-items-center"
                          >
                            <FileText className="tw-mr-2 tw-h-4 tw-w-4" /> View Form
                          </span>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <div className="tw-text-left tw-items-center tw-mb-2">
                  <span className="tw-text-sm tw-text-gray-500">
                    {survey.responses_count} responses
                  </span>
                </div>
                {survey.survey_analysis_type === "nps" && (
                  <div className="tw-relative tw-z-0" style={{ maxWidth: '300px', margin: 'auto' }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getChartOptions([survey.promoters, survey.passives, survey.detractors], survey.nps_score)}
                    />
                    <div className="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-text-2xl tw-font-bold">
                      {survey.nps_score}
                    </div>
                  </div>
                )}
                {survey.survey_analysis_type === "csat_smiley" && (
                  <div className="tw-relative tw-z-0 tw-w-full" style={{ maxWidth: '300px', margin: 'auto' }}>
                    <CsatChart
                      data={chartData}
                      title=""
                      type="gauge"
                      question_type={survey.survey_analysis_type}
                    />
                  </div>
                )}
                {survey.survey_analysis_type === "rating" && (
                  <div className="tw-relative tw-z-0 tw-flex tw-justify-between tw-flex-col tw-items-center tw-gap-4">
                    <Rating
                      rating={survey.avg_rating}
                      shape={shape}
                      scale={scale}
                      color={color}
                    />
                    {/* <h1 className="text-3xl font-bold">{rating}</h1>
                    <h1 className="text-lg font-semibold text-gray-400">Average Rating</h1> */}
                  </div>
                )}
                {survey.survey_analysis_type === "other" && (
                  <div className="tw-relative tw-z-0" style={{ maxWidth: '300px', margin: 'auto' }}>
                    <HighchartsReact
                      highcharts={Highcharts}
                      options={getChartOptions(otherData, otherLabel)}
                    />
                    <div className="tw-absolute tw-top-1/2 tw-left-1/2 tw-transform -tw-translate-x-1/2 -tw-translate-y-1/2 tw-text-2xl tw-font-bold">
                    {survey.responses_count}
                    </div>
                  </div>
                )}
                {survey.survey_analysis_type === null && (
                  <div className="tw-relative tw-z-0 tw-bg-gray-100 tw-rounded-lg tw-p-6 tw-flex tw-items-center tw-justify-center tw-h-48">
                    <div className="text-center">
                      <svg className="tw-mx-auto tw-h-12 tw-w-12 tw-text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M9.172 16.172a4 4 0 015.656 0M9 10h.01M15 10h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                      </svg>
                      <p className="tw-mt-2 tw-text-sm tw-font-medium tw-text-gray-900">No Data Available</p>
                      <p className="tw-mt-1 tw-text-xs tw-text-gray-500">This survey has no responses yet.</p>
                    </div>
                  </div>
                )}
              </div>
              <div className="tw-px-6 tw-py-4 tw-bg-gray-50 tw-flex tw-justify-end tw-rounded-b-lg">
                <span
                  onClick={() => handleAnalysis(survey.id)}
                  className="tw-flex tw-cursor-pointer tw-items-center tw-px-3 tw-py-2 tw-bg-[#03335b] tw-text-white tw-rounded-xl hover:tw-bg-[#03335b]/70 hover:tw-text-white hover:tw-border-[#03335b] tw-transition-colors tw-text-base"
                >
                  <BarChart2 className="tw-mr-2 tw-h-4 tw-w-4" />
                  Analysis
                </span>
              </div>
            </div>
          );
        })}
      </div>

      <div className="tw-mt-8 tw-text-base tw-flex tw-justify-center">
        <fieldset
          onClick={() => paginate(currentPage - 1)}
          disabled={currentPage === 1}
          className="tw-mr-2 tw-cursor-pointer tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md disabled:tw-opacity-50"
        >
          <ChevronLeft className="tw-h-5 tw-w-5" />
        </fieldset>
        <span className="tw-mx-4 tw-flex tw-items-center">
          Page {currentPage} of {Math.ceil(allSurveys.length / surveysPerPage)}
        </span>
        <fieldset
          onClick={() => paginate(currentPage + 1)}
          disabled={
            currentPage === Math.ceil(allSurveys.length / surveysPerPage)
          }
          className="tw-ml-2 tw-cursor-pointer tw-px-4 tw-py-2 tw-border tw-border-gray-300 tw-rounded-md disabled:tw-opacity-50"
        >
          <ChevronRight className="tw-h-5 tw-w-5" />
        </fieldset>
      </div>
    </div>
  );
};

export default Home;
