import React from 'react';
import './MDPServiceProvider.css';


function MDPServiceProvider() {

  return (
    <>
      <section className='MDPServiceProvider'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Add Service Provider</h1>
            </div>
            {/* <div className="col-md-auto text-end"><Link className="btn btn-primary add-new-program-btn" to="/create-new-template">Create New Template</Link></div> */}
          </div>

          <div className='MDP-Service-Provider mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/1204_1731308805.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">This screen enables administrators  add and manage service providers by entering essential details, such as provider name, contact information, services offered, and location. This streamlined setup ensures that users have access to a comprehensive network of trusted providers, enhancing the platform's offerings and ensuring a high-quality user experience.</p>
              </div>
            </div>
          </div>

          <div className='MDP-Service-Provider p-3'>
            <div className="row">
                <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >Provider Information</h1>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Provider Name</label>
                    <input type="text" className="form-control input-field-ui" placeholder="Enter Provider Name"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Contact Phone</label>
                    <input type="text" className="form-control input-field-ui" placeholder="Enter Contact Phone"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Email Address</label>
                    <input type="text" className="form-control input-field-ui" placeholder="Enter Email Address"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Physical Address</label>
                    <input type="text" className="form-control input-field-ui" placeholder="Enter Physical Address"/>
                </div>
                <div className='col-md-12'>
                <hr className="my-3"/>
                </div>
            </div>

            <div className="row">
                {/* <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >Services and Type</h1>
                </div> */}
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Services and Type</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Duration">Select</option>
                        <option value="1">Home-Based Lab Services</option>
                        <option value="2">Home-Based Vaccination Services</option>
                        <option value="3">Home Health Care Services</option>
                        <option value="4">Hospital</option>
                        <option value="5">Clinic</option>
                        </select>
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Services Offered</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Duration">Select</option>
                        <option value="1">Nursing Care</option>
                        <option value="2">Physiotherapy</option>
                        <option value="3">Palliative Care</option>
                        <option value="4">Elderly Care</option>
                        <option value="5">Post-Surgical Care</option>
                        <option value="5">ICU at Home</option>
                        <option value="5">Doctor Visits</option>
                        <option value="5">Wound Care and Dressing</option>
                        <option value="5">Chronic Disease Management</option>
                        </select>
                </div>
                <div className='col-md-12'>
                <hr className="my-3"/>
                </div>
            </div>

            <div className="row">
                <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >Verification Details</h1>
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">License Number</label>
                    <div className='row'>
                        <div className='col-md-6 pe-1'>
                            <input type="text" className="form-control input-field-ui" placeholder="Enter License Number"/>
                        </div>
                        <div className='col-md-6 ps-1'>
                            <input type="file" className="form-control input-field-ui" placeholder="Enter License Number"/>
                        </div>
                    </div>
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Certification/Accreditation</label>
                    <div className='row'>
                        <div className='col-md-6 pe-1'>
                            <input type="text" className="form-control input-field-ui" placeholder="Enter Certification/Accreditation"/>
                        </div>
                        <div className='col-md-6 ps-1'>
                            <input type="file" className="form-control input-field-ui" placeholder="Enter Certification/Accreditation"/>
                        </div>
                    </div>
                </div>

                <div className='col-md-12'>
                <hr className="my-3"/>
                </div>
            </div>


            <div className="row">
                <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >SLA and Payout Model</h1>
                </div>
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">The time it will take to fulfill the service</label>
                    <input type="text" className="form-control input-field-ui" placeholder="Enter the time it will take to fulfill the service"/>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Duration</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Duration">Select</option>
                        <option value="1">Minutes</option>
                        <option value="2">Hours</option>
                        <option value="3">Days</option>
                        </select>
                </div>
                <div className="col-md-3 mb-3">
                    <label className="form-label sub-title">Visit</label>
                    <select className="form-select input-field-ui" aria-label="Default select example">
                        <option value="Duration">Select</option>
                        <option value="1">Per Visit</option>
                        </select>
                </div>
                <div className='col-md-12'>
                <hr className="my-3"/>
                </div>
            </div>

            <div className="row">
                {/* <div  className="col-md-12 mb-2">
                <h1 class="dashboard-graph-title" >Services and Type</h1>
                </div> */}
                <div className="col-md-6 mb-3">
                    <label className="form-label sub-title">Upload Documents </label>
                    <input type="file" className="form-control input-field-ui" placeholder="Upload Documents"/>
                </div>
                {/* <div className='col-md-12'>
                <hr className="my-3"/>
                </div> */}
            </div>

            <div className="row">
                <div className="col-md-12">
                <button type="button" className="btn btn-success uplaod-file-btn">Submit</button>
                </div>
            </div>

            
          </div>
        </div>
      </section>
    </>
  );
}

export default MDPServiceProvider;
