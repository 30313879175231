import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Slider from '@mui/material/Slider';
import MuiInput from '@mui/material/Input';
import { Button, InputLabel, OutlinedInput } from '@mui/material';
import { SliderOptionProps } from '../../../utils/SurveyFormData';
import FormErrorBox from './FormErrorBox';
import SliderRTL from './SliderRTL';

const Input = styled(MuiInput)`
  width: 42px;
`;

const SliderBox = ({ question_text, order, slider_option, responseValue, onResponseChange, question_options, error, disabled, currentSelectedLanguage }: SliderOptionProps) => {

    const [value, setValue] = React.useState(slider_option.min_value);
    const [val, setVal] = React.useState<number>(slider_option.min_value);

    const handleSliderChange = (event: Event, newValue: number | number[]) => {
        setValue(newValue as number);
        if (onResponseChange) {
            onResponseChange({ slider_value: newValue as number });
        }
    };

    const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue(event.target.value === '' ? 0 : Number(event.target.value));
        if (onResponseChange) {
            onResponseChange({ slider_value: Number(event.target.value) });
        }
    };

    const handleBlur = () => {
        if (value < 0) {
            setValue(slider_option.min_value);
        } else if (value > slider_option.max_value) {
            setValue(slider_option.max_value);
        }
    };

    const handleInputClear = () => {
        setValue(slider_option.min_value);
    }

    const handleLabelClick = (sliderVal: number) => {
        setValue(sliderVal);
        if (onResponseChange) {
            onResponseChange({ slider_value: sliderVal });
        }
    };

    const sliderOptionLeftSideLabel = currentSelectedLanguage ? slider_option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "left_side_label")?.translated_text?.toString() : "";
    const sliderOptionRightSideLabel = currentSelectedLanguage ? slider_option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "right_side_label")?.translated_text?.toString() : "";
    const sliderOptionMinValue = currentSelectedLanguage ? slider_option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "min_value")?.translated_text?.toString() : "";
    const sliderOptionMaxValue = currentSelectedLanguage ? slider_option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "max_value")?.translated_text?.toString() : "";
    const sliderOptionStepSizeValue = currentSelectedLanguage ? slider_option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "step_size_value")?.translated_text?.toString() : "";

    return (
        <Box display='flex' flexDirection='column' alignItems="flex-start" sx={{
            '& > :not(style)': { m: 1 }, m: 1, p: 1, direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset",
        }}>
            {error && <FormErrorBox error={error} />}
            <InputLabel htmlFor="my-input" sx={{ whiteSpace: "wrap", textAlign: "left", color: "black"  }}>{question_options?.some((data: any) => data.validation === 'required') && "* "}{order + ") "}{question_text}</InputLabel>
            <Grid container spacing={2} alignItems="center">
                {/* <Grid item>
            <VolumeUp />
          </Grid> */}
                <Grid item xs>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                        <Typography
                            variant="body2"
                            onClick={() => handleLabelClick(slider_option.min_value)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {/* {slider_option.left_side_label} */}
                            {sliderOptionLeftSideLabel}
                        </Typography>
                        <Typography
                            variant="body2"
                            onClick={() => handleLabelClick(slider_option.max_value)}
                            sx={{ cursor: 'pointer' }}
                        >
                            {/* {slider_option.right_side_label} */}
                            {sliderOptionRightSideLabel}
                        </Typography>
                    </Box>
                    {disabled ? (
                        <Slider
                            disabled={disabled}
                            aria-labelledby="input-slider"
                            min={slider_option.min_value}
                            max={slider_option.max_value}
                            step={slider_option.step_size_value}
                        />
                    ) :
                        // (currentSelectedLanguage && currentSelectedLanguage[0].value !== 'ar' ? (
                        //     <Slider
                        //         value={responseValue?.slider_value || (typeof value === 'number' ? value : 0)}
                        //         onChange={handleSliderChange}
                        //         aria-labelledby="input-slider"
                        //         min={slider_option.min_value}
                        //         max={slider_option.max_value}
                        //         step={slider_option.step_size_value}
                        //     />
                        // ) : (
                        <SliderRTL
                            value={responseValue?.slider_value || (typeof value === 'number' ? value as number : 0)}
                            onChange={handleSliderChange}
                            aria-labelledby="input-slider"
                            min={slider_option.min_value}
                            max={slider_option.max_value}
                            step={slider_option.step_size_value}
                            currentLanguage={currentSelectedLanguage ? currentSelectedLanguage[0].value : 'en'}
                        />
                        // )


                        // )
                    }

                </Grid>
                <Grid item>
                    <OutlinedInput
                        disabled={disabled ? true : false}
                        value={value}
                        size="small"
                        onChange={handleInputChange}
                        onBlur={handleBlur}
                        inputProps={{
                            step: slider_option.step_size_value,
                            min: slider_option.min_value,
                            max: slider_option.max_value,
                            type: 'number',
                            'aria-labelledby': 'input-slider',
                        }}
                    />
                </Grid>
                <Grid item>
                    <Button onClick={handleInputClear} sx={{}} >Clear</Button>
                </Grid>
            </Grid>
        </Box>
    );
}

export default SliderBox