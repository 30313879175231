import { Box, Typography } from '@mui/material'
import { CheckCircle2Icon, CircleXIcon } from 'lucide-react';
import React, { forwardRef } from 'react'
import { AlertElementsTS } from '../../../utils/SurveyFormData';

const style = {
    position: "absolute" as "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
};

const CustomAlertBox = forwardRef<HTMLDivElement, AlertElementsTS>(
    ({ type, title, message }, ref) => {
        return (
            <Box sx={style}>
                {type === 'success' ? (
                    <CheckCircle2Icon className="tw-animate-pulse tw-mx-auto tw-w-20 tw-h-20 tw-text-green-600 tw-mb-4" />
                ) : type === 'error' ? (
                    <CircleXIcon className="tw-animate-pulse tw-mx-auto tw-w-20 tw-h-20 tw-text-red-600 tw-mb-4" />
                ) : null}
                <Typography
                    id="transition-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{ textAlign: "center" }}
                >
                    {title}
                </Typography>
                <Typography
                    id="transition-modal-description"
                    sx={{ mt: 2, textAlign: "center" }}
                >
                    {message}
                </Typography>
            </Box>
        )
    }
)

export default CustomAlertBox