import React, { useState } from 'react';
import './PracticeConnect.css';
import { Link } from 'react-router-dom';
import TablePracticeConnect from './componenents/PracticeConnect/TablePracticeConnect';
import DivisionWiseCategorisation from './componenents/PracticeConnect/DivisionWiseCategorisation';


function PracticeConnect() {


  return (
    <>
      <section className='PracticeConnect'>
        <div className="container-fluid px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h1 className="main-title">Division Overview & Management</h1>
            </div>
            <div className="col-md-auto text-end">
                <Link className="btn btn-primary add-new-program-btn me-2" data-bs-toggle="modal" data-bs-target="#addNewDivisionPopUps">Add New Division</Link>
                <Link className="btn btn-primary add-new-program-btn me-2" data-bs-toggle="modal" data-bs-target="#addPracticePopUps">Add New Practice</Link>
                <Link className="btn btn-primary add-new-program-btn me-2" data-bs-toggle="modal" data-bs-target="#onboarDoctorsPopUps">Onboard Doctors</Link>
            </div>
          </div>

          <div className='practice-connect mb-3'>
            <div className="row align-items-center">
              <div className="col-md-auto">
                <img src="https://appcdn.goqii.com/storeimg/83497_1728018601.jpg" alt="Invited Members icon" style={{ height: "140px" }} className="img-fluid mb-0" />
              </div>
              <div className="col-md">
                <p className="mb-0">A division is a distinct segment within a larger organization, typically focused on specific products, services, or markets. Effective management of a division involves strategic planning, resource allocation, and performance monitoring to align with the overall goals of the organization.</p>
              </div>
            </div>
          </div>

          <div className='practice-connect mb-3'>
            <div className='row'>
                <div className='col-md-5'>
                    <div className="row text-center px-2">
                        <div className="col px-1">
                            <div
                            className="card bg-grey"
                            style={{ borderRadius: '14px', border: 'none', height: '100%' }}
                            >
                            <div className="card-body p-3">
                                <div className="row">
                                <div className="col">
                                    <h1 className="card-title1">11</h1>
                                    <h5 className="card-text1">
                                    Division
                                    </h5>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                        <div className="col px-1">
                            <div
                            className="card bg-grey"
                            style={{ borderRadius: '14px', border: 'none', height: '100%' }}
                            >
                            <div className="card-body p-3">
                                <div className="row">
                                <div className="col">
                                    <h1 className="card-title1">50</h1>
                                    <h5 className="card-text1">
                                    Practice
                                    </h5>
                                </div>
                                </div>
                            </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-md-12 mt-4'>
                <div className="table-responsive TablePracticeConnect">
                    <TablePracticeConnect/>
                </div>
                </div>
            </div>
          </div>

          <div className='practice-connect mb-3'>
            <div className="row align-items-center">
              <div className="col-md-12">
              <h1 class="dashboard-graph-title">Division-Wise Categorisation</h1>
              </div>

              <div className="col-md-12">
              <DivisionWiseCategorisation/>
              </div>
              </div>
              </div>


        </div>
      </section>



      <div className="modal fade" id="addNewDivisionPopUps" tabindex="-1" aria-labelledby="addNewDivisionPopUpsLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title" id="addNewDivisionPopUpsLabel">Add New Division</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <label className="form-label">Division Name:</label>
                        <input
                        type="text"
                        name="weight"
                        className="form-control control-input-ui"
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">Division Email:</label>
                        <input
                        type="text"
                        name="height"
                        className="form-control control-input-ui"
                        />
                    </div>
                    <div className="col-12 text-center">
                        <button className="btn btn-primary save-btn me-3">
                        Add Division
                        </button>
                    </div>
                    </div>
                </div>
        </div>
      </div>
    </div>


    <div className="modal fade" id="addPracticePopUps" tabindex="-1" aria-labelledby="addPracticePopUpsLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title" id="addPracticePopUpsLabel">Add New Practice</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <label className="form-label">Practice Name:</label>
                        <input
                        type="text"
                        name="weight"
                        className="form-control control-input-ui"
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">Email Address:</label>
                        <input
                        type="text"
                        name="height"
                        className="form-control control-input-ui"
                        />
                    </div>
                    <div className="col-12 mb-3">
                        <label className="form-label">Division:</label>
                        <select className="form-select input-field-ui">
                            <option value="All">Select Division</option>
                            <option value="Modality Walsall">Modality Walsall</option>
                            <option value="GOQii Division">GOQii Division</option>
                            </select>
                    </div>
                    <div className="col-12 text-center">
                        <button className="btn btn-primary save-btn me-3">
                        Add Practice
                        </button>
                    </div>
                    </div>
                </div>
        </div>
      </div>
    </div>


    <div className="modal fade" id="onboarDoctorsPopUps" tabindex="-1" aria-labelledby="onboarDoctorsPopUpsLabel" aria-hidden="true">
            <div className="modal-dialog modal-dialog-centered modal-md">
                <div className="modal-content">
                <div className="modal-header">
                    <h1 className="modal-title" id="onboarDoctorsPopUpsLabel">Onboard Doctors
                    </h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                <div className="row">
                    <div className="col-12 mb-3">
                        <label className="form-label">Doctor Name:</label>
                        <input
                        type="text"
                        name="weight"
                        className="form-control control-input-ui"
                        />
                    </div>
                  
                    <div className="col-12 mb-3">
                        <label className="form-label">Select Practice:</label>
                        <select className="form-select input-field-ui">
                        <option value="New Invention Practice">New Invention Practice</option>
                        <option value="GOQiiNHS">GOQiiNHS</option>
                            </select>
                    </div>
                    <div className="col-12 text-center">
                        <button className="btn btn-primary save-btn me-3">
                        Onboard Doctors
                        </button>
                    </div>
                    </div>
                </div>
        </div>
      </div>
    </div>

    </>
  );
}

export default PracticeConnect;