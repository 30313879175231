// src/components/Blog/BlogList.jsx
import React, { useState, useEffect } from 'react';
import { FaEye, FaEdit, FaCheck, FaTimes } from 'react-icons/fa';
import HeaderDesc from './HeaderDesc';
import BlogTab from './BlogTab';
import { all_approval_articles, approveArticles, rejectArticles, log_data, searchPost } from "../../../services/cms/Service";
import { useNavigate } from 'react-router-dom';

const BlogList = ({ onEditBlog }) => {
  const [blogs, setBlogs] = useState([]);
  const [filteredBlogs, setFilteredBlogs] = useState(blogs);
  const [sortBy, setSortBy] = useState('title');
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [blogsPerPage] = useState(5);
  const [previewContent, setPreviewContent] = useState(null);
  const [selectedContentType, setSelectedContentType] = useState('all');
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  // Pagination for search results
  const [searchPage, setSearchPage] = useState(1);
  const [searchTotalPages, setSearchTotalPages] = useState(1);

  const handlePagination = (page) => {
    if (searchTerm) {
      setSearchPage(page);
    } else {
      setCurrentPage(page);
    }
  };


  const roleId = localStorage.getItem('roleId')


  const handleSort = (type) => {
    setSortBy(type);
    const sortedBlogs = [...blogs].sort((a, b) => a[type].localeCompare(b[type]));
    setBlogs(sortedBlogs);
  };

  const filterBlogs = () => {
    if (selectedContentType === 'all') {
      setFilteredBlogs(blogs);
    } else if (selectedContentType === 'blog') {
      setFilteredBlogs(blogs.filter(blog => blog.post_type === 'Blog'));
    } else if (selectedContentType === 'video') {
      setFilteredBlogs(blogs.filter(blog => blog.post_type === 'upload_video' || blog.post_type === 'yt_video' || blog.post_type === 'library_video'));
    }
  };

  const handlePreview = (content) => {
    setPreviewContent(content);
  };

  const handleAccept = (id) => {
    console.log(id, "id")
    setBlogs(blogs.map(blog => blog.ID === id ? { ...blog, post_status: 'Approved' } : blog));
    approve_Articles(id)
  };

  const handleReject = (id) => {
    setBlogs(blogs.map(blog => blog.ID === id ? { ...blog, post_status: 'Rejected' } : blog));
    reject_Articles(id)
  };

  // Get current blogs
  // const indexOfLastBlog = currentPage * blogsPerPage;
  // const indexOfFirstBlog = indexOfLastBlog - blogsPerPage;
  // const currentBlogs = blogs.slice(indexOfFirstBlog, indexOfLastBlog);

  // Change page
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  async function fetchApprovalArticles() {
    try {
      let response = await all_approval_articles({ pageNo: currentPage });
      console.log(response.data.data.articles, "response")
      const dataArray = JSON.parse(response.data.data.articles)
      setBlogs(dataArray)
      setTotalPages(Math.floor(response.data?.data?.totalPages))


      if (response.data?.code === 200) {
        filterBlogs();
      } else {
        throw new Error("Failed");
      }
    } catch (e) {

    }
  }

  async function approve_Articles(id) {
    try {
      let response = await approveArticles({ pid: id });
      console.log(response.data.message, "response")
      log_data({ pid: id, action: "Article Approved" })
      if (response.data?.code === 200) {
      } else {
        throw new Error("Failed");
      }
    } catch (e) {

    }
  }

  async function reject_Articles(id) {
    try {
      let response = await rejectArticles({ pid: id });
      console.log(response.data.message, "response")

      log_data({ pid: id, action: "Article Rejected" })
      if (response.data?.code === 200) {
      } else {
        throw new Error("Failed");
      }
    } catch (e) {

    }
  }

  // useEffect(() => {
  //   fetchApprovalArticles();
  // }, []);

  useEffect(() => {
    fetchApprovalArticles();
  }, [currentPage]);

  useEffect(() => {
    filterBlogs();
  }, [selectedContentType, blogs]);

  const handleSearch = async (page = 1) => {
    try {
      let response = await searchPost({ searchTerm: searchTerm, action: 'approval', pageNo: page });
      console.log(response.data.data, "search response")
      if (response.data.code == 200) {
        const dataArray = JSON.parse(response.data.data.articles)
        setFilteredBlogs(dataArray);
        setSearchTotalPages(Math.floor(response.data.data.totalPages));
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };


  useEffect(() => {
    if (searchTerm) {
      handleSearch(searchPage);
    } else {
      fetchApprovalArticles(currentPage);
    }
  }, [currentPage, searchPage, searchTerm]);

  const maxVisiblePages = 5;

  const pagesToShow = searchTerm ? searchTotalPages : totalPages;

  // Calculate the range of pages to display around the current page
  const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
  const endPage = Math.min(pagesToShow, startPage + maxVisiblePages - 1);


  return (
    <div className="tw-overflow-x-auto  tw-shadow-lg tw-rounded-lg tw-p-4 tw-mb-16">
      <BlogTab selectedIndex={roleId == 25 ? 1 : 2} />
      <HeaderDesc
        image={'https://storage.googleapis.com/ksabupatest/2024/11/15/dswhc/quw8sj3hmj.webp'}
        desc={'Review, edit, approve, or reject content before publication. This section ensures content quality, brand alignment, and relevance, providing a final checkpoint to maintain high standards.'}
      />
      <div className="tw-mb-4 tw-flex tw-flex-row tw-justify-between tw-mt-6">
        <div>
          <label htmlFor="contentType" className="tw-mr-2">Filter by Content Type:</label>
          <select
            id="contentType"
            className="tw-bg-gray-50 tw-border tw-border-black tw-text-gray-900 tw-text-sm tw-rounded-lg focus:tw-ring-gray-500 focus:tw-border-gray-500 tw-block tw-p-2.5 tw-max-w-xs"
            onChange={(e) => setSelectedContentType(e.target.value)} // Update state on change
            value={selectedContentType}
          >
            <option value="all">All</option>
            <option value="blog">Blog</option>
            <option value="video">Video</option>
          </select>
        </div>
        <input type="text" placeholder="Search" className="tw-border tw-border-gray-300 tw-px-3 tw-py-1 tw-rounded-lg focus:tw-border-black focus:tw-border-2 tw-max-w-xs tw-w-full tw-h-12 tw-ml-4 tw-mt-3"
          value={searchTerm}
          onChange={(e) => {
            setSearchTerm(e.target.value);
            setSearchPage(1); // Reset search pagination on new search
          }}
        />
        {/* <button
          className="tw-ml-2 tw-px-4 tw-py-1 tw-bg-black tw-text-white tw-rounded-lg tw-h-12 tw-mt-3"
          onClick={() => handleSearch(1)}
        >
          Search
        </button> */}
      </div>
      <table className="tw-text-sm tw-text-left rtl:tw-text-right tw-text-gray-500  tw-w-full">
        <thead className='tw-text-xs tw-text-gray-700 tw-uppercase tw-bg-gray-50 '>
          <tr>
            <th className='tw-px-6 tw-py-3'>
              <span className="tw-cursor-pointer" onClick={() => handleSort('title')}>
                Title {sortBy === 'title' && '↓'}
              </span>
            </th>
            <th className='tw-px-6 tw-py-3'>
              <span className="tw-cursor-pointer" onClick={() => handleSort('author')}>
                Author {sortBy === 'author' && '↓'}
              </span>
            </th>
            <th className='tw-px-6 tw-py-3'>
              <span className="tw-cursor-pointer" onClick={() => handleSort('status')}>
                Status {sortBy === 'status' && '↓'}
              </span>
            </th>
            <th className='tw-px-6 tw-py-3'>
              <span className="tw-cursor-pointer" onClick={() => handleSort('type')}>
                Type {sortBy === 'type' && '↓'}
              </span>
            </th>
            <th className='tw-px-6 tw-py-3'>Actions</th>
          </tr>
        </thead>
        <tbody>
          {filteredBlogs.map(blog => (
            <tr key={blog.ID} className='tw-bg-white tw-border-b '>
              <td className="tw-px-6 tw-py-4">{blog.post_title}</td>
              <td className="tw-px-6 tw-py-4">{blog.post_author}</td>
              <td className="tw-px-6 tw-py-4">
                <span className={`tw-font-semibold tw-cursor-pointer tw-p-1 ${blog.post_status === 'Approved' ? 'tw-text-green-500' : blog.post_status === 'pendingApproval' ? 'tw-text-yellow-500' : 'tw-text-red-500'}`}>
                  {blog.post_status}
                </span>
              </td>
              <td className="tw-px-6 tw-py-4">{blog.post_type}</td>
              <td className="tw-px-6 tw-py-4">
                <div className="tw-flex tw-space-x-2">
                  <span className="tw-border tw-cursor-pointer tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300" onClick={() => navigate(`/content-management/preview-post/${blog.ID}?type=approval`)}>
                    <FaEye />
                  </span>
                  {
                    blog.post_type === "upload_video" || blog.post_type === "library_video" ? (
                      <span
                        className="tw-border tw-cursor-pointer tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
                        onClick={() => navigate(`/content-management/add-video-library?id=${blog.ID}&type=approval`)}
                      >
                        <FaEdit />
                      </span>
                    ) : blog.post_type === "yt_video" ? (
                      <span
                        className="tw-border tw-cursor-pointer tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
                        onClick={() => navigate(`/content-management/edit-yt-video/${blog.ID}`)}
                      >
                        <FaEdit />
                      </span>
                    ) : blog.post_type === "post" ? (
                      <span
                        className="tw-border tw-cursor-pointer tw-p-2 tw-rounded-full tw-bg-gray-50 hover:tw-bg-gray-200 tw-transition-all tw-duration-300"
                        onClick={() => navigate(`/content-management/edit-post/${blog.ID}?type=approval`)}
                      >
                        <FaEdit />
                      </span>
                    ) : null
                  }
                  {blog.post_status === 'pendingApproval' && roleId != 25 && (
                    <>
                      <span className="tw-border tw-cursor-pointer tw-p-2 tw-rounded-lg tw-bg-green-500 hover:tw-bg-green-600 tw-transition-all tw-duration-300 tw-text-white" onClick={() => handleAccept(blog.ID)}>
                        <FaCheck />
                      </span>
                      <span className="tw-border tw-cursor-pointer tw-p-2 tw-rounded-lg tw-bg-red-500 hover:tw-bg-red-600 tw-transition-all tw-duration-300 tw-text-white" onClick={() => handleReject(blog.ID)}>
                        <FaTimes />
                      </span>
                    </>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="tw-mt-4 tw-flex tw-justify-center">
        <div className="">
          {/* {Array.from(
            { length: searchTerm ? searchTotalPages : totalPages },
            (_, i) => i + 1
          ).map((pageNum) => (
            <button
              key={pageNum}
              onClick={() => handlePagination(pageNum)}
              className={`
              ${searchTerm ? searchPage : currentPage === pageNum ? 'tw-bg-gray-900 tw-text-white' : ''}
            `}
            >
              {pageNum}
            </button>
          ))} */}
          <div className="tw-flex tw-justify-center tw-items-center tw-space-x-1">
            {/* First Page Button */}
            {currentPage > 1 && (
              <span onClick={() => handlePagination(1)} className="tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-bg-gray-200">
                First
              </span>
            )}

            {/* Previous Page Button */}
            {currentPage > 1 && (
              <span onClick={() => handlePagination(currentPage - 1)} className="tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-bg-gray-200">
                Prev
              </span>
            )}

            {/* Page Number Buttons */}
            {Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i).map((pageNum) => (
              <span
                key={pageNum}
                onClick={() => handlePagination(pageNum)}
                className={`tw-mx-1 tw-cursor-pointer tw-px-3 tw-py-1 tw-rounded ${pageNum === currentPage ? 'tw-bg-blue-500 tw-text-white' : 'tw-bg-gray-200'
                  }`}
              >
                {pageNum}
              </span>
            ))}

            {/* Next Page Button */}
            {currentPage < pagesToShow && (
              <span onClick={() => handlePagination(currentPage + 1)} className="tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-bg-gray-200">
                Next
              </span>
            )}

            {/* Last Page Button */}
            {currentPage < pagesToShow && (
              <span onClick={() => handlePagination(pagesToShow)} className="tw-px-2 tw-cursor-pointer tw-py-1 tw-rounded tw-bg-gray-200">
                Last
              </span>
            )}
          </div>
        </div>
      </div>

      {previewContent && (
        <div className="tw-fixed tw-inset-0 tw-bg-black tw-bg-opacity-50 tw-flex tw-items-center tw-justify-center tw-z-50">
          <div className="tw-bg-white tw-relative tw-p-6 tw-rounded-lg tw-w-3/4 tw-h-3/4 tw-overflow-auto">
            <h2 className="tw-text-2xl tw-font-bold tw-mb-4">{previewContent.title}</h2>
            {previewContent.headerImage && (
              <img src={previewContent.headerImage} alt={previewContent.title} className="tw-w-full tw-h-48 tw-object-cover tw-mb-4" />
            )}
            <p><strong>Author:</strong> {previewContent.author}</p>
            <p><strong>Type:</strong> {previewContent.type}</p>
            <p><strong>Status:</strong> {previewContent.status}</p>
            <p><strong>Description:</strong> {previewContent.description}</p>
            <p><strong>Categories:</strong> {previewContent.categories.join(', ')}</p>
            <p><strong>Tags:</strong> {previewContent.tags.join(', ')}</p>
            <p><strong>Program:</strong> {previewContent.program}</p>
            <p><strong>Condition:</strong> {previewContent.condition}</p>
            <p><strong>Pathway:</strong> {previewContent.pathway}</p>
            <p><strong>Language:</strong> {previewContent.language}</p>
            {previewContent.videoUrl && (
              <video src={previewContent.videoUrl} controls className="tw-w-full tw-mt-4" />
            )}
            <div className="tw-mt-4">
              <h3 className="tw-text-xl tw-font-bold tw-mb-2">Content:</h3>
              <div dangerouslySetInnerHTML={{ __html: previewContent.content }} />
            </div>
            <div className='tw-flex tw-justify-between tw-items-center tw-mt-4'>
              <span className="tw-flex tw-cursor-pointer tw-items-center tw-border-2 tw-border-black tw-px-4 tw-py-2 tw-rounded-lg hover:tw-bg-black hover:tw-text-white tw-font-semibold tw-transition-all tw-duration-300 hover:tw-scale-105" onClick={() => setPreviewContent(null)}>Close Preview</span>
              {previewContent.status === 'Pending' ? (
                <div className="tw-flex tw-justify-center tw-items-center tw-gap-2">
                  <span className="tw-px-4 tw-cursor-pointer tw-py-2 tw-bg-green-500 hover:tw-bg-green-600 tw-transition-all tw-duration-300 tw-text-white tw-rounded" onClick={() => handleAccept(previewContent.id)}>
                    Approve
                  </span>
                  <span className="tw-px-4 tw-cursor-pointer tw-py-2 tw-bg-red-500 hover:tw-bg-red-600 tw-transition-all tw-duration-300 tw-text-white tw-rounded" onClick={() => handleReject(previewContent.id)}>
                    Reject
                  </span>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default BlogList;