import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { fetchCommunications } from "../../services/CommunicationFormService";
import { PlusIcon, PencilIcon, PauseIcon, StopCircleIcon } from "lucide-react";
import { Navigate } from "react-router-dom";

interface Communication {
  channel: string;
  trigger_point: string;
  feedback_name: string;
  trigger_time: string;
  trigger_interval_time: string;
}

const ManageCommunicators: React.FC = () => {
  const [communications, setCommunications] = useState<Communication[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    fetchAllCommunications();
  }, []);

  const fetchAllCommunications = async () => {
    setIsLoading(true);
    try {
      const data = await fetchCommunications();
      if (data.data.status === "success") {
        setCommunications(data.data.response);
      } else {
        console.log(data.data.message);
        alert(data.data.message);
        console.log(data);
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const tableData = [
    {
      mode: "Phone Message - SMS",
      triggerType: "Post Call to Agent",
      surveyPicked: "Template 1",
      audience: "Age > 25, Location = NY",
      dateOfSettingUp: "2024-07-15",
    },
    {
      mode: "Email",
      triggerType: "Journey Day - 30",
      surveyPicked: "Template 2",
      audience: "Gender = Female",
      dateOfSettingUp: "2024-07-10",
    },
    {
      mode: "Whatsapp",
      triggerType: "On Demand",
      surveyPicked: "Template 3",
      audience: "Subscription = Premium",
      dateOfSettingUp: "2024-07-05",
    },
  ];

  console.log(communications);


  return (
    <div className="tw-container tw-mx-auto tw-px-4 tw-pb-8 tw-mb-16">
      <motion.h4
        className=" tw-font-bold tw-mb-6 tw-text-left tw-text-gray-600"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        Manage Communicators
      </motion.h4>
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.2 }}
        className="tw-text-left tw-mx-auto tw-rounded-xl tw-shadow-lg tw-p-4 tw-bg-white tw-w-full tw-mb-6 tw-flex tw-flex-col md:tw-flex-row tw-items-center tw-justify-between">
        <img
          src="https://www.culturemonkey.io/employee-engagement/content/images/size/w1600/2023/05/effective-manager-feedback-survey-questions.png"
          alt="survey"
          className="tw-w-60 tw-h-48 tw-object-cover tw-rounded-lg"
        />
        <p className="tw-text-gray-600 tw-text-lg tw-pl-5 tw-flex tw-justify-center tw-items-center">
          This feature allows users to oversee and adjust ongoing communication strategies for survey invitations and reminders. Users can pause, edit, or stop communicators across channels like SMS, email, in-app notifications, and messaging apps. It provides tools to monitor engagement and refine communication settings to optimize participation rates.
        </p>
      </motion.div>

      <motion.div
        className="tw-mb-6 tw-flex tw-justify-end"
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, delay: 0.4 }}
      >
        <span
          onClick={() => {
            navigate("/feedback360/choose-communcation-options");
          }}
          className="tw-bg-[#03335b] tw-cursor-pointer hover:tw-bg-[#1279BE] tw-text-base tw-text-white tw-font-bold tw-py-2 tw-px-4 tw-rounded "
        >
          <PlusIcon className="tw-mr-2" size={16} />
          Create Communicator
        </span>
      </motion.div>

      {isLoading ? (
        <div className="tw-flex tw-items-center tw-justify-center tw-h-screen tw-bg-gray-100">
          <motion.div
            className="tw-w-16 tw-h-16 tw-border-t-4 tw-border-blue-500 tw-border-solid tw-rounded-full"
            animate={{
              rotate: 360
            }}
            transition={{
              duration: 1,
              repeat: Infinity,
              ease: "linear"
            }}
          />
          <motion.div
            className="tw-absolute tw-text-xl tw-font-semibold tw-text-blue-500"
            initial={{ opacity: 0.5, scale: 0.9 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{
              duration: 0.5,
              repeat: Infinity,
              repeatType: "reverse"
            }}
          >
            Loading...
          </motion.div>
        </div>
      ) : (
        <motion.div
          className="tw-overflow-x-auto tw-rounded-xl"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <table className="tw-w-full tw-min-w-max">
            <thead>
              <tr className=" ">
                <th className="tw-py-3 tw-px-6 tw-text-left tw-text-gray-500 tw-font-semibold tw-capitalize tw-text-sm tw-bg-[#D4E6EF]">Mode</th>
                <th className="tw-py-3 tw-px-6 tw-text-left tw-text-gray-500 tw-font-semibold tw-capitalize tw-text-sm tw-bg-[#D4E6EF]">Trigger Type</th>
                <th className="tw-py-3 tw-px-6 tw-text-left tw-text-gray-500 tw-font-semibold tw-capitalize tw-text-sm tw-bg-[#D4E6EF]">Survey Picked</th>
                <th className="tw-py-3 tw-px-6 tw-text-left tw-text-gray-500 tw-font-semibold tw-capitalize tw-text-sm tw-bg-[#D4E6EF]">Audience (Filters)</th>
                <th className="tw-py-3 tw-px-6 tw-text-left tw-text-gray-500 tw-font-semibold tw-capitalize tw-text-sm tw-bg-[#D4E6EF]">Date of Setting Up</th>
                <th className="tw-py-3 tw-px-6 tw-text-left tw-text-gray-500 tw-font-semibold tw-capitalize tw-text-sm tw-bg-[#D4E6EF]">Actions</th>
              </tr>
            </thead>
            <tbody className="">
              {communications.map((row, index) => (
                <motion.tr
                  key={index}
                  className={`${index % 2 === 0 ? 'tw-bg-white' : 'tw-bg-gray-100'} `}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.3, delay: index * 0.1 }}
                >
                  <td className="tw-py-3 tw-px-6 tw-text-left tw-whitespace-nowrap tw-text-xs">
                    {row.channel}
                  </td>
                  <td className="tw-py-3 tw-px-6 tw-text-left tw-text-xs">{row.trigger_point}</td>
                  <td className="tw-py-3 tw-px-6 tw-text-left tw-text-xs">{row.feedback_name}</td>
                  <td className="tw-py-3 tw-px-6 tw-text-left tw-text-xs">N.A</td>
                  <td className="tw-py-3 tw-px-6 tw-text-left tw-text-xs">{row.trigger_time} {row.trigger_interval_time}</td>
                  <td className="tw-py-3 tw-px-6 tw-text-left tw-text-xs">
                    <div className="tw-flex tw-item-center tw-justify-start">
                      <motion.span
                        className="tw-transform tw-cursor-pointer hover:tw-scale-110 tw-transition tw-duration-300 tw-ease-in-out tw-mr-3"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <PencilIcon className="tw-text-blue-500" size={18} />
                      </motion.span>
                      <motion.span
                        className="tw-transform tw-cursor-pointer hover:tw-scale-110 tw-transition tw-duration-300 tw-ease-in-out tw-mr-3"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <PauseIcon className="tw-text-yellow-500" size={18} />
                      </motion.span>
                      <motion.span
                        className="tw-transform tw-cursor-pointer hover:tw-scale-110 tw-transition tw-duration-300 tw-ease-in-out"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.9 }}
                      >
                        <StopCircleIcon className="tw-text-red-500" size={18} />
                      </motion.span>
                    </div>
                  </td>
                </motion.tr>
              ))}
            </tbody>
          </table>
        </motion.div>
      )}
    </div>
  );
};

export default ManageCommunicators;
