import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FileText, Video, Image as ImageIcon, File } from 'lucide-react';
import Pagination from '../../utils/Pagination';

// Separate Card Component
const TemplateCard = ({ template, isActive, onCardClick, isHovered, onHover, onLeave }) => {
  const getIcon = () => {
    switch (template.type) {
      case 'Document':
        return <FileText className="tw-w-6 tw-h-6 tw-text-blue-500" />;
      case 'Video':
        return <Video className="tw-w-6 tw-h-6 tw-text-orange-600" />;
      case 'Image':
        return <ImageIcon className="tw-w-6 tw-h-6 tw-text-green-500" />;
      default:
        return <File className="tw-w-6 tw-h-6 tw-text-gray-500" />;
    }
  };

  // Parse buttons if they exist and are a string
  const parseButtons = () => {
    if (!template.buttons) return null;
    try {
      return typeof template.buttons === 'string' ? JSON.parse(template.buttons) : template.buttons;
    } catch (e) {
      return null;
    }
  };

  // Parse website URLs if they exist and are a string
  const parseWebsiteUrl = () => {
    if (!template.websiteUrl) return null;
    try {
      return typeof template.websiteUrl === 'string' ? JSON.parse(template.websiteUrl) : template.websiteUrl;
    } catch (e) {
      return null;
    }
  };

  const replaceVariables = (content) => {
    if (!content) return "";

    // URL regex for better link detection
    const urlRegex = /(?:(?:https?:\/\/)?(?:www\.)?)?[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z]{2,})+(?:\/[^\s]*)?/gi;
    
    const commonTLDs = ['com', 'org', 'net', 'edu', 'gov', 'mil', 'io', 'co', 'ai', 'app', 'dev', 'uk', 'us', 'eu', 'in', 'de', 'fr'];

     
     // First handle line breaks
     content = content.replace(/\\n/g, "<br />");

     // Split content by HTML tags and process each part
     const parts = content.split(/(<[^>]*>)/);
     content = parts.map(part => {
         // If it's an HTML tag, return it unchanged
         if (part.startsWith('<') && part.endsWith('>')) {
             return part;
         }
         // If part contains Arabic/Urdu, wrap it in RTL div
         if (/[\u0600-\u06FF\uFB50-\uFDFF\uFE70-\uFEFF]/.test(part)) {
             return `<div class="tw-text-right" dir="rtl" style="text-align: right;">${part}</div>`;
         }
         // If it's non-empty text without Arabic/Urdu, wrap it in regular div
         if (part.trim()) {
             return `<div>${part}</div>`;
         }
         // Return empty parts unchanged
         return part;
     }).join('');

     console.log(content,'content')

    return (
      content
        // Handle line breaks first
        // .replace(/\\n/g, "<br />")

        // Bold: *text* (not preceded or followed by space)
        .replace(/\*([^*]+)\*/g, "<strong>$1</strong>")

        // Italic: _text_ (not preceded or followed by space)
        .replace(/(?<!\s)_(.*?)_(?!\s)/g, "<em>$1</em>")

        // Strikethrough: ~text~ (not preceded or followed by space)
        .replace(/(?<!\s)~(.*?)~(?!\s)/g, "<del>$1</del>")

        // Monospace: ```text```
        .replace(/```(.*?)```/g, "<code>$1</code>")

        // Single line code: `text`
        .replace(/`(.*?)`/g, "<code>$1</code>")

        // Enhanced URL detection and replacement
        .replace(urlRegex, (url) => {
          // Check if this matches common URL patterns
          const isLikelyURL = commonTLDs.some(tld => 
            url.toLowerCase().includes(`.${tld}`) &&
            url.match(/^[^@\s]+\.[^\s]+$/) // Exclude email addresses
          );

          if (!isLikelyURL) return url;

          // Add protocol if missing
          let fullUrl = url;
          if (!url.match(/^https?:\/\//i)) {
            fullUrl = 'https://' + url;
          }

          // Validate URL
          try {
            new URL(fullUrl);
            return `<a href="${fullUrl}" class="tw-text-blue-600 hover:tw-underline" target="_blank" rel="noopener noreferrer">${url}</a>`;
          } catch {
            return url;
          }
        })

        // WhatsApp style quotes: > at start of line
        .replace(
          /(^|\n)&gt; ?([^\n]*)/g,
          '$1<div class="tw-border-l-4 tw-border-gray-300 tw-pl-3 tw-my-1 tw-text-gray-600">$2</div>'
        )

        // Lists: - or • at start of line
        .replace(
          /(^|\n)[-•] ?([^\n]*)/g,
          '$1<div class="tw-flex tw-gap-2 tw-my-1">•<span>$2</span></div>'
        )

        // Handle multiple underscores or asterisks in a single word
        .replace(/(\*{2}|_{2})(.*?)\1/g, "<strong>$2</strong>")
        .replace(/(\*{3}|_{3})(.*?)\1/g, "<strong><em>$2</em></strong>")

        // Fix any potentially broken HTML from nested formatting
        .replace(/<(\/?)(strong|em|del|code)>/g, "<$1$2>")
    );
  };

  const buttons = parseButtons();
  const websiteUrls = parseWebsiteUrl();

  return (
    <div 
      className={`tw-h-full tw-bg-white tw-rounded-lg tw-shadow-md tw-transition-all tw-duration-300 hover:tw-shadow-lg tw-relative tw-overflow-hidden ${
        isActive ? 'tw-ring-2 tw-ring-blue-500' : ''
      }`}
      onMouseEnter={onHover}
      onMouseLeave={onLeave}
    >
      {/* Card Header */}
      <div className="tw-p-2 tw-bg-gray-300 tw-min-h-[75px]">
        {isActive && (
          <div className="tw-absolute tw-top-2 tw-right-2 tw-w-6 tw-h-6 tw-bg-green-500 tw-rounded-full tw-flex tw-items-center tw-justify-center">
            <svg className="tw-w-4 tw-h-4 tw-text-white" fill="none" stroke="currentColor" viewBox="0 0 24 24">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
            </svg>
          </div>
        )}
        <div className="tw-flex tw-items-center tw-gap-2">
          {getIcon()}
          <h5 className="tw-font-semibold tw-truncate tw-mt-1 tw-text-gray-500">{template.templateName}</h5>
        </div>
        {template.headerContent && (
          <p className="tw-text-sm tw-mb-0 tw-text-gray-500" dangerouslySetInnerHTML={{ __html: replaceVariables(template.headerContent) }}></p>
        )}
      </div>

      {/* Card Body */}
      <div className="tw-flex tw-flex-col tw-gap-4 tw-border-t tw-border-gray-500">
        {/* Media Section */}
        {(template.type === 'Image' || template.type === 'Video') && template.mediaUrl && (
          <div className="tw-relative tw-aspect-video tw-bg-gray-100 tw-rounded-md tw-overflow-hidden tw-mt-2">
            {template.type === 'Image' ? (
              <img 
                src={template.mediaUrl} 
                alt={template.templateName}
                className="tw-w-full tw-h-full tw-object-cover"
              />
            ) : (
              <div className="tw-w-full tw-h-full tw-flex tw-items-center tw-justify-center">
                <Video className="tw-w-12 tw-h-12 tw-text-gray-400" />
              </div>
            )}
          </div>
        )}

        {/* Content Section */}
        <div className="tw-p-4 tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-rounded-lg">
          {/* Document Header */}
          <div className="tw-flex tw-items-start tw-gap-3 tw-mb-2">
            <div className="tw-w-12 tw-h-12 tw-flex tw-items-center tw-justify-center tw-bg-gray-100 tw-rounded-lg">
              <svg className="tw-w-8 tw-h-8 tw-text-gray-500" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12h6m-6 4h6m2 5H7a2 2 0 01-2-2V5a2 2 0 012-2h5.586a1 1 0 01.707.293l5.414 5.414a1 1 0 01.293.707V19a2 2 0 01-2 2z" />
              </svg>
            </div>
            <div className='tw-w-full tw-flex tw-justify-center tw-flex-col'>
              <h3 className="tw-font-medium tw-text-[#667781] tw-text-sm" dangerouslySetInnerHTML={{ __html: replaceVariables(template.headerContent || "Message Preview") }}>
                
              </h3>
              <span className="tw-text-xs tw-text-gray-500">{template.type}</span>
            </div>
          </div>

          {/* Message Content */}
          <div className="tw-flex tw-flex-col tw-gap-3">
            {/* Template Body */}
            <div className="tw-text-[15px] tw-leading-relaxed tw-text-[#111b21]"
              dangerouslySetInnerHTML={{ __html: replaceVariables(template.templateBody) }}
            />

            {/* Footer Content */}
            {template.footerContent && (
              <p className="tw-text-[13px] tw-text-[#8696a0] tw-mt-1" dangerouslySetInnerHTML={{ __html: replaceVariables(template.footerContent) }}>
              </p>
            )}

            {/* Website URLs (Links and Phone Numbers) */}
            {websiteUrls && websiteUrls.length > 0 && (
              <div className="tw-mt-3 tw-space-y-2">
                {websiteUrls.map((item, index) => (
                  <div key={index}>
                    {item.type === 'URL' && (
                      <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                        <i className="bi bi-link-45deg tw-mr-2"></i>
                        {item.title}
                      </span>
                    )}
                    {item.type === 'PHONE_NUMBER' && (
                      <span className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center">
                        <i className="bi bi-telephone tw-mr-2"></i>
                        {item.title}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            )}

            {/* Quick Reply Buttons */}
            {buttons && buttons.length > 0 && (
              <div className="tw-mt-3 tw-w-full tw-flex tw-flex-col tw-justify-center tw-items-center tw-flex-wrap tw-gap-2">
                {buttons.map((button, index) => (
                  <span
                    key={index}
                    className="tw-w-full tw-bg-[#f0f2f5] hover:tw-bg-gray-200 tw-text-[#0177b5] tw-py-2 tw-px-3 tw-rounded tw-text-sm tw-font-medium tw-flex tw-items-center tw-justify-center"
                  >
                    {button.title}
                  </span>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Hover Overlay */}
      <div className={`tw-absolute tw-inset-0 tw-bg-black/50 tw-flex tw-flex-col tw-items-center tw-justify-center tw-transition-opacity tw-duration-300 tw-rounded-lg ${
        isHovered ? 'tw-opacity-100' : 'tw-opacity-0'
      }`}>
        <Link
          to={`/edit-template/${template.templateId}`}
          className="tw-bg-[#2986C4] tw-text-white tw-font-semibold tw-py-2 tw-px-4 tw-rounded tw-mb-2 tw-transition-transform tw-duration-300 tw-transform hover:tw-scale-105"
          onClick={() => onCardClick(template.templateId)}
        >
          Use this template
        </Link>
      </div>
    </div>
  );
};

// Main Component
function WATemplateLibrary({ 
  templates,
  currentPage,
  recordsPerPage,
  onPageChange,
  onLimitChange,
  loading,
  setPerPage,
  perPage,
  setPage,
  page,
  totalPages
}) {
  const [activeCard, setActiveCard] = useState(0);
  const [hoveredCard, setHoveredCard] = useState(null);

  if (!templates?.templateData || loading) {
    return (
      <div className="tw-flex tw-items-center tw-justify-center tw-min-h-[200px]">
        <p className="tw-text-gray-500">{loading ? 'Loading...' : 'No templates available'}</p>
      </div>
    );
  }
  
    // Navigation Handlers
    const goToFirstPage = () => setPage(1);
    const goToPreviousPage = () => setPage((prev) => Math.max(prev - 1, 1));
    const goToNextPage = () => setPage((prev) => Math.min(prev + 1, totalPages));
    const goToLastPage = () => setPage(totalPages);

  return (
    <div className="tw-space-y-6">
        <div className="tw-grid tw-grid-cols-4 tw-gap-8 tw-w-full">
          {templates.templateData.map((template) => (
            <div key={template.templateId} className="">
              <TemplateCard
                template={template}
                isActive={activeCard === template.templateId}
                onCardClick={setActiveCard}
                isHovered={hoveredCard === template.templateId}
                onHover={() => setHoveredCard(template.templateId)}
                onLeave={() => setHoveredCard(null)}
              />
            </div>
          ))}
        </div>

      {/* {templates.totalRecords > 0 && (
        <div className="tw-px-6">
          <Pagination
            currentPage={currentPage}
            totalEntries={templates.totalRecords}
            handlePageChange={onPageChange}
            limit={recordsPerPage}
            onLimitChange={onLimitChange}
          />
        </div>
      )} */}
         <div className="pagination-container">
      {/* Rows Per Page */}
      <div className="rows-per-page">
        <label>Rows per page: </label>
        <select value={perPage} onChange={(e) => setPerPage(Number(e.target.value))}>
          <option value={10}>10</option>
          <option value={15}>15</option>
          <option value={20}>20</option>
          <option value={25}>25</option>
        </select>
      </div>
      {templates.totalRecords > 0 && (
        <div className='tw-flex tw-flex-row tw-justify-center tw-items-center tw-gap-2'>
      <div className="records-info">
          {page} of {templates.totalRecords} records
      </div>

      <div className="pagination-controls">
          <button onClick={goToFirstPage} disabled={page === 1}>
            <i className="bx bx-first-page"></i>
          </button>
          <button onClick={goToPreviousPage} disabled={page === 1}>
            <i className="bx bx-chevron-left"></i>
          </button>
          <button onClick={goToNextPage} disabled={page === totalPages}>
            <i className="bx bx-chevron-right"></i>
          </button>
          <button onClick={goToLastPage} disabled={page === totalPages}>
            <i className="bx bx-last-page"></i>
          </button>
      </div>
    </div>
      )}
</div>
    </div>
  );
}

export default WATemplateLibrary;