import React from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import Highcharts3D from 'highcharts/highcharts-3d';
import { ChartPropsTS, CustomPointOptionsObject } from '../../../utils/SurveyFormData';
import { Box } from '@mui/material';

// Initialize the 3D module
Highcharts3D(Highcharts);

const Chart = ({ data, title, type, question_type }: ChartPropsTS) => {
    if (question_type === 'range') {
        console.log(data);
    }

    const options: Highcharts.Options = {
        chart: {
            type: type === 'bar' ? 'column' : 'pie',
            options3d: {
                enabled: true,
                alpha: type === 'bar' ? 0 : 45,
                beta: type === 'bar' ? 10 : 0,
                depth: type === 'bar' ? 50 : 35,
                viewDistance: 0
            }
        },
        title: undefined,
        xAxis: (type === 'bar' && question_type !== 'range') ? {
            categories: data?.map(d => d.name),
            title: {
                text: null,
            },
            labels: {
                style: {
                    color: '#333',
                    fontSize: '12px'
                }
            }
        } : (type === 'bar' && question_type === 'range') ? {
            title: {
                text: null
            }
        } : undefined,
        yAxis: (type === 'bar' && question_type !== 'range') ? {
            min: 0,
            max: 100,
            title: {
                text: 'Count',
                align: 'high',
            },
            labels: {
                overflow: 'justify',
                format: '{value}%',
                style: {
                    color: '#333',
                    fontSize: '12px'
                }
            },
            allowDecimals: false,
            tickInterval: 10
        } : (type === 'bar' && question_type === 'range') ? {
            min: data && data[0].min_value,
            max: data && data[0].max_value,
            title: {
                text: 'Average Range'
            }
        } : undefined,
        tooltip: {
            formatter: (question_type !== 'range') ?
                function () {
                    const point = this.point as CustomPointOptionsObject;
                    return `${this.series.name}: <b>${point.y_percentage}</b>`;
                } : function () {
                    const point = this.point as CustomPointOptionsObject;
                    return `${this.series.name}: <b>${point.y}</b>`;
                },
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderWidth: 0,
            shadow: true
        },
        plotOptions: {
            column: {
                depth: 50,
                dataLabels: {
                    enabled: true,
                    format: (type === 'bar' && question_type !== 'range') ? '({point.y:.1f}%) {point.y_percentage}' : undefined,
                    style: {
                        textOutline: '1px contrast'
                    }
                }
            },
            pie: {
                depth: 35,
                dataLabels: {
                    enabled: true,
                    format: '{point.name}: ({point.y:.1f}%) {point.y_percentage}',
                    style: {
                        textOutline: '1px contrast'
                    }
                }
            }
        },
        accessibility: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        colors: ['#7cb5ec', '#434348', '#90ed7d', '#f7a35c', '#8085e9', '#f15c80', '#e4d354', '#2b908f', '#f45b5b', '#91e8e1'],
        series: [{
            name: 'Responses',
            type: type === 'bar' ? 'column' : 'pie',
            data: (question_type !== 'range') ? (data?.map(d => ({
                name: d.name,
                y: d.y,
                y_percentage: d.y_percentage,
            })) as CustomPointOptionsObject[]) : (data?.map(d => d.y))
        }]
    };

    return (
        <Box sx={{ m: 2, p: 2, boxShadow: 3, borderRadius: 2, bgcolor: 'background.paper' }}>
            <HighchartsReact highcharts={Highcharts} options={options} />
        </Box>
    );
};

export default Chart;