import React from 'react';
import { useNavigate } from 'react-router-dom';

const HealthManageDashboard = () => {
  const navigate = useNavigate();
  
  const healthConditions = [
    "Stay Fit and Healthy",
    "Diabetes",
    "High Blood Pressure",
    "Thyroid",
    "High Cholesterol",
    "Bone Issues",
    "Fatty Liver",
    "Digestive issues",
    "Eczema/Atopic Dermatitis"
  ];

  const handleCardClick = (condition) => {
    // Convert condition name to URL-friendly format
    const urlFriendlyName = condition.toLowerCase().replace(/\s+/g, '-').replace(/\//g, '-');
    navigate(`/health-manage/${urlFriendlyName}`);
  };

  return (
   
      <section className="tw-w-full tw-px-6 tw-mb-20">
        <div className=" px-0 mb-3">
          <div className="row align-items-center pb-3 sticky-top-bar">
            <div className="col-md">
              <h4 className="tw-text-gray-500 tw-font-semibold">Division Overview & Management</h4>
            </div>
          </div>

          <div className="practice-connect mb-3">
            <div className="tw-bg-white tw-p-6 tw-rounded-lg tw-flex tw-flex-row tw-items-center">
              <div className="tw-mr-4">
                <img
                  src="https://storage.googleapis.com/ksabupatest/2024/12/02/c4q0b/8hl4k4d2j1.png"
                  alt="GOQii HealthManage Icon"
                  style={{ height: "140px" }}
                  className="img-fluid mb-0"
                />
              </div>
              <div className="col-md">
                <p className="mb-0">
                  <strong>GOQii HealthManage</strong> is a PWA app designed to
                  help users achieve their health goals and manage chronic
                  conditions through Digital Therapeutics (DTx). It provides
                  personalized care plans, medication reminders, and educational
                  content to promote informed health decisions. The app also
                  features integrated tools for tracking exercise, nutrition,
                  sleep, and behavioral prompts, fostering proactive engagement.
                  By connecting patients with healthcare providers, it ensures
                  better adherence, improved outcomes, and a seamless health
                  management experience.
                </p>
              </div>
            </div>
          </div>

          <div className="practice-connect mb-3">
            <div className="tw-bg-white tw-p-6 tw-rounded-lg">
              <h2 className="tw-text-2xl tw-font-bold tw-mb-4 tw-text-gray-800">Select Goal/Condition to Proceed</h2>
              <div className="tw-grid tw-grid-cols-1 md:tw-grid-cols-2 lg:tw-grid-cols-3 tw-gap-4">
                {healthConditions.map((condition, index) => (
                  <div
                    key={index}
                    onClick={() => handleCardClick(condition)}
                    className="tw-bg-[#f1f6fc] tw-p-4 tw-rounded-lg tw-shadow tw-border tw-border-gray-200 tw-transition-all tw-duration-300 hover:tw-shadow-xl hover:tw-scale-105 tw-cursor-pointer"
                  >
                    <div className="tw-flex tw-items-center tw-space-x-3">
                      <span className="tw-text-gray-700 tw-font-medium">{condition}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </section>
    
  );
};

export default HealthManageDashboard;