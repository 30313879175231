import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Rating from '@mui/material/Rating';
import StarIcon from '@mui/icons-material/Star';
import { InputLabel } from '@mui/material';
import { RatingOptionProps } from '../../../utils/SurveyFormData';
import { EmojiEmotions, Favorite, FavoriteBorderOutlined, InsertEmoticon, ThumbUpAlt, ThumbUpOffAlt } from '@mui/icons-material';
import FormErrorBox from './FormErrorBox';
import RatingRTL from './RatingRTL';

const RatingBox = ({ question_text, order, rating_option, responseValue, onResponseChange, question_options, error, disabled, currentSelectedLanguage }: RatingOptionProps) => {
    const [value, setValue] = useState<number | null>(0);
    const [hover, setHover] = useState<number>(-1);

    const handleChange = (event: React.ChangeEvent<{}>, newValue: number | null) => {
        setValue(newValue);
        if (onResponseChange) {
            onResponseChange({ rating_value: newValue });
        }
    };

    const handleChangeActive = (event: React.ChangeEvent<{}>, newHover: number) => {
        setHover(newHover);
    };

    const ratingOptionShape = currentSelectedLanguage ? rating_option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "shape")?.translated_text?.toString() : "";
    const ratingOptionScale = currentSelectedLanguage ? rating_option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "scale")?.translated_text?.toString() : "";
    const ratingOptionColor = currentSelectedLanguage ? rating_option.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "color")?.translated_text?.toString() : "";

    return (
        <Box display='flex' flexDirection='column' alignItems="flex-start" gap={1} sx={{
            '& > :not(style)': { m: 1 }, m: 1, p: 1, direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset",
        }}>
            {error && <FormErrorBox error={error} />}
            <InputLabel htmlFor="my-input" sx={{ whiteSpace: "wrap", textAlign: "left", color: "black"  }}>{question_options?.some((data: any) => data.validation === 'required') && "* "}{order + ") "}{question_text}</InputLabel>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', overflowX: 'auto', overflowY: 'hidden' }}>
                <Box display="grid" gridTemplateColumns={`repeat(${rating_option.scale}, 1fr)`} width="100%" mb={1}>
                    {rating_option.labels && Object.keys(rating_option.labels).map((item: any) => (
                        <Box key={item} textAlign="center">
                            <Typography variant="caption">{rating_option.labels[item]}</Typography>
                        </Box>
                    ))}
                </Box>
                {disabled ? (
                    <Rating
                        name="hover-feedback"
                        // value={responseValue?.rating_value || value}
                        precision={1}
                        max={rating_option.scale}
                        // onChange={handleChange}
                        disabled={disabled}
                        onChangeActive={handleChangeActive}
                        emptyIcon={
                            // rating_option.shape === "heart" ? <FavoriteBorderOutlined style={{ opacity: 0.55 }} fontSize="inherit" /> :
                            // rating_option.shape === "smiley" ? <InsertEmoticon style={{ opacity: 0.55 }} fontSize="inherit" /> :
                            // rating_option.shape === "thumb" ? <ThumbUpOffAlt style={{ opacity: 0.55 }} fontSize="inherit" /> :
                            // <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                            ratingOptionShape === "heart" ? <FavoriteBorderOutlined style={{ opacity: 0.55 }} fontSize="inherit" /> :
                                ratingOptionShape === "smiley" ? <InsertEmoticon style={{ opacity: 0.55 }} fontSize="inherit" /> :
                                    ratingOptionShape === "thumb" ? <ThumbUpOffAlt style={{ opacity: 0.55 }} fontSize="inherit" /> :
                                        <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                        }
                        icon={
                            // rating_option.shape === "heart" ? <Favorite fontSize="inherit" /> :
                            // rating_option.shape === "smiley" ? <EmojiEmotions fontSize="inherit" /> :
                            // rating_option.shape === "thumb" ? <ThumbUpAlt fontSize="inherit" /> :
                            // <StarIcon fontSize="inherit" />
                            ratingOptionShape === "heart" ? <Favorite fontSize="inherit" /> :
                                ratingOptionShape === "smiley" ? <EmojiEmotions fontSize="inherit" /> :
                                    ratingOptionShape === "thumb" ? <ThumbUpAlt fontSize="inherit" /> :
                                        <StarIcon fontSize="inherit" />
                        }
                        sx={{
                            '& .MuiRating-iconEmpty': {
                                color: 'rgba(0, 0, 0, 0.26)',
                            },
                            '& .MuiRating-iconFilled': {
                                color: rating_option.color,
                            },
                            '& .MuiRating-iconHover': {
                                color: rating_option.color,
                            },
                            fontSize: '2.5rem',
                            width: '100%',
                            justifyContent: 'space-around',
                            '& .MuiRating-label': {
                                display: 'none'
                            }
                        }}
                    />
                ) : (
                    //     <Rating
                    //     name="hover-feedback"
                    //     value={responseValue?.rating_value || value}
                    //     precision={1}
                    //     max={rating_option.scale}
                    //     onChange={handleChange}
                    //     onChangeActive={handleChangeActive}
                    //     emptyIcon={
                    //         // rating_option.shape === "heart" ? <FavoriteBorderOutlined style={{ opacity: 0.55 }} fontSize="inherit" /> :
                    //         // rating_option.shape === "smiley" ? <InsertEmoticon style={{ opacity: 0.55 }} fontSize="inherit" /> :
                    //         // rating_option.shape === "thumb" ? <ThumbUpOffAlt style={{ opacity: 0.55 }} fontSize="inherit" /> :
                    //         // <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    //         ratingOptionShape === "heart" ? <FavoriteBorderOutlined style={{ opacity: 0.55 }} fontSize="inherit" /> :
                    //         ratingOptionShape === "smiley" ? <InsertEmoticon style={{ opacity: 0.55 }} fontSize="inherit" /> :
                    //         ratingOptionShape === "thumb" ? <ThumbUpOffAlt style={{ opacity: 0.55 }} fontSize="inherit" /> :
                    //         <StarIcon style={{ opacity: 0.55 }} fontSize="inherit" />
                    //     }
                    //     icon={
                    //         // rating_option.shape === "heart" ? <Favorite fontSize="inherit" /> :
                    //         // rating_option.shape === "smiley" ? <EmojiEmotions fontSize="inherit" /> :
                    //         // rating_option.shape === "thumb" ? <ThumbUpAlt fontSize="inherit" /> :
                    //         // <StarIcon fontSize="inherit" />
                    //         ratingOptionShape === "heart" ? <Favorite fontSize="inherit" /> :
                    //         ratingOptionShape === "smiley" ? <EmojiEmotions fontSize="inherit" /> :
                    //         ratingOptionShape === "thumb" ? <ThumbUpAlt fontSize="inherit" /> :
                    //         <StarIcon fontSize="inherit" />
                    //     }
                    //     sx={{
                    //         '& .MuiRating-iconEmpty': {
                    //             color: 'rgba(0, 0, 0, 0.26)',
                    //         },
                    //         '& .MuiRating-iconFilled': {
                    //             color: rating_option.color,
                    //         },
                    //         '& .MuiRating-iconHover': {
                    //             color: rating_option.color,
                    //         },
                    //         fontSize: '2.5rem',
                    //         width: '100%',
                    //         justifyContent: 'space-around',
                    //         '& .MuiRating-label': {
                    //             display: 'none'
                    //         }
                    //     }}
                    // />
                    <RatingRTL
                        value={responseValue?.rating_value || value}
                        max={rating_option.scale}
                        onChange={handleChange}
                        ratingOptionShape="star"
                        rating_option={rating_option}
                        currentLanguage={currentSelectedLanguage ? currentSelectedLanguage[0]?.value : "en"}
                    />
                )
                }

            </Box>
        </Box>
    );
}

export default RatingBox;
