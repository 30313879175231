import React from 'react';
import { Commingsoon } from '../../utils/CommonLibrary';
import { Link } from "react-router-dom";
function DropDownAction() {

 
return (
    <>
        <div className='DropDownAction'>
        <div class="btn-group dropend">
        <button type="button" class="btn btn-light dropdown-toggle action-dropdown-btn" data-bs-toggle="dropdown" aria-expanded="false">
            <i class="bi bi-three-dots-vertical"></i>
        </button>
        <ul class="dropdown-menu">
            <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal"  data-bs-target="#upcomingFeatureModal"><i class="bi bi-pen"></i> Edit Condition</Link></li>
            <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-plus-circle"></i> Add Pathway</Link></li>
            <li className='list-class'><Link to="" class="dropdown-item" href="#" data-bs-toggle="modal" data-bs-target="#upcomingFeatureModal"><i class="bi bi-x-circle text-danger"></i> Delete Condition</Link></li>
        </ul>
        </div>
        </div>
        <Commingsoon />
    </>
  );
}

export default DropDownAction;

export const DisplayMessage = ({showPopup,message,onClose}) =>{
    return (
        <>
<div className={`modal fade ${showPopup ? 'show' : ''}`}  id="displayModalLabel"  data-bs-backdrop="static" tabIndex="-1" aria-labelledby="displayModalLabel" aria-hidden={!showPopup} style={{display: showPopup ? 'block' : 'none'}}>
            <div className="modal-dialog modal-dialog-centered modal-md">
                    <div className="modal-content">
                        <div className="modal-header">
                            {/* <p className="main-title">Are you sure want to publish condition?</p> */}
                            <button
                                type="button"
                                className="btn-close"
                                onClick={onClose}
                            ></button>
                        </div>
                        <div class="modal-body pt-1 mb-2 mx-auto w-80" style={{ color: '#000000' }}>
                            {message}
                        </div>
                           
                    </div>
                </div>
                <div className="modal-backdrop fade show" style={{ zIndex: 1040 }}></div>
            </div>
            </>
    );

}
