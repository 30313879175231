import { Box } from '@mui/material';
import React, { useState } from 'react';

interface SmileyProps {
    level: number;
    label: string;
    isSelected: boolean;
    onSmileyClick: (level: number) => void
}

const smileyColors = [
    '#FF0000', // Very Unsatisfied - Red
    '#FF4500', // Unsatisfied - Orange Red
    '#FFA500', // Neutral - Orange
    '#32CD32', // Satisfied - Lime Green
    '#008000'  // Very Satisfied - Green
];

const Smileys: React.FC<SmileyProps> = ({ level, label, isSelected, onSmileyClick }) => {
    const [isHovered, setIsHovered] = useState(false);
    const outlineColor = smileyColors[level - 1];
    const fillColor = isHovered || isSelected ? outlineColor : 'none';

    const getSmileyPath = () => {
        switch (level) {
            case 1: // Very Unsatisfied
                return (
                    <>
                        <circle cx="12" cy="12" r="10" />
                        <circle cx="8" cy="10" r="1" />
                        <circle cx="16" cy="10" r="1" />
                        <path d="M8 16c4-4 8-4 8 0" />
                    </>
                );
            case 2: // Unsatisfied
                return (
                    <>
                        <circle cx="12" cy="12" r="10" />
                        <circle cx="8" cy="10" r="1" />
                        <circle cx="16" cy="10" r="1" />
                        <path d="M8 16c2-3 6-3 8 0" />
                    </>
                );
            case 3: // Neutral
                return (
                    <>
                        <circle cx="12" cy="12" r="10" />
                        <circle cx="8" cy="10" r="1" />
                        <circle cx="16" cy="10" r="1" />
                        <path d="M8 16h8" />
                    </>
                );
            case 4: // Satisfied
                return (
                    <>
                        <circle cx="12" cy="12" r="10" />
                        <circle cx="8" cy="10" r="1" />
                        <circle cx="16" cy="10" r="1" />
                        <path d="M8 16c1.5 2 4.5 2 6 0" />
                    </>
                );
            case 5: // Very Satisfied
                return (
                    <>
                        <circle cx="12" cy="12" r="10" />
                        <circle cx="8" cy="10" r="1" />
                        <circle cx="16" cy="10" r="1" />
                        <path d="M8 16c2 3 6 3 8 0" />
                    </>
                );
            default:
                return null;
        }
    };

    return (
        <Box
            sx={{
                textAlign: 'center',
                cursor: 'pointer',
                transition: 'fill 0.2s',
                ':hover': {
                    transition: 'all 0.3s',
                    transform: 'scale(1.1)'
                },
                width: '100%'
            }}
            onClick={() => onSmileyClick(level)}
            onMouseEnter={() => setIsHovered(true)}
            onMouseLeave={() => setIsHovered(false)}
        >
            <svg
                width="100%"
                height="50"
                viewBox="0 0 24 24"
                fill={fillColor}
                xmlns="http://www.w3.org/2000/svg"
                stroke={isHovered || isSelected ? 'black' : outlineColor}
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
            >
                {getSmileyPath()}
            </svg>
            <Box
                sx={{
                    marginTop: '5px',
                    fontSize: '12px'
                }}
            >
                {label}
            </Box>
        </Box>
    );

};

export default Smileys;
