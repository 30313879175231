import React from 'react';
import { motion } from 'framer-motion';

interface RatingProps {
  rating: number;
  shape: string;
  scale: number;
  color: string;
}

const Rating: React.FC<RatingProps> = ({ rating, shape, scale, color }) => {
  const fillPercentage = (rating / scale) * 100;
  const gradientId = `fillGradient-${Math.random().toString(36).substr(2, 9)}`;
  const shadowId = `shadow-${Math.random().toString(36).substr(2, 9)}`;

  const getShapePath = () => {
    switch (shape) {
      case 'star':
        return 'M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z';
      case 'heart':
        return 'M12 21.35l-1.45-1.32C5.4 15.36 2 12.28 2 8.5 2 5.42 4.42 3 7.5 3c1.74 0 3.41 0.81 4.5 2.09C13.09 3.81 14.76 3 16.5 3 19.58 3 22 5.42 22 8.5c0 3.78-3.4 6.86-8.55 11.54L12 21.35z';
      case 'thumb':
        return 'M2 20V10c0-1.1.9-2 2-2h2.38l5.25-5.58c.31-.33.75-.52 1.21-.52.62 0 1.16.39 1.38.94l2.68 7.39H22v10H7c-1.1 0-2-.9-2-2v-2H2zm18-8v6h-2v-6h2zm-4 6V8.66L11.38 3.4 6 9.09V18h10z';
      case 'smiley':
        return 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm3.5-9c.83 0 1.5-.67 1.5-1.5S16.33 8 15.5 8 14 8.67 14 9.5s.67 1.5 1.5 1.5zm-7 0c.83 0 1.5-.67 1.5-1.5S9.33 8 8.5 8 7 8.67 7 9.5 7.67 11 8.5 11zm3.5 6.5c2.33 0 4.31-1.46 5.11-3.5H6.89c.8 2.04 2.78 3.5 5.11 3.5z';
      default:
        return 'M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z';
    }
  };

  return (
    <div className="tw-flex tw-flex-col tw-items-center tw-bg-white tw-p-2 tw-rounded-lg">
      <div className="tw-relative tw-w-32">
        <motion.svg
          viewBox="0 0 24 24"
          xmlns="http://www.w3.org/2000/svg"
          className="w-full h-full"
          initial={{ scale: 0, rotate: -180 }}
          animate={{ scale: 1, rotate: 0 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          <defs>
            <linearGradient id={gradientId} x1="0%" y1="0%" x2="100%" y2="100%">
              <stop offset="0%" stopColor={`${color}FF`} />
              <stop offset="100%" stopColor={`${color}CC`} />
            </linearGradient>
            <filter id={shadowId}>
              <feDropShadow dx="0.2" dy="0.4" stdDeviation="0.2" floodOpacity="0.3" />
            </filter>
          </defs>
          
          {/* Base shape */}
          <path
            d={getShapePath()}
            fill="#E0E0E0"
            filter={`url(#${shadowId})`}
          />
          
          {/* Gradient fill */}
          <motion.path
            d={getShapePath()}
            fill={`url(#${gradientId})`}
            filter={`url(#${shadowId})`}
            initial={{ clipPath: `polygon(0 0, 0 0, 0 100%, 0% 100%)` }}
            animate={{ clipPath: `polygon(0 0, ${fillPercentage}% 0, ${fillPercentage}% 100%, 0% 100%)` }}
            transition={{ duration: 1.5, ease: "easeInOut" }}
          />
        </motion.svg>
      </div>
      <motion.div 
        className="tw-mt-2 tw-text-center"
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5 }}
      >
        <span className="tw-text-4xl tw-font-bold" style={{ color }}>{rating.toFixed(1)}</span>
        <span className="tw-text-2xl tw-text-gray-600">/{scale}</span>
      </motion.div>
      <motion.div 
        className="tw-text-lg tw-font-semibold tw-mt-2 tw-text-gray-700"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.7 }}
      >
        Average Rating
      </motion.div>
    </div>
  );
};

export default Rating;