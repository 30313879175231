import React, { useRef, useEffect } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
// import tinymce from "tinymce/tinymce";

// Import necessary plugins (optional)
// import "tinymce/themes/silver/theme";
// import "tinymce/plugins/advlist";
// import "tinymce/plugins/autolink";
// import "tinymce/plugins/link";
// import "tinymce/plugins/image";
// import "tinymce/plugins/lists";
// import "tinymce/plugins/charmap";
// import "tinymce/plugins/preview";
// import "tinymce/plugins/anchor";
// import "tinymce/plugins/code";
// import "tinymce/plugins/fullscreen";
// import "tinymce/plugins/help";
// import "tinymce/plugins/table";

const FullHeightQuill = ({ value, onChange, modules, formats }) => {
    const quillRef = useRef(null);

    useEffect(() => {
        if (quillRef.current) {
            const quill = quillRef.current.getEditor();
            quill.root.style.height = '600px';
            quill.root.style.minHeight = '640px';
        }
    }, []);

    // const editorRef = useRef(null);
    // const [editorContenttiny, setEditorContent] = useState("");

    // const handleEditorChange = (content, editor) => {
    //     // setEditorContent(content);
    //     if (getWordCount(content) <= CONTENT_LIMIT) {
    //         setEditorContent(content);
    //         handleInputChange("content", content);
    //     }
    //     console.log("Content was updated:", content);
    // };

    // const handleKeyPress = (e) => {
    //     console.log("Key pressed:", e.key);
    // };

    // useEffect(() => {
    //     tinymce.init({
    //         selector: "textarea", // Attach to the textarea
    //         menubar: "file edit view", // Customize the menubar
    //         height: 300,
    //         width: 600,
    //         setup: (editor) => {
    //             editor.on("change", () => {
    //                 console.log("Editor content changed:", editor.getContent());
    //             });
    //         },
    //     });

    //     return () => {
    //         // Cleanup TinyMCE instance on unmount
    //         tinymce.remove();
    //     };
    // }, []);

    return (
        <ReactQuill
            ref={quillRef}
            theme="snow"
            value={value}
            onChange={onChange}
            modules={modules}
            formats={formats}
            style={{ height: '100%', display: 'flex', flexDirection: 'column' }}
        />

    );
};

export default FullHeightQuill;