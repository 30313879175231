import {
  Box,
  Button,
  InputLabel,
  Radio,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { MatrixRatingProps } from "../../../utils/SurveyFormData";
import FormErrorBox from "./FormErrorBox";

const MatrixRatingBox = ({
  question_text,
  order,
  matrix_row_options,
  matrix_column_options,
  responseValue,
  onResponseChange,
  question_options,
  error,
  disabled,
  isMobile,
  currentSelectedLanguage,
  pageResponses
}: MatrixRatingProps) => {
  // const [selections, setSelections] = useState<{ [key: number]: number }>(
  //   () => {
  //     const storedSelections = JSON.parse(
  //       localStorage.getItem("matrix_values") || "{}"
  //     );
  //     return storedSelections || {};
  //   }
  // );
  const [selections, setSelections] = useState<{ [key: number]: number }>();

  console.log(selections)

  const handleSelect = (row: number, column: number) => {
    setSelections((prevSelections) => {
      const newSelections = { ...prevSelections, [row]: column };
      localStorage.setItem("matrix_values", JSON.stringify(newSelections));

      return newSelections;
    });
  };

  useEffect(() => {
    selections && Object.entries(selections).forEach((value, key) => {
      if (onResponseChange) {
        onResponseChange({
          matrix_row_options_id: parseInt(value[0]),
          matrix_column_options_id: value[1],
        });
      }
    });
  }, [selections]);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  var y = JSON.parse(localStorage.getItem("matrix_values") || "{}");

  return (
    <div>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="flex-start"
        sx={{
          "& > :not(style)": { m: 1 },
          m: 1,
          p: 1,
          direction: disabled && currentSelectedLanguage && currentSelectedLanguage[0].value === "ar" ? "rtl" : "unset",
        }}
      >
        {error && <FormErrorBox error={error} />}
        <InputLabel
          htmlFor="my-input"
          sx={{ whiteSpace: "wrap", textAlign: "left", color: "black"  }}
        >
          {question_options?.some(
            (data: any) => data.validation === "required"
          ) && "* "}
          {order + ") "}
          {question_text}
        </InputLabel>
        {isSmallScreen || isMobile ? (
          <Box>
            {matrix_row_options.map((row, rowIndex) => {
              const matrixRowText = currentSelectedLanguage ? row.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "row_option_text")?.translated_text?.toString() : "";
              return (
                <Box key={rowIndex} sx={{ marginBottom: 2 }}>
                  {/* <Typography>{row.row_option_text}</Typography> */}
                  <Typography sx={{ fontWeight: "bold" , textAlign: "start"}}>{matrixRowText}</Typography>
                  {matrix_column_options.map((column, colIndex) => {
                    const matrixColumnText = currentSelectedLanguage ? column.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "column_option_text")?.translated_text?.toString() : "";
                    return (
                      <Box
                        key={colIndex}
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        {disabled ? (
                          <Radio
                            disabled={disabled}
                            checked={false}
                          />
                        ) : (
                          <Radio
                            // checked={selections[row.id] === column.id}
                            checked={((pageResponses?.responses.find(r => r.matrix_row_options_id === row.id && r.matrix_column_options_id === column.id) ? true : false) || ((selections && selections[row.id]) === column.id))}
                            onChange={() => handleSelect(row.id, column.id)}
                            value={column.id}
                          />
                        )}

                        {/* <Typography>{column.column_option_text}</Typography> */}
                        <Typography>{matrixColumnText}</Typography>
                      </Box>
                    )
                  })}
                </Box>
              )
            })}
          </Box>
        ) : (
          <Table>
            <TableHead>
              <TableRow>
                <TableCell></TableCell>
                {matrix_column_options.map((column, index) => {
                  const matrixColumnText = currentSelectedLanguage ? column.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "column_option_text")?.translated_text?.toString() : "";
                  return (
                    <TableCell key={index} align="center">
                      {/* {column.column_option_text} */}
                      {matrixColumnText}
                    </TableCell>
                  )
                })}
              </TableRow>
            </TableHead>
            <TableBody>
              {matrix_row_options.map((row, rowIndex) => {
                const matrixRowText = currentSelectedLanguage ? row.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "row_option_text")?.translated_text?.toString() : "";
                return (
                  <TableRow key={rowIndex}>
                    {/* <TableCell>{row.row_option_text}</TableCell> */}
                    <TableCell>{matrixRowText}</TableCell>
                    {matrix_column_options.map((column, colIndex) => {
                      const matrixColumnText = currentSelectedLanguage ? column.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "column_option_text")?.translated_text?.toString() : "";
                      return (
                        <TableCell key={colIndex} align="center">
                          {/* <Radio
                            checked={selections[row.id] === column.id}
                            onChange={() => handleSelect(row.id, column.id)}
                            value={column.column_option_text}
                          /> */}
                          {disabled ? (
                            <Radio
                              disabled={disabled}
                              checked={false}
                            />
                          ) : (
                            <Radio
                              // checked={selections[row.id] === column.id}
                              checked={((pageResponses?.responses.find(r => r.matrix_row_options_id === row.id && r.matrix_column_options_id === column.id) ? true : false) || ((selections && selections[row.id]) === column.id))}
                              onChange={() => handleSelect(row.id, column.id)}
                              // value={column.column_option_text}
                              value={matrixColumnText}
                            />
                          )}
                        </TableCell>
                      )
                    })}
                  </TableRow>
                )
              })}
            </TableBody>
          </Table>
        )}
      </Box>
    </div>
  );
};

export default MatrixRatingBox;
