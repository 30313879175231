import React, { ChangeEvent, useContext, useState } from 'react'
import {
    PageBuilderPropsTS,
    QuestionsDataBuilderTS,
    SurveyFormCommonProps,
    ChoicesTS,
    SliderOptionTS,
    MatrixRowOptionTS,
    MatrixColumnOptionTS,
    RatingOptionTS,
    ResponsesResponseTS
} from '../../../utils/SurveyFormData';
import QuestionBuilder from './QuestionBuilder';
import { Box, Button, Divider, IconButton, OutlinedInput, Tooltip, useMediaQuery, useTheme, Typography } from '@mui/material';
import { AddCircleOutline, RemoveCircle } from '@mui/icons-material';
import CheckBox from '../FormElements/CheckBox';
import InputBox from '../FormElements/InputBox';
import RadioBox from '../FormElements/RadioBox';
import TextAreaBox from '../FormElements/TextAreaBox';
import NPSBox from '../FormElements/NPSBox';
import SliderBox from '../FormElements/SliderBox';
import MatrixRatingBox from '../FormElements/MatrixRatingBox';
import RatingBox from '../FormElements/RatingBox';
import CSATBox from '../FormElements/CSATBox';

import { Pencil } from "lucide-react";
import DeleteIcon from '@mui/icons-material/Delete';
import { AppContext } from '../../../context/AppContextProvider';
import { SelectOptionType } from '../../../utils/CommunicationFormData';
import { translateTextToAllLanguages } from '../../../helpers/TranslationHelper';

const PageBuilder = ({ page, updatePage, removePage, getTotalQuestions }: PageBuilderPropsTS) => {

    const [isPreview, setIsPreview] = useState<Record<number, boolean>>({});
    const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [isEditingTitle, setIsEditingTitle] = useState(false);
    const [isEditingDescription, setIsEditingDescription] = useState(false);

    const totalQuestions = getTotalQuestions();

    const surveyContext = useContext(AppContext);
    if (!surveyContext) {
        throw new Error("SurveyBuilder must be used within an AppContextProvider");
    }

    const {
        selectedLanguageOptions,
        selectedTab
    } = surveyContext;

    const [currentSelectedLanguage, setCurrentSelectedLanguage] = useState<SelectOptionType[]>([selectedLanguageOptions[selectedTab]])

    const addQuestion = () => {
        const newQuestion: QuestionsDataBuilderTS = {
            // id: Date.now(), // Temporary ID for new questions
            question_text: '',
            order: totalQuestions + 1,
            translations: [],
            question_type: {
                id: 1,
                type_name: 'input'
            },
            question_options: [],
            choices: [],
            matrix_row_options: [],
            matrix_column_options: [],
            slider_option: {},
            rating_option: {}
        };
        updatePage({
            ...page,
            questions: [...page.questions, newQuestion]
        });
    };

    const updateQuestion = (questionIndex: number, updatedQuestion: QuestionsDataBuilderTS) => {
        const updatedQuestions = page.questions.map((question, index) =>
            index === questionIndex ? updatedQuestion : question
        );
        updatePage({ ...page, questions: updatedQuestions });
    };

    const removeQuestion = (questionIndex: number) => {
        const updatedQuestions = page.questions.filter((_, index) => index !== questionIndex);
        updatePage({ ...page, questions: updatedQuestions });
    };

    const togglePreview = (questionIndex: number) => {
        setIsPreview(prevState => ({
            ...prevState,
            [questionIndex]: !prevState[questionIndex]
        }));
    };

    const renderQuestionComponent = (question: QuestionsDataBuilderTS, index: number) => {
        const questionTextTrans = question.translations?.find(t => t.language_code === currentSelectedLanguage[0].value && t.column_name === "question_text")?.translated_text?.toString() ?? "";
        const commonProps: SurveyFormCommonProps = {
            order: question.order,
            // question_text: question.question_text,
            question_text: questionTextTrans,
            page_id: page.id ?? 0,
            question_type: question.question_type?.type_name || 'unknown', // Fallback in case type_name is undefined
            question_options: question.question_options,
            disabled: isPreview[index],
            currentSelectedLanguage: currentSelectedLanguage
        };

        switch (question.question_type.type_name) {
            case 'input':
                return (
                    <div className='tw-bg-white'>
                        <InputBox {...commonProps} />
                    </div>
                );
            case 'radio':
                return (
                    <div className='tw-bg-white'>
                <RadioBox {...commonProps} choices={question.choices as ChoicesTS[]} />
                    </div>
            );
            case 'checkbox':
                return (
                    <div className='tw-bg-white'>
                <CheckBox {...commonProps} choices={question.choices as ChoicesTS[]} />
                    </div>
                );
            case 'comment_box':
                return (
                    <div className='tw-bg-white'>
                    <TextAreaBox {...commonProps} />
                    </div>
                );
            case 'nps':
                return (
                    <div className='tw-bg-white'>
                <NPSBox {...commonProps} />
                    </div>
                );
            case 'range':
                return (
                    <div className='tw-bg-white'>
                <SliderBox {...commonProps} slider_option={question.slider_option as SliderOptionTS} />
                    </div>
                );
            case 'matrix_rating':
                return (
                    <div className='tw-bg-white'>
                <MatrixRatingBox
                    {...commonProps}
                    matrix_row_options={question.matrix_row_options as MatrixRowOptionTS[]}
                    matrix_column_options={question.matrix_column_options as MatrixColumnOptionTS[]}
                />
                    </div>
                );
            case 'rating':
                return (
                    <div className='tw-bg-white'>
                <RatingBox {...commonProps} rating_option={question.rating_option as RatingOptionTS} />
                    </div>
                );
            case 'csat_smiley':
                return (
                    <div className='tw-bg-white'>
                <CSATBox {...commonProps} rating_option={question.rating_option as RatingOptionTS} question_text={questionTextTrans} />
                    </div>
                );
            default:
                return null;
        }
    };

    const handlePageTitleDescChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, inputType: string, effectType: string) => {
        const updatedText = e.target.value;
        const selectedLocale = selectedLanguageOptions[selectedTab].value;

        // // Clone the translations or create a new array if it doesn't exist
        // const updatedTranslations = page.translations?.map(translation =>
        //   translation.language_code === selectedLocale
        //     ? { ...translation, [inputType]: updatedText }
        //     : translation
        // ) || [];

        // // Check if the locale exists in translations
        // const existingTranslationIndex = updatedTranslations.findIndex(
        //   translation => translation.language_code === selectedLocale
        // );

        // if (existingTranslationIndex === -1) {
        //   // Add a new translation entry if it doesn't exist
        //   updatedTranslations.push({ language_code: selectedLocale, [inputType]: updatedText });
        // }

        // Clone the translations or create a new array if it doesn't exist
        let updatedTranslations = page.translations ? [...page.translations] : [];

        // Find existing entries for the current locale and input type
        const existingEntryIndex = updatedTranslations.findIndex(
            translation => translation.language_code === selectedLocale && translation.column_name === inputType
        );

        if (existingEntryIndex !== -1) {
            // Update existing entry
            updatedTranslations[existingEntryIndex] = {
                ...updatedTranslations[existingEntryIndex],
                // [inputType]: updatedText,
                translated_text: updatedText,
            };
        } else {
            // Add a new entry
            updatedTranslations.push({
                language_code: selectedLocale,
                column_name: inputType,
                // [inputType]: updatedText,
                translated_text: updatedText,
            });
        }

        if (effectType === 'blur' && selectedLocale === 'en' && selectedLanguageOptions.length > 1) {
            const translationLanguages = selectedLanguageOptions.reduce((acc: any, l: any) => {
                return l.value !== "en" ? [...acc, l.value] : acc;
            }, []).join(",");

            translateTextToAllLanguages(updatedText, translationLanguages).then((translatedTexts) => {
                selectedLanguageOptions.forEach((option) => {
                    if (option.value !== 'en') {
                        const translationIndex = updatedTranslations.findIndex(
                            translation => translation.language_code === option.value && translation.column_name === inputType
                        );

                        if (translationIndex !== -1) {
                            updatedTranslations[translationIndex] = {
                                ...updatedTranslations[translationIndex],
                                translated_text: translatedTexts[option.value]
                            };
                        } else {
                            updatedTranslations.push({
                                language_code: option.value,
                                column_name: inputType,
                                translated_text: translatedTexts[option.value]
                            });
                        }
                    }
                });

                updatePage({
                    ...page,
                    [inputType]: updatedText,
                    translations: updatedTranslations,
                });
            });
        } else if (effectType === 'change') {
            // Update the page with the new translations
            updatePage({
                ...page,
                [inputType]: updatedText,
                translations: updatedTranslations,
            });
        }
    };


    return (
        <div className='tw-rounded-2xl tw-p-4 tw-bg-[#f0f4ff]'>
            <div
                className='tw-flex tw-items-center tw-justify-end'
            >
                {/* <h2>Page {page.page_number}</h2> */}
                
                {/* {isSmallScreen ? (
                ) : (
                    <Tooltip title="Remove Page">
                        <IconButton
                            aria-label="delete"
                            size='large'
                            onClick={removePage}
                        >
                            <RemoveCircle />
                        </IconButton>
                    </Tooltip>
                )} */}
            </div>
            <div className=' tw-rounded-lg tw-flex tw-flex-col tw-gap-4 tw-items-end tw-w-full tw-relative'>
            <Tooltip title="Delete Page" placement="top" componentsProps={{
              tooltip: {
                sx: {
                  backgroundColor: "#0260ab",
                  color: "#FFFFFF",
                  fontSize: "18px",
                },
              },
            }}>
                    <span
                    // variant='contained'
                    onClick={removePage}
                    className='tw-absolute tw-cursor-pointer -tw-top-2 -tw-right-2 tw-text-base tw-text-nowrap hover:tw-scale-105 tw-transition-all tw-duration-300  tw-text-white tw-bg-red-500 hover:tw-bg-red-600 tw-font-bold tw-p-2 tw-rounded-2xl '
                >
                    {/* <DeleteIcon className='tw-text-red-500 hover:tw-text-red-600 ' /> */}
                    Delete Page
                </span>
            </Tooltip>
                <div
                    className='tw-w-full tw-flex tw-flex-col tw-items-center tw-mb-2'
                >
                    {isEditingTitle ? (
                        <div
                            className='tw-w-full tw-flex tw-flex-col tw-items-end tw-gap-4 tw-mt-8'

                        >
                            <input
                                type="text"
                                placeholder="Page Title"
                                //   value={page.title}
                                //   onChange={(e) => updatePage({ ...page, title: e.target.value })}
                                //   value={page.translations?.find(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)?.title || ""}
                                value={page.translations?.find(t => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "title")?.translated_text || ""}
                                onChange={(e) => handlePageTitleDescChange(e, 'title', 'change')}
                                onBlur={(e) => handlePageTitleDescChange(e, 'title', 'blur')}
                                //   onBlur={() => setIsEditingTitle(false)}
                                className="tw-w-full tw-px-4 tw-py-1 tw-text-[#03335b] tw-border-2 tw-border-[#03335b] tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#03335b] focus:tw-border-transparent tw-transition-all tw-duration-300 tw-text-xl tw-font-bold"
                                autoFocus
                            />
                            <textarea
                                className='tw-w-full tw-px-4 tw-py-1 tw-border-2 tw-border-[#03335b] tw-rounded-lg focus:tw-outline-none focus:tw-ring-2 focus:tw-ring-[#03335b] focus:tw-border-transparent tw-transition-all tw-duration-300 tw-text-xl tw-font-semibold'
                                cols={50}
                                rows={2}
                                name='demo-textarea'
                                placeholder="Page Description"
                                onClick={() => setIsEditingTitle(true)}
                                // onBlur={() => setIsEditingTitle(false)}
                                // value={page.description}
                                // onChange={(e) => updatePage({ ...page, description: e.target.value })}
                                // value={page.translations?.find(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)?.description || ""}
                                value={page.translations?.find(t => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "description")?.translated_text || ""}
                                onChange={(e) => handlePageTitleDescChange(e, 'description', 'change')}
                                onBlur={(e) => handlePageTitleDescChange(e, 'description', 'blur')}
                            />
                            <span
                                onClick={() => setIsEditingTitle(false)}
                                className=" tw-bg-[#03335b] tw-cursor-pointer tw-text-white tw-rounded-lg tw-text-base tw-p-2 hover:tw-bg-[#03335b]/70 tw-transition-all hover:tw-scale-105 tw-py-2 tw-px-4"
                            >
                                Save
                            </span>

                        </div>
                    ) : (
                        <h2 className="tw-text-2xl tw-font-bold  tw-flex tw-items-center tw-justify-center tw-rounded-2xl tw-px-8 tw-w-full">
                            {/* {page.title || `Page Title`} */}
                            {/* {page.translations?.find(translation => translation.language_code === selectedLanguageOptions[selectedTab].value)?.title || `Page Title`} */}
                            {page.translations?.find(t => t.language_code === selectedLanguageOptions[selectedTab].value && t.column_name === "title")?.translated_text || `Page Title`}
                            <span
                                onClick={() => setIsEditingTitle(true)}
                                className="tw-ml-2 tw-cursor-pointer"
                            >
                                <Pencil
                                    size={20}
                                    className="tw-text-gray-500 hover:tw-text-indigo-600"
                                />
                            </span>
                        </h2>
                    )}
                </div>
                {/* value={page.title}
                    onChange={(e) => updatePage({ ...page, title: e.target.value })} */}
                {/* <div
                className='flex flex-col justify-center items-center my-6'
                >
                    {isEditingDescription ? (
                    
                  ) : (
                    <h2 className="text-2xl font-bold text-indigo-800 flex items-center justify-center rounded-lg shadow-lg py-8 bg-blue-100 w-full">
                      {page.description || `Page Description`}
                      <button
                        onClick={() => setIsEditingDescription(true)}
                        className="ml-2"
                      >
                        <Pencil
                          size={20}
                          className="text-gray-500 hover:text-indigo-600"
                        />
                      </button>
                    </h2>
                  )}
                
            </div> */}

            </div>
            <div
                className='tw-flex tw-flex-col tw-gap-4  '
            >
                {page.questions.map((question, index) => (
                    <React.Fragment key={`question-${question.order}`}>
                        {isPreview[index] ? (
                            <Box sx={{ backgroundColor: 'white', borderRadius: 4, padding: 2, marginBottom: 2 }}>
                                <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
                                    <Typography sx={{ fontWeight: 'semibold', fontSize: 24 }}>Preview</Typography>
                                    <Button sx={{ borderRadius: 2 , border: '1px solid #03335b'}} onClick={() => togglePreview(index)}>
                                        <Pencil className='tw-text-[#03335b]' />
                                    </Button>
                                </Box>
                                {renderQuestionComponent(question, index)}
                            </Box>
                        ) : (
                            <QuestionBuilder
                                togglePreview={() => togglePreview(index)}
                                question={question}
                                updateQuestion={(updatedQuestion) => updateQuestion(index, updatedQuestion)}
                                removeQuestion={() => removeQuestion(index)}
                            />
                        )}
                    </React.Fragment>
                ))}
            </div>
            {/* <Divider sx={{ margin: '20px 0' }} /> */}
            <Box
                display='flex'
                justifyContent='start'
                alignItems='center'
                paddingLeft={2}
                sx={{
                    margin: '20px 0'
                    // margin: '10px',
                    // padding: '20px 0px',
                    // background: 'lightgray'
                }}
            >

                <span
                    className='tw-text-blue-500 tw-cursor-pointer tw-flex tw-justify-center tw-items-center tw-text-base tw-float-start'
                    onClick={addQuestion}
                >
                    <AddCircleOutline />
                    New Question
                </span>
            </Box>
        </div>
    );
}

export default PageBuilder;