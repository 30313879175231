import React, { useRef, useEffect, useState } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import HighchartsMore from 'highcharts/highcharts-more';
import { ChartPropsTS } from '../../../utils/SurveyFormData';
import SolidGauge from 'highcharts/modules/solid-gauge';

HighchartsMore(Highcharts);
SolidGauge(Highcharts);

const CsatChart: React.FC<ChartPropsTS> = ({ data, title, type, question_type }) => {
    const chartComponentRef = useRef<HighchartsReact.RefObject>(null);
    const [chartSize, setChartSize] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const updateSize = () => {
            if (chartComponentRef.current && chartComponentRef.current.chart) {
                const chart = chartComponentRef.current.chart;
                const container = chart.container;
                if (container) {
                    const rect = container.getBoundingClientRect();
                    setChartSize({ width: rect.width, height: rect.height });
                    chart.reflow();
                }
            }
        };

        updateSize();
        window.addEventListener('resize', updateSize);

        return () => window.removeEventListener('resize', updateSize);
    }, []);

    const isSmallScreen = chartSize.width < 300;

    const options: Highcharts.Options = {
        chart: {
            type: 'gauge',
            plotBackgroundColor: undefined,
            plotBackgroundImage: undefined,
            plotBorderWidth: 0,
            plotShadow: false,
            height: '100%',
            margin: [0, 0, 0, 0],
            spacing: [0, 0, 0, 0]
        },
        title: {
            text: title,
            style: {
                fontSize: isSmallScreen ? '12px' : '16px'
            }
        },
        pane: {
            startAngle: -90,
            endAngle: 90,
            background: [],
            center: ['50%', '50%'],
            size: '80%'
        },
        tooltip: {
            enabled: false
        },
        yAxis: {
            min: 1,
            max: 5,
            lineWidth: 0,
            minorTickInterval: undefined,
            tickLength: 5,
            tickPosition: 'outside',
            tickColor: '#666',
            tickWidth: 0,
            labels: {
                distance: isSmallScreen ? 10 : 12,
                style: {
                    fontSize: isSmallScreen ? '7px' : '9px'
                },
                formatter: function(this: Highcharts.AxisLabelsFormatterContextObject): string {
                    const currVal = this.value as number;
                    if (currVal === 1) return 'V.Poor';
                    if (currVal === 2) return 'Poor';
                    if (currVal === 3) return 'Neutral';
                    if (currVal === 4) return 'Good';
                    if (currVal === 5) return 'V.Good';
                    return '';
                }
            },
            plotBands: [{
                from: 1,
                to: 1.8,
                color: 'red',
                thickness: '20%',
            }, {
                from: 1.8,
                to: 2.6,
                color: 'orange',
                thickness: '20%',
            }, {
                from: 2.6,
                to: 3.4,
                color: 'yellow',
                thickness: '20%',
            }, {
                from: 3.4,
                to: 4.2,
                color: 'green',
                thickness: '20%',
            }, {
                from: 4.2,
                to: 5,
                color: 'darkgreen',
                thickness: '20%',
            }]
        },
        accessibility: {
            enabled: false
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Rating',
            type: 'gauge',
            data: data?.map(d => d.y) || [],
            dataLabels: {
                format: '{y}',
                borderWidth: 0,
                color: (
                    Highcharts.defaultOptions.title &&
                    Highcharts.defaultOptions.title.style &&
                    Highcharts.defaultOptions.title.style.color
                ) || '#333333',
                style: {
                    fontSize: isSmallScreen ? '14px' : '16px'
                },
                y: isSmallScreen ? -25 : -30
            },
            dial: {
                radius: '80%',
                backgroundColor: 'gray',
                baseWidth: isSmallScreen ? 4 : 6,
                baseLength: '0%',
                rearLength: '0%'
            },
            pivot: {
                backgroundColor: 'gray',
                radius: isSmallScreen ? 2 : 3
            }
        }]
    };

    return (
        <div style={{ width: '100%', paddingBottom: '75%', position: 'relative' }}>
            <div style={{ position: 'absolute', width: '100%', height: '100%' }}>
                <HighchartsReact 
                    highcharts={Highcharts} 
                    options={options} 
                    ref={chartComponentRef}
                    containerProps={{ style: { width: '100%', height: '100%' } }}
                />
            </div>
        </div>
    );
};

export default CsatChart;